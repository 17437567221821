package com.appcreator.creatorapp.editor.buildercondition

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Book
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.Checkbox
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import com.appcreator.blueprint.conditions.logic.NotConditionBuilder
import com.appcreator.blueprint.spec.ConditionBuilder
import com.appcreator.creatorapp.editor.DocsButton
import com.appcreator.creatorapp.editor.inputs.InputItem
import com.appcreator.styles.ThemeMargins

@Composable
fun ConditionBuilder(
    condition: ConditionBuilder,
    save: () -> Unit,
    remove: () -> Unit
) {

    (condition as? NotConditionBuilder)?.let { notCondition ->
         notCondition.notCondition.value?.let { realCondition ->
             Row(
                 modifier = Modifier.fillMaxWidth(),
                 verticalAlignment = Alignment.CenterVertically,
                 horizontalArrangement = Arrangement.SpaceBetween
             ) {

                 Text(realCondition.spec.title)

                 TextButton(onClick = remove) {
                     Text("Remove")
                     Spacer(Modifier.width(ThemeMargins.SmallMargin))
                     Icon(Icons.Default.Delete, contentDescription = null)
                 }
             }

             realCondition.inputSpecs.forEach {
                 InputItem(it, save)
                 Box(Modifier.height(ThemeMargins.SmallMargin))
             }

             Row(verticalAlignment = Alignment.CenterVertically) {
                 Checkbox(
                     checked = notCondition.enabled.value == true,
                     onCheckedChange = {
                         notCondition.enabled.value = it
                         save()
                     }
                 )
                 Column {
                     Text("Flip results (NOT)")
                     Text(
                         text = "Convert \"true\" to \"false\" or \"false\" to \"true\".",
                         style = MaterialTheme.typography.labelMedium
                     )
                 }
             }

             Spacer(Modifier.height(ThemeMargins.LargeMargins))
             if(realCondition.spec.hasDocs) {
                 DocsButton("conditions", realCondition.spec.title)
             }
         }
    }
}
