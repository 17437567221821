package com.appcreator.blueprint.firebase.remoteconfig

import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.ConversionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FirebaseRemoteConfigConversion")
@ConversionClass(
    group = FirebaseRemoteConfigConsts.group,
    title = "Firebase Remote Config",
    isExtraPlugin = true,
    availableFrom = "0.0.1"
)
class FirebaseRemoteConfigConversion(
    @InputProperty(
        title = "Key Name",
        availableFrom = "0.0.1"
    )
    val keyName: String?
): Conversion
