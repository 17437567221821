package com.appcreator.blueprint

import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.blueprint.core.LoaderSpecReference
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.loaderspec.ScreenLoaderSpec
import com.appcreator.blueprint.theme.Theme
import kotlinx.serialization.Serializable

@Serializable
data class Blueprint(
    val screens: List<ScreenSpec>,
    val loaderSpecs: List<LoaderSpec>,
    val globalData: List<GlobalData>?,
    val customEnvironments: List<CustomEnvironment>?,
    val defaultEnvironment: String?,
    val defaultLocale: String,
    val theme: Theme
) {
    fun rootDestination(): Destination =
        Destination(screens.first(), emptyMap())

    fun loaderSpec(ref: LoaderSpecReference): LoaderSpec? {
        if (ref.id == "screen-loader") {
            // TODO better way to do this, return from api by default with needed env IDs and so on
           return ScreenLoaderSpec(ref.id)
        }
        return loaderSpecs.find { it.id == ref.id }
    }
}

data class Destination(
    val screen: ScreenSpec,
    val properties: Map<String, String>,
)

@Serializable
data class ScreenSpec(
    val id: String,
    val path: String,
    val title: LocalizableString?
): Component {
    override val _nodeId: String? = null
    override val _nodeLabel: String? = null
    override val _nodeRelativeId: String? = null
}

@Serializable
data class GlobalData(
    val key: String,
    val values: Map<String, String>,
    val perEnvironment: Boolean,
    val forLoadersOnly: Boolean
) {
    companion object {
        const val defaultEnv = "Production"
    }
    fun value(envName: String?): String =
        if (perEnvironment) values[envName ?: defaultEnv] ?: ""
        else values[defaultEnv] ?: ""
}

@Serializable
data class CustomEnvironment(
    val id: String,
    val name: String,
)
