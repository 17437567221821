package com.appcreator.creatorapp.project

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.AdminPanelSettings
import androidx.compose.material.icons.filled.Android
import androidx.compose.material.icons.filled.Checklist
import androidx.compose.material.icons.filled.PhoneIphone
import androidx.compose.material.icons.filled.Settings
import androidx.compose.material.icons.filled.SystemUpdate
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import app_creator.app.generated.resources.logo_contentful
import app_creator.app.generated.resources.logo_firebase
import com.appcreator.creatorapp.project.configurations.AdminConfiguration
import com.appcreator.creatorapp.project.configurations.AndroidConfiguration
import com.appcreator.creatorapp.project.configurations.ContentfulConfiguration
import com.appcreator.creatorapp.project.configurations.FirebaseConfiguration
import com.appcreator.creatorapp.project.configurations.IOSConfiguration
import com.appcreator.creatorapp.project.configurations.ProjectGeneralConfiguration
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.local.LocalUserState
import org.jetbrains.compose.resources.painterResource


enum class SettingsCategory(
    val title: String?
) {
    Overview(null),
    General("General Settings"),
    ThirdPart("Third Party Plugins"),
    Admin("Admin")
}

data class SettingsContext(
    val org: OrganisationResponse,
    val project: Project,
    val goBack: () -> Unit,
    val openDownload: (String) -> Unit,
    val openSetting: (SettingsOptions) -> Unit,
    val openEditor: () -> Unit
)

enum class SettingsOptions(
    val category: SettingsCategory,
    val visible: @Composable (SettingsContext) -> Boolean = { true },
    val label:  @Composable RowScope.() -> Unit,
    val content: @Composable (SettingsContext) -> Unit
) {
    Checklist(
        category = SettingsCategory.Overview,
        visible = { it.project.showChecklist },
        label = {
            Icon(
                Icons.Default.Checklist,
                contentDescription = null
            )
            Text("Checklist")
        },
        content = { context ->
            Checklist(context.org, context.project.id, context.openSetting, context.openEditor)
        }
    ),
    Releases(
        category = SettingsCategory.Overview,
        label = {
            Icon(Icons.Default.SystemUpdate, contentDescription = null)
            Text("Releases")
        },
        content = { context ->
            ReleasesSection(context.org, context.project, context.openDownload)
        }
    ),
    Configuration(
        category = SettingsCategory.General,
        label = {
            Icon(Icons.Default.Settings, contentDescription = null)
            Text("Configuration")
        },
        content = { context ->
            ProjectGeneralConfiguration(context.org, context.project, context.goBack)
        }
    ),
    Android(
        category = SettingsCategory.General,
        label = {
            Icon(Icons.Default.Android, contentDescription = null)
            Text("Android")
        },
        content = { context ->
            AndroidConfiguration(context.org, context.project, context.openDownload)
        }
    ),
    IOS(
        category = SettingsCategory.General,
        label = {
            Icon(Icons.Default.PhoneIphone, contentDescription = null)
            Text("IOS")
        },
        content = { context ->
            IOSConfiguration(context.org, context.project)
        }
    ),
    Firebase(
        category = SettingsCategory.ThirdPart,
        label = {
            Image(
                painter = painterResource(app_creator.app.generated.resources.Res.drawable.logo_firebase),
                contentDescription = null
            )
        }, content = { context ->
            FirebaseConfiguration(context.org.organisation, context.project)
        }
    ),
    Contentful(
        category = SettingsCategory.ThirdPart,
        label = {
            Image(
                modifier = Modifier.padding(vertical = 2.dp),
                painter = painterResource(app_creator.app.generated.resources.Res.drawable.logo_contentful),
                contentDescription = null
            )
        }, content = { context ->
            ContentfulConfiguration(organisation =  context.org.organisation, project = context.project)
        }
    ),
    Admin(
        category = SettingsCategory.Admin,
        visible = {
            LocalUserState.current.admin
        },
        label = {
            Icon(Icons.Default.AdminPanelSettings, contentDescription = null)
            Text("Admin Section")
        }, content = { context ->
            AdminConfiguration(context.org, context.project)
        }
    )
}
