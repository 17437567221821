package com.appcreator.dto

import kotlinx.serialization.Serializable

@Serializable
data class FeedbackRequest(
    val type: String,
    val message: String,
    val href: String,
)
