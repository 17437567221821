package com.appcreator.blueprint.actions

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.ColorReference
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("CopyToClipboardAction")
@ActionClass(
    group = "Alerts",
    title = "Copy to clipboard Alert",
    availableFrom = "0.0.1",
)
class CopyToClipboardAction(
    @InputProperty(
        title = "Text",
        availableFrom = "0.0.1"
    )
    val text: String?,
): Action