package com.appcreator.blueprint.actions.data

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.DataOptionsFrom
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.core.properties.DataValueSet
import com.appcreator.blueprint.spec.inputs.DataSpecType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("AddListItem")
@ActionClass(
    group = "Data",
    title = "Add Item to List",
    availableFrom = "0.0.1"
)
class AddListItemAction(
    @InputProperty(
        title = "List",
        availableFrom = "0.0.1",
        dataOptions = DataSpecType.List,
        settableDataOnly = true
    )
    val listPath: DataPath?,
    @InputProperty(
        title = "Values",
        availableFrom = "0.0.1",
    )
    @DataOptionsFrom("listPath")
    val values: DataValueSet?,
): Action
