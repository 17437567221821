package com.appcreator.compose.actions.navigation

import androidx.compose.runtime.Composable
import androidx.compose.ui.platform.LocalUriHandler
import com.appcreator.blueprint.actions.navigation.OpenExternalAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.actions.Performer

class OpenExternalPerformer(private val action: OpenExternalAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        val navigator = LocalUriHandler.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.link?.let {
                    val link = envStore.injectVariables(it)
                    navigator.openUri(link)
                }
            }
        }
    }

}
