package com.appcreator.blueprint.firebase.auth

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FirebaseAuthResetPassword")
@ActionClass(
    group = FirebaseAuthConsts.group,
    title = "Reset Password",
    isExtraPlugin = true,
    availableFrom = "0.0.1"
)
class FirebaseAuthResetPasswordAction(
    @InputProperty(
        title = "Email",
        availableFrom = "0.0.1"
    )
    val email: String?,
): Action
