package com.appcreator.blueprint.contentful

import com.appcreator.blueprint.contentful.ContentfulLoaderSpec.Keys.listValue
import com.appcreator.blueprint.core.DataShape
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.blueprint.core.findParameters
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.RichTextDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import kotlinx.coroutines.Job
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive


@Serializable
@SerialName("ContentfulLoaderSpec")
data class ContentfulLoaderSpec(
    override val id: String,
    override val parameterSettings: Map<String, LoaderSpec.ParameterSetting>?,
    val modelName: String?,
    val modelId: String?,
    val limit: Int?,
    val orderBy: List<Order>?,
    val where: List<Where>?,
    val dataValues: List<ContentfulData>?
): LoaderSpec {

    object Keys {
        const val assetId = "assetId"
        const val fileUrl = "fileUrl"
        const val fileTitle = "fileTitle"
        const val width = "width"
        const val height = "height"
        const val contentType = "contentType"
        const val entryTitle = "entryTitle"
        const val entryId = "entryId"

        const val listValue = "value"

    }

    override val dataShape: DataShape
        get() {
            val shape = dataValues?.map {
                when(it.type) {
                    ContentfulDataType.Default -> listOf(ValueDataSpec(it.field, ValueDataSpec.Type.String, it.preview, true))
                    ContentfulDataType.RichText -> listOf(RichTextDataSpec(it.field))
                    ContentfulDataType.Asset -> assetShape(it.field + "-", it.preview)
                    ContentfulDataType.Entry -> entryShape(it.field + "-")
                    ContentfulDataType.AssetList -> listOf(ListDataSpec(it.field, assetShape("", it.preview)))
                    ContentfulDataType.EntryList -> listOf(ListDataSpec(it.field, entryShape("")))
                    ContentfulDataType.List -> listOf(ListDataSpec(it.field, listOf(ValueDataSpec(listValue, ValueDataSpec.Type.String, it.preview, true))))
                }
            }?.flatten()
                ?.plus(ValueDataSpec(Keys.entryId, ValueDataSpec.Type.String, "", true))?: emptyList()

            return if(modelId != null || limit == 1) {
                DataShape(shape)
            } else DataShape(listOf(ListDataSpec(modelName ?: "", shape)))
        }
    override val supportsPaging: Boolean get() = limit != 1 && modelId == null

    private fun entryShape(prefix: String): List<ValueDataSpec> {
        return listOf(
            ValueDataSpec(prefix + Keys.entryId, ValueDataSpec.Type.String, "", true),
            ValueDataSpec(prefix + Keys.contentType, ValueDataSpec.Type.String, "", true)
        )

    }
    private fun assetShape(prefix: String, imageUrl: String): List<ValueDataSpec> {
        return listOf(
            ValueDataSpec(prefix + Keys.assetId, ValueDataSpec.Type.String, "", true),
            ValueDataSpec(prefix + Keys.fileUrl, ValueDataSpec.Type.String, imageUrl, true),
            ValueDataSpec(prefix + Keys.fileTitle, ValueDataSpec.Type.String, "Title", true),
            ValueDataSpec(prefix + Keys.width, ValueDataSpec.Type.String, "", true),
            ValueDataSpec(prefix + Keys.height, ValueDataSpec.Type.String, "", true),
            ValueDataSpec(prefix + Keys.contentType, ValueDataSpec.Type.String, "", true)
        )
    }

    override val parameters: Set<String>
        get() {
            val pathParams = (where?.map { it.value.findParameters() }?.flatten()?: emptyList()) + (modelId?.findParameters()?: emptyList())
            return pathParams.toSet()
        }

    @Serializable
    data class Order(
        val field: String,
        val direction: OrderBy
    )

    enum class OrderBy {
        Ascending,
        Descending
    }

    @Serializable
    data class Where(val field: String, val value: String)

    @Serializable
    data class ContentfulData(
        val field: String,
        val preview: String,
        val type: ContentfulDataType
    )

    enum class ContentfulDataType {
        Default,
        List,
        RichText,
        Entry,
        EntryList,
        Asset,
        AssetList
    }

}
