package com.appcreator.blueprint.conversions

import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.ConversionClass
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.spec.inputs.DataSpecType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("ListCountDataConversion")
@ConversionClass(
    title = "List Count",
    availableFrom = "0.0.1"
)
class ListCountConversion(
    @InputProperty(
        title = "Path",
        hidden = true,
        availableFrom = "0.0.1",
        dataOptions = DataSpecType.List
    )
    val listPath: DataPath?
): Conversion