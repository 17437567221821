package com.appcreator.creatorapp.editor.centerimages

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.FileCopy
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import coil3.compose.SubcomposeAsyncImage
import com.appcreator.app.utils.addToClipboard
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.compose.components.basic.PlatformImage
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.crm.ImageUploaderModal
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.FileResponse
import com.appcreator.local.LocalSnackbar
import com.appcreator.local.SnackbarInfo
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.launch

@Composable
fun ImageCenterModal(
    orgId: String,
    projectId: String,
    anchor: @Composable (() -> Unit) -> Unit
) {
    ModalScaffold(
        anchor = anchor,
        header = {
            Text("Image center")
        },
        body = {
            ImageCenterContent(orgId, projectId)
        }
    )
}

@Composable
fun ImageCenterContent(
    orgId: String,
    projectId: String,
    selected: MutableState<FileResponse?> = remember { mutableStateOf(null) }
) {
    val scope = rememberCoroutineScope()
    LoadingComposable(loader = {
        mutableStateListOf(*it.getFiles(orgId, projectId, "png,jpeg,svg,webp,gif").files.toTypedArray()).also {
            selected.value = it.firstOrNull()
        }
    }) { data ->

        Row(Modifier.fillMaxSize()) {

            Box(Modifier.width(240.dp).fillMaxHeight()) {

                LazyColumn(
                    modifier = Modifier.fillMaxSize(),
                    contentPadding = PaddingValues(bottom = 64.dp)
                ) {
                    items(data, key = { it }) { image ->
                        NavigationDrawerItem(
                            colors = NavigationDrawerItemDefaults.colors(unselectedContainerColor = Color.Unspecified),
                            selected = selected.value == image,
                            label = {
                                Row(
                                    modifier = Modifier.fillMaxWidth(),
                                    verticalAlignment = Alignment.CenterVertically,
                                    horizontalArrangement = Arrangement.SpaceBetween
                                ) {
                                    Text(image.title)
                                }
                            },
                            onClick = {
                                selected.value = image
                            }
                        )
                    }
                }

                if (data.isEmpty()) {
                    Text("No images")
                }

                if (selected.value != null) {
                    ExtendedFloatingActionButton(
                        modifier = Modifier.align(Alignment.BottomCenter),
                        text = {
                            Text("Add")
                        },
                        icon = {
                            Icon(Icons.Default.Add, contentDescription = null)
                        },
                        onClick = {
                            selected.value = null
                        }
                    )
                }
            }

            Spacer(Modifier.width(ThemeMargins.SmallMargin))
            VerticalDivider()
            Spacer(Modifier.width(ThemeMargins.SmallMargin))

            selected.value?.let {
                Column {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Text(
                            text = it.title,
                            style = MaterialTheme.typography.headlineMedium
                        )
                        Spacer(Modifier.width(ThemeMargins.LargeMargins))
                        TextButton(onClick = {
                            addToClipboard(it.url)
                        }) {
                            Icon(Icons.Default.FileCopy, contentDescription = null)
                            Spacer(Modifier.width(ThemeMargins.SmallMargin))
                            Text("Copy URL")
                        }

                        Spacer(Modifier.width(ThemeMargins.LargeMargins))

                        val client = LocalApiClient.current
                        val snackbarChannel = LocalSnackbar.current
                        TextButton(onClick = {
                            scope.launch {
                                try {
                                    client.deleteFile(orgId, projectId, it.id)
                                    data.remove(it)
                                    selected.value = data.firstOrNull()
                                } catch (ex: Exception) {
                                    ex.printStackTrace()
                                    snackbarChannel.send(SnackbarInfo.Builder.fromNetworkException(ex))
                                }
                            }
                        }) {
                            Icon(Icons.Default.Delete, contentDescription = null)
                            Spacer(Modifier.width(ThemeMargins.SmallMargin))
                            Text("Delete")
                        }
                    }

                    SubcomposeAsyncImage(
                        model = it.url,
                        success = { success ->
                            PlatformImage(Modifier, contentScale, success) {
                                Image(
                                    modifier = it,
                                    painter = success.painter,
                                    contentDescription = null,
                                    contentScale = contentScale
                                )
                            }
                        },
                        contentDescription = null,
                    )

                }
            }?: run {
                ImageUploaderModal(orgId = orgId, projectId = projectId) {
                    data.add(it)
                    selected.value = it
                }
            }
        }
    }
}
