package com.appcreator.components.layouts

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Close
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import androidx.compose.ui.window.DialogProperties
import com.appcreator.local.SnackbarContainer
import com.appcreator.styles.ThemeMargins

sealed class ModalSize {
    data object Small: ModalSize()
    data object Medium: ModalSize()
    data class Large(val minus: Dp = 0.dp): ModalSize()
    data class Custom(val modifier: Modifier): ModalSize()
}

@Composable
fun ModalScaffold(
    size: ModalSize = ModalSize.Large(),
    anchor: @Composable (() -> Unit) -> Unit,
    header: @Composable () -> Unit,
    body: @Composable (() -> Unit) -> Unit,
    footer: @Composable ((() -> Unit) -> Unit)? = null
) {
    Modal(size, anchor) { close ->
        Column(Modifier.padding(ThemeMargins.SmallMargin)) {
            Row(
                Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                CompositionLocalProvider(LocalTextStyle provides MaterialTheme.typography.headlineMedium) {
                    header()
                }

                IconButton(
                    onClick = close
                ) {
                    Icon(Icons.Default.Close, contentDescription = null)
                }
            }

            Box(Modifier.weight(1f)) {
                body(close)
            }

            footer?.let {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.End
                ) {
                    footer(close)
                }
            }
        }
    }
}

@Composable
fun Modal(
    size: ModalSize = ModalSize.Large(),
    anchor: @Composable (() -> Unit) -> Unit,
    content: @Composable (() -> Unit) -> Unit
) {

    var visible by remember { mutableStateOf(false) }

    if (visible) {
        Dialog(
            properties = DialogProperties(usePlatformDefaultWidth = false),
            onDismissRequest = {
                visible = false
            }
        ) {
            val modifier = when(size) {
                ModalSize.Small -> Modifier.sizeIn(maxWidth = 600.dp, maxHeight = 400.dp)
                ModalSize.Medium -> Modifier.sizeIn(maxWidth = 1000.dp, maxHeight = 800.dp)
                is ModalSize.Large -> Modifier.sizeIn(maxWidth = 1400.dp - size.minus, maxHeight = 1200.dp - size.minus)
                is ModalSize.Custom -> size.modifier
            }

            Box(modifier.padding(ThemeMargins.SmallMargin)) {
                Card(
                    modifier = Modifier.fillMaxSize(),
                    colors = CardDefaults.cardColors(containerColor = MaterialTheme.colorScheme.surfaceContainer),
                    elevation = CardDefaults.cardElevation(defaultElevation = 0.dp)
                ) {
                    SnackbarContainer {
                        content {
                            visible = false
                        }
                    }
                }
            }
        }
    }

    anchor {
        visible = true
    }
}

