package com.appcreator.compose.di

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.conditions.Evaluator
import com.appcreator.compose.conversions.Convertor
import com.appcreator.compose.extensions.JSEvaluationConfig
import com.appcreator.compose.interfaces.Analytics
import com.appcreator.compose.interfaces.GroupAnalytics
import com.appcreator.compose.loaders.Loader
import kotlinx.serialization.json.Json
import kotlin.reflect.KClass

object Container {

    var jsConfig: JSEvaluationConfig? = null

    val componentRegistry = mutableMapOf<KClass<out Component>, @Composable (Modifier, Component) -> Unit>()

    fun <T: Component> registerComponent(clazz: KClass<T>, content: @Composable (Modifier, T) -> Unit) {
        componentRegistry[clazz] = { modifier, component -> content(modifier, component as T) }
    }

    internal val conditionRegistry = mutableMapOf<KClass<out Condition>, (Condition) -> Evaluator>()

    fun <T: Condition> registerCondition(clazz: KClass<T>, content: (T) -> Evaluator) {
        conditionRegistry[clazz] = { content(it as T) }
    }

    internal val loaderRegistry = mutableMapOf<KClass<out LoaderSpec>, (LoaderSpec) -> Loader>()

    fun <T: LoaderSpec> registerLoader(clazz: KClass<T>, content: (T) -> Loader) {
        loaderRegistry[clazz] = { content(it as T) }
    }

    internal val actionRegistry = mutableMapOf<KClass<out Action>, (Action) -> Performer>()

    fun <T: Action> registerAction(clazz: KClass<T>, content: (T) -> Performer) {
        actionRegistry[clazz] = { content(it as T) }
    }

    internal val conversionRegistry = mutableMapOf<KClass<out Conversion>, (Conversion) -> Convertor>()

    fun <T: Conversion> registerConversion(clazz: KClass<T>, content: (T) -> Convertor) {
        conversionRegistry[clazz] = { content(it as T) }
    }

    internal val analytics = GroupAnalytics()

    fun registerAnalytics(analytics: Analytics) {
        this.analytics.children.add(analytics)
    }

    var globalKeys = mutableMapOf<String, Any>()
}

fun Container.evaluator(condition: Condition) = conditionRegistry[condition::class]?.invoke(condition).also {
    it?: println("----- No condition registered for ${condition::class} -----")
}

fun Container.performer(action: Action) = actionRegistry[action::class]?.invoke(action).also {
    it?: println("----- No action registered for ${action::class} -----")
}

fun Container.convertor(conversion: Conversion) = conversionRegistry[conversion::class]?.invoke(conversion).also {
    it?: println("----- No conversion registered for ${conversion::class} -----")
}