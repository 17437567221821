package com.appcreator.compose

import androidx.compose.foundation.layout.BoxWithConstraintsScope
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.properties.SizeValue


class AbsoluteSize(
    private val globalWidth: Dp,
    private val globalHeight: Dp,
    private val localWidth: Dp?,
    private val localHeight: Dp?,
) {
    constructor(scope: BoxWithConstraintsScope) : this(
        globalWidth = scope.maxWidth,
        globalHeight = scope.maxHeight,
        localWidth = scope.maxWidth,
        localHeight = scope.maxHeight
    )

    constructor(
        parent: AbsoluteSize,
        width: Dp?,
        height: Dp?,
        padding: PaddingValues,
    ) : this(
        globalWidth = parent.globalWidth - calculateHorizontal(padding),
        globalHeight = parent.globalHeight - calculateVertical(padding),
        localWidth = width?.let { it - calculateHorizontal(padding) },
        localHeight = height?.let { it - calculateVertical(padding) },
    )

    fun width(spacing: Int, width: SizeValue?, margin: PaddingValues): Dp? {
        return width?.let {
            when(it) {
                is SizeValue.Px -> it.value.dp + calculateHorizontal(margin)
                is SizeValue.Percent -> {
                    val availableWidth = (localWidth?: globalWidth) - spacing.dp
                    (availableWidth * it.value) / 100f
                }
            }
        }
    }

    fun height(spacing: Int, height: SizeValue?, margin: PaddingValues): Dp? {
        return height?.let {
            when(it) {
                is SizeValue.Px -> it.value.dp + calculateVertical(margin)
                is SizeValue.Percent -> {
                    val availableHeight = (localHeight?: globalHeight) - spacing.dp
                    (availableHeight * it.value) / 100f
                }
            }
        }
    }

}

private fun calculateHorizontal(padding: PaddingValues): Dp =
    padding.calculateLeftPadding(LayoutDirection.Ltr) + padding.calculateRightPadding(LayoutDirection.Ltr)

private fun calculateVertical(padding: PaddingValues): Dp =
    padding.calculateTopPadding() + padding.calculateBottomPadding()
