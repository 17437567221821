package com.appcreator.compose.actions

import androidx.compose.runtime.Composable
import coil3.PlatformContext
import coil3.compose.LocalPlatformContext
import com.appcreator.blueprint.actions.AlertAction
import com.appcreator.blueprint.actions.CopyToClipboardAction
import com.appcreator.blueprint.core.EnvStore

class CopyToClipboardPerformer(private val action: CopyToClipboardAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        val context = LocalPlatformContext.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.text?.let {
                    copyTextToClipboard(envStore.injectVariables(it), context)
                }
            }
        }
    }
}

expect fun copyTextToClipboard(text: String, context: PlatformContext)