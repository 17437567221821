package com.appcreator.creatorapp.editor.centerdata.contentful

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.SegmentedButton
import androidx.compose.material3.SingleChoiceSegmentedButtonRow
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.contentful.ContentfulLoaderSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.api.MutableDataSource
import com.appcreator.styles.ThemeMargins


@Composable
fun ContentfulQuery(
    data: MutableDataSource,
    spec: ContentfulLoaderSpec,
    save: () -> Unit
) {
    Column(Modifier.widthIn(max = 600.dp)) {

        CreatorTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Model",
            value = spec.modelName ?: "",
            onValueChange = {
                data.loaderSpec = spec.copy(modelName = it)
                save()
            }
        )

        Spacer(Modifier.height(ThemeMargins.SmallMargin))
        SingleChoiceSegmentedButtonRow {
            SegmentedButton(
                selected = spec.modelId != null,
                onClick = {
                    data.loaderSpec = spec.copy(modelId = "")
                    save()
                },
                shape = RoundedCornerShape(
                    topStartPercent = 25,
                    bottomStartPercent = 25
                )
            ) {
                Text(text = "By ID")
            }
            SegmentedButton(
                selected = spec.modelId == null,
                onClick = {
                    data.loaderSpec = spec.copy(modelId = null)
                    save()
                },
                shape = RoundedCornerShape(
                    topEndPercent = 25,
                    bottomEndPercent = 25
                )
            ) {
                Text("Query")
            }
        }
        spec.modelId?.let {

            CreatorTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "ID",
                value = it,
                onValueChange = { updated ->
                    data.loaderSpec = spec.copy(modelId = updated)
                    save()
                }
            )

        }?: run {



//        if (spec.dataPath?.isNotEmpty() == true) {
//
//            if (spec.documentFetch) {
//                Text(
//                    style = MaterialTheme.typography.bodySmall,
//                    text = "This path will fetch a single document"
//                )
//            } else {

            Spacer(Modifier.height(ThemeMargins.LargeMargins))

            CreatorTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Limit",
                value = spec.limit?.toString()?: "",
                onValueChange = {
                    data.loaderSpec = spec.copy(limit = it.toIntOrNull())
                    save()
                }
            )

            val where = remember { mutableStateListOf(*(spec.where?: emptyList()).toTypedArray()) }
            where.forEachIndexed { index, item ->
                WhereRow(
                    where = item,
                    update = {
                        where[index] = it
                        data.loaderSpec = spec.copy(where = where)
                        save()
                    },
                    remove = {
                        where.remove(item)
                        data.loaderSpec = spec.copy(where = where)
                        save()
                    }
                )
            }

            TextButton(onClick = {
                where.add(
                    ContentfulLoaderSpec.Where(
                        field = "",
                        value = ""
                    )
                )
            }) {
                Icon(Icons.Default.Add, contentDescription = null)
                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                Text("Add to query")
            }

            val order = remember { mutableStateListOf(*(spec.orderBy?: emptyList()).toTypedArray()) }
            order.forEachIndexed { index, item ->
                OrderRow(
                    order = item,
                    update = {
                        order[index] = it
                        data.loaderSpec = spec.copy(orderBy = order)
                        save()
                    },
                    remove = {
                        order.remove(item)
                        data.loaderSpec = spec.copy(orderBy = order)
                        save()
                    }
                )
            }

            TextButton(onClick = {
                order.add(
                    ContentfulLoaderSpec.Order(
                        field = "",
                        direction = ContentfulLoaderSpec.OrderBy.Ascending
                    )
                )
            }) {
                Icon(Icons.Default.Add, contentDescription = null)
                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                Text("Add to order")
            }
        }
    }
}

@Composable
private fun WhereRow(where: ContentfulLoaderSpec.Where, update: (ContentfulLoaderSpec.Where) -> Unit, remove: () -> Unit) {

    Row(horizontalArrangement = Arrangement.spacedBy(ThemeMargins.SmallMargin)) {
        CreatorTextInput(
            modifier = Modifier.weight(1f),
            label = "Field",
            value = where.field,
            onValueChange = {
                update(where.copy(field = it))
            }
        )

//        DropDownInputGroup(
//            modifier = Modifier.width(100.dp),
//            title = "",
//            dropDownTitle = where.type.display,
//            dropDownContent = { close ->
//                ContentfulLoaderSpec.WhereType.entries.forEach {
//                    DropdownItem(
//                        title = "${it.name.split("(?=\\p{Upper})".toRegex()).joinToString(separator = " ")} (${it.display})"
//                    ) {
//                        update(where.copy(type = it))
//                        close()
//                    }
//                }
//            }
//        )

        CreatorTextInput(
            modifier = Modifier.weight(1f),
            label = "Value",
            value = where.value,
            onValueChange = {
                update(where.copy(value = it))
            },
            trailingIcon = {
                IconButton(
                    onClick = remove
                ) {
                    Icon(Icons.Default.Delete, contentDescription = null)
                }
            }
        )
    }
}

@Composable
private fun OrderRow(order: ContentfulLoaderSpec.Order, update: (ContentfulLoaderSpec.Order) -> Unit, remove: () -> Unit) {

    Row(horizontalArrangement = Arrangement.spacedBy(ThemeMargins.SmallMargin), verticalAlignment = Alignment.CenterVertically) {
        CreatorTextInput(
            modifier = Modifier.weight(1f),
            label = "Field",
            value = order.field,
            onValueChange = {
                update(order.copy(field = it))
            }
        )

        DropDownInputGroup(
            modifier = Modifier.width(300.dp),
            title = "",
            dropDownTitle = order.direction.name,
            dropDownContent = { close ->
                ContentfulLoaderSpec.OrderBy.entries.forEach {
                    DropdownItem(
                        title = it.name
                    ) {
                        update(order.copy(direction = it))
                        close()
                    }
                }
            }
        )

        IconButton(
            onClick = remove
        ) {
            Icon(Icons.Default.Delete, contentDescription = null)
        }
    }
}
