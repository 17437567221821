package com.appcreator.blueprint.firebase.firestore

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.TypedDataValue
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FirebaseFirestoreSetData")
@ActionClass(
    group = FirebaseFirestoreConsts.group,
    title = "Set Data",
    isExtraPlugin = true,
    availableFrom = "0.0.1"
)
class FirebaseFirestoreSetDataAction(
    @InputProperty(
        title = "Collection Path",
        availableFrom = "0.0.1"
    )
    val path: String?,
    @InputProperty(
        title = "Data",
        availableFrom = "0.0.1"
    )
    val data: List<TypedDataValue>?,
): Action
