package com.appcreator.dto.configurations

import com.appcreator.dto.PlatformKeys
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.decodeFromJsonElement

@Serializable
data class ThirdPartyConfiguration(
    val type: String,
    val enabled: Boolean,
    val components: List<String>,
    val globalState: Set<String>?,
    val config: JsonObject?
)

object ThirdPartyConfigurationTypes {
    const val firebase = "firebase"
    const val contentful = "contentful"
}

fun ThirdPartyConfiguration.isValid(json: Json, platforms: String): Pair<Boolean, String?> {
    config?: return false to "Third party service $type is enabled without config set up."
    when(type) {
        ThirdPartyConfigurationTypes.firebase -> json.decodeFromJsonElement<FirebaseConfiguration>(config).let {
            if (platforms.contains(PlatformKeys.Android) && it.androidServiceJson.isNullOrBlank()) {
                return false to "Firebase is enabled but there is no google-services.json uploaded."
            }
            if (platforms.contains(PlatformKeys.iOS) && it.iosServicePlist.isNullOrBlank()) {
                return false to "Firebase is enabled but there is no GoogleServices-info.plist uploaded."
            }
            if (platforms.contains(PlatformKeys.Web)) {
                if (it.webConfig.apiKey.isBlank()
                    || it.webConfig.apiKey.isBlank()
                    || it.webConfig.apiKey.isBlank()
                    || it.webConfig.apiKey.isBlank()
                    || it.webConfig.apiKey.isBlank()
                    || it.webConfig.apiKey.isBlank()
                    || it.webConfig.apiKey.isBlank())
                return false to "Firebase is enabled but web configuration is noty completed"
            }
        }
    }
    return true to null
}