package com.appcreator.creatorapp.project

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.material3.Checkbox
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.components.layouts.ModalSize
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.creatorapp.project.configurations.LoadingButton
import com.appcreator.dto.Organisation
import com.appcreator.dto.PlatformKeys
import com.appcreator.dto.Project
import com.appcreator.dto.ReleaseResponse
import com.appcreator.styles.ThemeMargins

@Composable
fun ReleaseModal(
    organisation: Organisation,
    project: Project,
    lastRelease: ReleaseResponse?,
    anchor: @Composable (() -> Unit) -> Unit,
    onCreate: (ReleaseResponse) -> Unit
) {

    ModalScaffold(
        size = ModalSize.Custom(Modifier.sizeIn(maxWidth = 600.dp, maxHeight = 500.dp)),
        anchor = anchor,
        header = {
            Text("New Release")
        },
        body = { close ->
            Column {
                var version by remember { mutableStateOf(lastRelease?.nextRelease() ?: "1.0.0") }
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Version",
                    value = version,
                    onValueChange = {
                        version = it
                    }
                )
                var android by remember {
                    mutableStateOf(
                        lastRelease?.platform?.contains(PlatformKeys.Android) ?: true
                    )
                }
                var ios by remember {
                    mutableStateOf(
                        lastRelease?.platform?.contains(PlatformKeys.iOS) ?: true
                    )
                }
                var web by remember {
                    mutableStateOf(
                        lastRelease?.platform?.contains(PlatformKeys.Web) ?: true
                    )
                }

                var prerender by remember {
                    mutableStateOf(
                        lastRelease?.prerender == true
                    )
                }

                var host by remember {
                    mutableStateOf(
                        lastRelease?.host?: ""
                    )
                }

                Spacer(Modifier.height(ThemeMargins.LargeMargins))

                Text("Platforms to release")
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Checkbox(
                        checked = android,
                        onCheckedChange = {
                            android = it
                        }
                    )
                    Text("Android")
                }
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Checkbox(
                        checked = ios,
                        onCheckedChange = {
                            ios = it
                        }
                    )
                    Text("iOS")
                }
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Checkbox(
                        checked = web,
                        onCheckedChange = {
                            web = it
                        }
                    )
                    Text("Web")

                    if (web) {
                        Checkbox(
                            checked = prerender,
                            onCheckedChange = {
                                prerender = it
                            }
                        )
                        Text("Prerender pages for improved SEO (experimental)")
                    }

                }

                if(web && prerender) {
                    CreatorTextInput(
                        modifier = Modifier.fillMaxWidth(),
                        value = host,
                        onValueChange = {
                            host = it
                        },
                        label = "Website host",
                        supportingText = "Request to create site map with absolute URLs"
                    )
                }
                Spacer(Modifier.weight(1f))

                val client = LocalApiClient.current
                LoadingButton(
                    enabled = version.isNotEmpty() && (android || ios || web),
                    modifier = Modifier.align(Alignment.End),
                    label = "Confirm"
                ) {
                    val platform = listOfNotNull(
                        if(android) PlatformKeys.Android else null,
                        if(ios) PlatformKeys.iOS else null,
                        if(web) PlatformKeys.Web else null,
                    ).joinToString(separator = ",")
                    val result = client.startRelease(organisation.id, project.id, platform, version, host, prerender)
                    onCreate(result)
                    close()
                }
            }
        }
    )
}

private fun ReleaseResponse.nextRelease(): String {
    return version // Don't increase its annoying for testing
//    return try {
//        val parts = version.split(".")
//        "${parts[0]}.${parts[1].toInt() + 1}.0"
//    } catch (ex: Exception) {
//        null
//    }
}