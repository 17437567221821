package com.appcreator.blueprint.core.properties

import kotlinx.serialization.Serializable

@Serializable
data class ComponentPosition(
    val positionOf: String,
    val x: Float?,
    val xAlign: XAlign = XAlign.Center,
    val y: Float?,
    val yAlign: YAlign = YAlign.Center,
) {
    enum class XAlign {
        Start,
        Center,
        End
    }

    enum class YAlign {
        Top,
        Center,
        Bottom
    }
}
