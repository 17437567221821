package com.appcreator.creatorapp.project.newproject

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import com.appcreator.client.contentful.richtext.RichTextRenderer
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.creatorapp.local.LocalContentfulClient
import com.appcreator.creatorapp.project.configurations.ContentfulConfiguration
import com.appcreator.creatorapp.project.configurations.LoadingButton
import com.appcreator.dto.configurations.ContentfulConfiguration
import com.appcreator.local.LocalOrganisation
import com.appcreator.local.LocalProject
import com.appcreator.shared.api.ApiClient
import com.appcreator.styles.ThemeMargins
import io.ktor.client.HttpClient
import io.ktor.client.request.header
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.HttpHeaders
import io.ktor.http.contentType
import io.ktor.http.path
import kotlinx.serialization.json.jsonArray
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive

@Composable
fun SetupContentfulSlide(templateId: String, completed: () -> Unit) {


    var cmaToken by remember { mutableStateOf("") }
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "CMA Token",
        value = cmaToken,
        onValueChange = { cmaToken = it }
    )

    Spacer(Modifier.height(ThemeMargins.LargeMargins))

    var saved by remember { mutableStateOf<ContentfulConfiguration?>(null) }
    ContentfulConfiguration(
        forceEnabled = true,
        organisation = LocalOrganisation.current,
        project = LocalProject.current,
        saveButtonAtTop = false,
        inset = 0.dp
    ) {
        saved = it
    }

    val orgId = LocalOrganisation.current.id
    val apiClient = LocalApiClient.current
    val contentfulClient = LocalContentfulClient.current
    var synced by remember { mutableStateOf(false) }
    LoadingButton(
        label = "Sync Template to Contentful",
        enabled = !synced && !saved?.space.isNullOrEmpty() && cmaToken.isNotEmpty(),
        action = {
            saved?.let {
                createModel(
                    apiClient,
                    contentfulClient,
                    orgId,
                    templateId,
                    cmaToken,
                    it
                )
            }
            synced = true
            completed()
    })

    if(synced) {
        Text("Contentful is now synced, refresh Contentful and you should see created content models.")
        Row(verticalAlignment = Alignment.CenterVertically) {
            TextButton(onClick = {
                synced = false
            }) {
                Text("Start Again")
            }
            Text(
                text = "Start over if set up did not work, make sure to delete all content and content models from Contentful",
                style = MaterialTheme.typography.labelMedium
            )
        }
        Text("Click next to move to the next step")
    } else {
        Row(verticalAlignment = Alignment.CenterVertically) {
            TextButton(onClick = {
                completed()
            }) {
                Text("I have already performed this step")
            }
            Text(
                text = "Only click this if contentful is already set up but you need to rerun through other steps. Your app will not work without this.",
                style = MaterialTheme.typography.labelMedium
            )
        }
    }
}

private suspend fun createModel(
    apiClient: ApiClient,
    contentfulClient: HttpClient,
    orgId: String,
    templateId: String,
    token: String,
    config: ContentfulConfiguration
) {

    val template = apiClient.getContentfulConfiguration(orgId, templateId)

    val contentTypes = template["contentTypes"]?.jsonArray
    contentTypes?.forEach { model ->
        val id = model.jsonObject["sys"]?.jsonObject?.get("id")?.jsonPrimitive?.content?: throw IllegalStateException("ID Not found")
        contentfulClient.put {
            url {
                header(HttpHeaders.Authorization, "Bearer $token")
                path("spaces", config.space, "environments", config.environment, "content_types", id)
                contentType(ContentType.Application.Json)
                setBody(model.toString())
            }
        }
        contentfulClient.put {
            url {
                header(HttpHeaders.Authorization, "Bearer $token")
                header("X-Contentful-Version", "1")
                path("spaces", config.space, "environments", config.environment, "content_types", id, "published")
            }
        }
    }

    val entries = template["entries"]?.jsonArray
    entries?.forEach { entry ->
        val modelId = entry.jsonObject["sys"]?.jsonObject?.get("contentType")?.jsonObject?.get("sys")?.jsonObject?.get("id")?.jsonPrimitive?.content?: throw IllegalStateException("ID Not found")
        val entryId = entry.jsonObject["sys"]?.jsonObject?.get("id")?.jsonPrimitive?.content?: throw IllegalStateException("ID Not found")
        contentfulClient.put {
            url {
                header(HttpHeaders.Authorization, "Bearer $token")
                header("X-Contentful-Content-Type", modelId)

                path("spaces", config.space, "environments", config.environment, "entries", entryId)
                contentType(ContentType.Application.Json)
                setBody(entry.toString())
            }
        }

        try {
            contentfulClient.put {
                url {
                    header(HttpHeaders.Authorization, "Bearer $token")
                    header("X-Contentful-Version", "1")
                    path("spaces", config.space, "environments", config.environment, "entries", entryId, "published")
                }
            }
        } catch (ex: Exception) {
            println("Can't publish entry")
        }
    }
}
