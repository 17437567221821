@file:OptIn(ExperimentalSerializationApi::class)

package com.appcreator.client.contentful.richtext

import com.appcreator.client.contentful.RichTextInfo
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonClassDiscriminator
import kotlinx.serialization.json.decodeFromJsonElement

interface RichBlock {
    val content: List<RichNode>
}

interface EntryBlock: RichBlock {
    val data: EntryData
}
@Serializable
class EntryData(
    val target: EntryLink
)

@Serializable
data class EntryLink(
    @SerialName("sys")
    val sys: Sys
)

@Serializable
data class Sys(
    @SerialName("id")
    val id: String,
    @SerialName("linkType")
    val linkType: String?,
    @SerialName("type")
    val type: String,
    @SerialName("contentType")
    val contentType: ContentType?
) {
    @Serializable
    data class ContentType(
        @SerialName("sys")
        val sys: Sys,
    )
}

interface HeadingBlock: RichBlock {
    val level: HeadingLevel
}
enum class HeadingLevel {
    H1,
    H2,
    H3,
    H4,
    H5,
    H6
}

@Serializable
@JsonClassDiscriminator("nodeType")
sealed class RichNode {

    @Serializable
    @SerialName("text")
    data class Text(
        val marks: List<RichMark>,
        val value: String
    ): RichNode()

    @Serializable
    @SerialName("hr")
    data object Hr: RichNode()

    @Serializable
    @SerialName("blockquote")
    data class Quote(override val content: List<RichNode>): RichNode(), RichBlock

    @Serializable
    @SerialName("paragraph")
    data class Paragraph(override val content: List<RichNode>): RichNode(), RichBlock

    @Serializable
    @SerialName("document")
    data class Document(override val content: List<RichNode>): RichNode(), RichBlock

    @Serializable
    @SerialName("list-item")
    data class ListItem(override val content: List<RichNode>): RichNode(), RichBlock
    @Serializable
    @SerialName("ordered-list")
    data class OrderedList(override val content: List<RichNode>): RichNode(), RichBlock
    @Serializable
    @SerialName("unordered-list")
    data class UnorderedList(override val content: List<RichNode>): RichNode(), RichBlock
    @Serializable
    @SerialName("table")
    data class Table(override val content: List<RichNode>): RichNode(), RichBlock
    @Serializable
    @SerialName("table-row")
    data class TableRow(override val content: List<RichNode>): RichNode(), RichBlock
    @Serializable
    @SerialName("table-header-cell")
    data class TableHeaderCell(override val content: List<RichNode>): RichNode(), RichBlock
    @Serializable
    @SerialName("table-cell")
    data class TableCell(override val content: List<RichNode>): RichNode(), RichBlock

    @Serializable
    @SerialName("hyperlink")
    data class HyperLink(
        override val content: List<RichNode>,
        val data: HyperlinkData
    ): RichNode(), RichBlock

    @Serializable
    @SerialName("entry-hyperlink")
    data class EntryHyperLink(
        override val content: List<RichNode>,
        override val data: EntryData
    ): RichNode(), EntryBlock

    @Serializable
    @SerialName("asset-hyperlink")
    data class AssetHyperLink(
        override val content: List<RichNode>,
        override val data: EntryData
    ): RichNode(), EntryBlock

    @Serializable
    @SerialName("embedded-entry-block")
    data class EmbeddedEntryBlock(
        override val content: List<RichNode>,
        override val data: EntryData
    ): RichNode(), EntryBlock

    @Serializable
    @SerialName("embedded-entry-inline")
    data class EmbeddedEntryInline(
        override val content: List<RichNode>,
        override val data: EntryData
    ): RichNode(), EntryBlock

    @Serializable
    @SerialName("embedded-asset-block")
    data class EmbeddedAssetBlock(
        override val content: List<RichNode>,
        override val data: EntryData
    ): RichNode(), EntryBlock

    @Serializable
    @SerialName("heading-1")
    data class H1(
        override val content: List<RichNode>,
    ): RichNode(), HeadingBlock {
        override val level: HeadingLevel
            get() = HeadingLevel.H1
    }

    @Serializable
    @SerialName("heading-2")
    data class H2(
        override val content: List<RichNode>,
    ): RichNode(), HeadingBlock {
        override val level: HeadingLevel
            get() = HeadingLevel.H2
    }

    @Serializable
    @SerialName("heading-3")
    data class H3(
        override val content: List<RichNode>,
    ): RichNode(), HeadingBlock {
        override val level: HeadingLevel
            get() = HeadingLevel.H3
    }

    @Serializable
    @SerialName("heading-4")
    data class H4(
        override val content: List<RichNode>,
    ): RichNode(), HeadingBlock {
        override val level: HeadingLevel
            get() = HeadingLevel.H4
    }

    @Serializable
    @SerialName("heading-5")
    data class H5(
        override val content: List<RichNode>,
    ): RichNode(), HeadingBlock {
        override val level: HeadingLevel
            get() = HeadingLevel.H5
    }

    @Serializable
    @SerialName("heading-6")
    data class H6(
        override val content: List<RichNode>,
    ): RichNode(), HeadingBlock {
        override val level: HeadingLevel
            get() = HeadingLevel.H6
    }
}

@Serializable
sealed class RichMark {
    @Serializable
    @SerialName("bold")
    data object Bold: RichMark()
    @Serializable
    @SerialName("italic")
    data object Italic: RichMark()
    @Serializable
    @SerialName("underline")
    data object Underline: RichMark()
    @Serializable
    @SerialName("superscript")
    data object Superscript: RichMark()
    @Serializable
    @SerialName("subscript")
    data object Subscript: RichMark()
    @Serializable
    @SerialName("strikethrough")
    data object strikethrough: RichMark()
    @Serializable
    @SerialName("code")
    data object Code: RichMark()
}

@Serializable
data class HyperlinkData(val uri: String)

private val json = Json {
    ignoreUnknownKeys = true
    explicitNulls = false
}
fun resolveRichNode(info: RichTextInfo): RichNode.Document {
    return try {
        json.decodeFromJsonElement(info.jsonObject)
    } catch (ex: Exception) {
        ex.printStackTrace()
        RichNode.Document(emptyList())
    }
}
