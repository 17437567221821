package com.appcreator.blueprint.conversions

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ConversionBuilder
import com.appcreator.blueprint.spec.ConversionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.IntInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class BasicMathsConversionBuilder(
  conversion: BasicMathsConversion?,
  newId: Boolean = false,
) : ConversionBuilder {
  override val spec: ConversionSpec = ConversionSpec(
          group = "",
          name = "BasicMathsConversion",
          title = "Basic Maths",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
          isExtraPlugin = false,
      )

  public val lhs: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Value",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(conversion?.lhs),
          
          
      )

  public val maths: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "",
          title = "By",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((conversion?.maths?: BasicMathsConversion.Maths.Add).name),
          
          options = BasicMathsConversion.Maths.entries.map { it.name },
      )

  public val rhs: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Other",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(conversion?.rhs),
          
          
      )

  public val precision: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "",
          title = "Precision",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(conversion?.precision?: 0),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(lhs, maths, rhs, precision)

  override fun build(): Conversion = BasicMathsConversion(lhs.value, maths.value?.let {
      BasicMathsConversion.Maths.valueOf(it) }?: BasicMathsConversion.Maths.Add, rhs.value,
      precision.value?: 0)
}
