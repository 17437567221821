package com.appcreator.blueprint.core.properties

import kotlinx.serialization.Serializable

@Serializable
data class TemplateInfo(
    val id: String?,
    val group: String,
    val name: String,
    val description: String,
    val enabled: Boolean
)