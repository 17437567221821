package com.appcreator.blueprint.spec

class ActionSpec(
    val name: String,
    val group: String,
    val title: String,
    val isExtraPlugin: Boolean,
    val description: String,
    val availableFrom: String,
    val accessible: Boolean,
    val hasDocs: Boolean
)