package com.appcreator.creatorapp.crm

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import coil3.compose.AsyncImage
import coil3.compose.LocalPlatformContext
import coil3.compose.SubcomposeAsyncImage
import coil3.request.ImageRequest
import coil3.request.crossfade
import com.appcreator.app.file.FileInfo
import com.appcreator.app.file.openFilePicker
import com.appcreator.blueprint.contentful.ContentfulLoaderSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.compose.components.basic.PlatformImage
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.FileResponse
import com.appcreator.local.LocalSnackbar
import com.appcreator.local.SnackbarInfo
import com.appcreator.shared.api.FileUploadInfo
import com.appcreator.styles.ThemeMargins
import io.ktor.utils.io.ByteReadChannel
import kotlinx.coroutines.launch



@Composable
fun ImageUploaderModal(
    orgId: String,
    projectId: String,
    onAdd: (FileResponse) -> Unit
) {

    var file by remember { mutableStateOf<FileInfo?>(null) }
    var title by remember { mutableStateOf("") }

    Column {

        Text("Upload New Image")

        CreatorTextInput(
            label = "Title",
            value = title,
            onValueChange = {
                title = it
            }
        )

        Box(Modifier.height(ThemeMargins.MedMargins))


        TextButton(onClick = {
            openFilePicker(
                accept = ".png,.jpg,.jpeg,.svg,.webp,.gif"
            ) {
                file = it
                if(title.isBlank()) {
                    title = it.name
                }
            }
        }) {
            Text(file?.let { "Change File" } ?: "Select File")
        }

        file?.let {
            Box(Modifier.height(ThemeMargins.MedMargins))
            Text("Selected: ${it.name}")
            Box(Modifier.height(ThemeMargins.MedMargins))
        }

        val apiClient = LocalApiClient.current
        val scope = rememberCoroutineScope()
        var loading by remember { mutableStateOf(false) }
        file?.let { file ->

            SubcomposeAsyncImage(
                modifier = Modifier.sizeIn(maxWidth = 400.dp, maxHeight = 400.dp),
                model = file.data,
                success = { success ->
                    PlatformImage(Modifier, contentScale, success) {
                        Image(
                            modifier = it,
                            painter = success.painter,
                            contentDescription = null,
                            contentScale = contentScale
                        )
                    }
                },
                contentDescription = null,
            )

            Spacer(Modifier.height(ThemeMargins.MedMargins))

            val snackbarChannel = LocalSnackbar.current
            Button(
                enabled = title.isNotEmpty() && !loading,
                onClick = {
                    loading = true
                    scope.launch {
                        try {
                            val response = apiClient.uploadFile(
                                orgId = orgId,
                                projectId = projectId,
                                name = title,
                                fileInfo = FileUploadInfo(
                                    contentType = file.mimeType,
                                    size = file.size,
                                    file = ByteReadChannel(file.data),
                                    filename = file.name
                                )
                            )
                            onAdd(response)
                        } catch (ex: Exception) {
                            ex.printStackTrace()
                            snackbarChannel.send(SnackbarInfo.Builder.fromNetworkException(ex))
                        }
                        loading = false
                    }

                }) {
                Text("Upload and Save")
            }
        }
    }
}
