package com.appcreator.blueprint.components.data

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.spec.inputs.DataSpecType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("DataRepeater")
@ComponentClass(
    group = "Data",
    title = "Repeating Item",
    description = "Repeats child component base on data",
    availableFrom = "0.0.1",
    producesDataFields = "\"index\""
)
data class DataRepeaterComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Path",
        availableFrom = "0.0.1",
        description = "List value to repeat over",
        dataOptions = DataSpecType.List
    )
    val path: DataPath?,

    @InputProperty(
        title = "Component",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val content: Component?,

    @InputProperty(
        title = "Filter",
        availableFrom = "0.0.1",
        includeSiblingData = true
    )
    val filter: Condition?,

    @InputProperty(
        section = "Preview",
        title = "Repeat preview data",
        availableFrom = "0.0.1",
        allowDataFields = false
    )
    @GenericDefault("1")
    val previewRepeatTimes: Int?

): Component
