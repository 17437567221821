package com.appcreator.local

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.remember
import androidx.compose.runtime.staticCompositionLocalOf
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.analytics.analytics


class CreatorAnalytics {

    fun log(event: String, params: Map<String, String> = emptyMap()) {
        Firebase.analytics.logEvent(event, params)
    }
    fun log(event: String, vararg params: Pair<String, String>) {
        log(event, params.toMap())
    }

}

val LocalAnalytics = staticCompositionLocalOf<CreatorAnalytics> {
    error("No instance of Analytics provided")
}

@Composable
fun ProvideAnalytics(content: @Composable () -> Unit) {
    val analytics = remember { CreatorAnalytics() }
    CompositionLocalProvider(LocalAnalytics provides analytics, content = content)
}