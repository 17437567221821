package com.appcreator.blueprint.conditions.data

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.StringDefault
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("NumberCompare")
@ConditionClass(
    group = "Data",
    title = "Compare Numbers",
    availableFrom = "0.0.1"
)
data class NumberCompareCondition(
    @InputProperty(
        title = "Value 1",
        availableFrom = "0.0.1"
    )
    @StringDefault("")
    val lhs: String,

    @InputProperty(
        title = "Comparison",
        availableFrom = "0.0.1"
    )
    @GenericDefault("NumberCompareCondition.Comparison.Equals")
    val comparison: Comparison,

    @InputProperty(
        title = "Value 2",
        availableFrom = "0.0.1"
    )
    @StringDefault("")
    val rhs: String,
): Condition {

    enum class Comparison {
        Equals,
        GreaterThan,
        LessThan
    }

}
