package com.appcreator.dto

import kotlinx.serialization.Serializable

@Serializable
data class FilesRequest(
    val extensions: String
)

@Serializable
data class FilesResponse(
    val files: List<FileResponse>
)
