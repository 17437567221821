package com.appcreator.dto

import com.appcreator.blueprint.components.basic.ContainerComponent
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.TemplateInfo
import kotlinx.serialization.Serializable

@Serializable
data class ComponentTemplate(
    val info: TemplateInfo,
    val component: ContainerComponent
)

@Serializable
data class ComponentTemplatesResponse(
    val templates: List<ComponentTemplate>,
)