package com.appcreator.creatorapp.editor.inputs

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem

@Composable
fun EnumInput(spec: EnumInputSpec, save: () -> Unit) {
    DropDownInputGroup(
        title = spec.properties.title,
        supportingText = spec.properties.description,
        dropDownTitle = spec.value ?: "",
        dropDownContent = { close ->
            spec.options.forEach {
                DropdownItem(it) {
                    spec.value = it
                    save()
                    close()
                }
            }
        }
    )
}