package com.appcreator.creatorapp.editor.inputs.admin

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.basic.ContainerComponent
import com.appcreator.blueprint.core.properties.TemplateInfo
import com.appcreator.blueprint.spec.inputs.TemplateInfoInputSpec
import com.appcreator.components.layouts.CheckboxGroup
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.ModalInputGroup
import com.appcreator.components.layouts.ModalSize
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.creatorapp.editor.local.LocalSelectedComponent
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.creatorapp.project.configurations.LoadingButton
import com.appcreator.styles.ThemeMargins

@Composable
fun TemplateInfoInput(spec: TemplateInfoInputSpec, save: () -> Unit) {
    ModalInputGroup(
        size = ModalSize.Medium,
        title = spec.properties.title,
        modalTitle = spec.value?.name ?: "None",
        supportingText = spec.properties.description,
        header = {
            Text("Template")
        },
        body = { close ->

            var display by remember { mutableStateOf(spec.value?: default()) }

            Column {
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Group",
                    value = display.group,
                    onValueChange = {
                        display = display.copy(group = it)
                    }
                )
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Name",
                    value = display.name,
                    onValueChange = {
                        display = display.copy(name = it)
                    }
                )
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Description",
                    value = display.description,
                    onValueChange = {
                        display = display.copy(description = it)
                    }
                )
                CheckboxGroup(
                    label = "Enable",
                    checked = display.enabled,
                    onCheckedChange = {
                        display = display.copy(enabled = it)
                    }
                )

                Spacer(Modifier.weight(1f))

                Row(horizontalArrangement = Arrangement.spacedBy(ThemeMargins.MedMargins)) {
                    val apiClient = LocalApiClient.current
                    val blueprint = LocalBlueprintManager.current
                    val selected by LocalSelectedComponent.current
                    LoadingButton(
                        action = {
                            (selected?.build() as? ContainerComponent)?.let {
                                val update = apiClient.createComponentTemplate(blueprint.orgId, blueprint.projectId, it, display)
                                spec.value = update
                                save()
                                close()
                            }

                        },
                        label = "Save New"
                    )
                    if(display.id != null) {
                        LoadingButton(
                            enabled = display.id != null,
                            action = {
                                (selected?.build() as? ContainerComponent)?.let {
                                    val update = apiClient.updateComponentTemplate(blueprint.orgId, blueprint.projectId, it, display)
                                    spec.value = update
                                    save()
                                    close()
                                }
                            },
                            label = "Update"
                        )
                        LoadingButton(
                            enabled = display.id != null,
                            action = {
                                display.id?.let {
                                    apiClient.deleteComponentTemplate(blueprint.orgId, blueprint.projectId, it)
                                    spec.value = null
                                    save()
                                    close()
                                }

                            },
                            label = "Delete"
                        )
                    }
                }
            }
        }
    )
}

private fun default(): TemplateInfo = TemplateInfo(
    id = null,
    group = "",
    name = "",
    description = "",
    enabled = true
)