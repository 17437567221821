package com.appcreator.blueprint.components.`data`

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.blueprint.spec.inputs.BooleanInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.LoaderConfigInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class LoadingComponentBuilder(
  component: LoadingComponent?,
  newId: Boolean = false,
) : ComponentBuilder {
  override val spec: ComponentSpec = ComponentSpec(
          group = "Data",
          name = "LoadingComponent",
          title = "Data Loader",
          isExtraPlugin = false,
          description = "Loads data",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
          producesDataFields = listOf(),
          producedFieldsEditable = false
      )

  override val _nodeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(if(newId || component?._nodeId == null) Uuid.random().toString()
          else component._nodeId),
          
          
      )

  override val _nodeLabel: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeLabel",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?._nodeLabel),
          
          
      )

  override val _nodeRelativeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeRelativeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(if(component?._nodeRelativeId == null) Uuid.random().toString()
          else component._nodeRelativeId),
          
          
      )

  public val config: LoaderConfigInputSpec = LoaderConfigInputSpec(
          properties = InputProperties(
          section = "",
          title = "Loader",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.config),
          
          
      )

  public val content: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "",
          title = "Component",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true,
          extraDataAvailable = "".split(",")
      ) ,
          value =  mutableStateOf(component?.content?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val action: ActionInputSpec = ActionInputSpec(
          properties = InputProperties(
          section = "",
          title = "On Load Action",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.action?.toBuilder(newId)),
          
          
      )

  public val pullToRefresh: BooleanInputSpec = BooleanInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Pull to refresh",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.pullToRefresh),
          
          
      )

  public val errorView: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Error type",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.errorView?: LoadingComponent.ViewType.Default).name),
          
          options = LoadingComponent.ViewType.entries.map { it.name },
      )

  public val errorContent: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Error content",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && errorView.value == LoadingComponent.ViewType.Custom.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true,
          extraDataAvailable = "".split(",")
      ) ,
          value =  mutableStateOf(component?.errorContent?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val loadingView: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Loader type",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.loadingView?: LoadingComponent.ViewType.Default).name),
          
          options = LoadingComponent.ViewType.entries.map { it.name },
      )

  public val loadingContent: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Loader content",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && loadingView.value == LoadingComponent.ViewType.Custom.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true,
          extraDataAvailable = "".split(",")
      ) ,
          value =  mutableStateOf(component?.loadingContent?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val reloadTrigger: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "Advanced",
          title = "Reload From Trigger",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.reloadTrigger),
          
          
      )

  public val displayInPreview: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Preview",
          title = "Display content",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.displayInPreview?:
          LoadingComponent.DisplayInPreview.Loaded).name),
          
          options = LoadingComponent.DisplayInPreview.entries.map { it.name },
      )

  override val inputSpecs: List<InputSpec> = listOf(_nodeId, _nodeLabel, _nodeRelativeId, config,
      content, action, pullToRefresh, errorView, errorContent, loadingView, loadingContent,
      reloadTrigger, displayInPreview)

  override fun build(): Component = LoadingComponent(_nodeId.value?: Uuid.random().toString(),
      _nodeLabel.value, _nodeRelativeId.value, config.value, content.value?.build(),
      action.value?.build(), pullToRefresh.value, errorView.value?.let {
      LoadingComponent.ViewType.valueOf(it) }?: LoadingComponent.ViewType.Default,
      errorContent.value?.build(), loadingView.value?.let { LoadingComponent.ViewType.valueOf(it)
      }?: LoadingComponent.ViewType.Default, loadingContent.value?.build(), reloadTrigger.value,
      displayInPreview.value?.let { LoadingComponent.DisplayInPreview.valueOf(it) }?:
      LoadingComponent.DisplayInPreview.Loaded)
}
