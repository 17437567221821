package com.appcreator.dto

import kotlinx.serialization.Serializable

@Serializable
data class Project(
    val id: String,
    val blueprintId: String,
//    val token: String,
    val appIcon: IconResponse?,
    val projectConfig: ProjectConfig,
    val blockingChecklist: Boolean,
    val showChecklist: Boolean,
    val checklist: List<ChecklistItem>?,
    val fromTemplate: String?,
)

@Serializable
data class ProjectConfig(
    val name: String,
    val appName: String
)

@Serializable
data class ChecklistItem(
    val tag: String,
    val title: String,
    val description: String,
    val completed: Boolean,
    val settings: String?,
    val links: List<ChecklistLink>?,

    val blocking: Boolean,
    val extraConfig: Map<String, String>
)

@Serializable
data class ChecklistLink(
    val title: String,
    val link: String
)

