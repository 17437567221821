package com.appcreator.compose.conversions

import com.appcreator.blueprint.conversions.ListCountConversion
import com.appcreator.blueprint.core.EnvStore
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonArray

class ListCountConvertor(
    val conversion: ListCountConversion
): Convertor {

    override fun convert(envStore: EnvStore, jsonElement: JsonElement?): String? {
        with(conversion) {
            jsonValue(jsonElement, listPath?.value)?.jsonArray?.let { jsonList ->
                return jsonList.size.toString()
            }
            (envStore.env[listPath?.value] as? List<*>)?.let {
                return it.size.toString()
            }
            return "0"
        }
    }

}