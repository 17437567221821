package com.appcreator.blueprint.spec

class ConversionSpec(
    val name: String,
    val title: String,
    val description: String,
    val availableFrom: String,
    val accessible: Boolean,
    val hasDocs: Boolean,
    val isExtraPlugin: Boolean,
    val group: String
)