package com.appcreator.creatorapp.project.configurations

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Button
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.Modal
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.components.layouts.ModalSize
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.styles.ThemeMargins

@Composable
fun ProjectGeneralConfiguration(
    organisationResponse: OrganisationResponse,
    project: Project,
    navigateBack: () -> Unit,
) {
    var savedProject by remember { mutableStateOf(project.projectConfig) }
    var display by remember { mutableStateOf(project.projectConfig) }
    Column(Modifier.padding(ThemeMargins.LargeMargins)) {
        CreatorTextInput(
            label = "Project Name",
            value = display.name,
            onValueChange = {
                display = display.copy(name = it)
            }
        )
        Spacer(Modifier.height(ThemeMargins.SmallMargin))
        CreatorTextInput(
            label = "App Name",
            value = display.appName,
            onValueChange = {
                display = display.copy(appName = it)
            }
        )

        Spacer(Modifier.height(ThemeMargins.LargeMargins))

        val client = LocalApiClient.current
        LoadingButton(
            label = "Save Changes",
            enabled = savedProject != display,
            action = {
                client.setProjectConfiguration(organisationResponse.organisation.id, project.id, display)
                savedProject = display
            }
        )

        Spacer(Modifier.height(ThemeMargins.LargeMargins))

        HorizontalDivider()

        Spacer(Modifier.height(ThemeMargins.LargeMargins))

        Text(
            "Danger Zone",
            style = MaterialTheme.typography.headlineMedium,
            color = MaterialTheme.colorScheme.error
        )

        Spacer(Modifier.height(ThemeMargins.LargeMargins))

        ModalScaffold(
            size = ModalSize.Small,
            anchor = {
                Button(onClick = it) {
                    Text("Delete Project")
                }
            },
            header = {
                Text("Permanently Delete Project")
            },
            body = {
                Column(Modifier.fillMaxSize()) {

                    var projectName by remember { mutableStateOf("") }

                    Text("This can not be undone!! Are you Sure?")
                    Spacer(Modifier.height(ThemeMargins.SmallMargin))
                    Text("Please enter the app name \"${project.projectConfig.name}\" below to confirm you want to delete this project")
                    CreatorTextInput(
                        modifier = Modifier.fillMaxWidth(),
                        label = "Project Name",
                        value = projectName,
                        onValueChange = {
                            projectName = it
                        }
                    )


                    Spacer(Modifier.weight(1f))

                    val apiClient = LocalApiClient.current
                    LoadingButton(
                        enabled = projectName == project.projectConfig.name,
                        label = "Delete",
                        action = {
                            apiClient.deleteProject(organisationResponse.organisation.id, project.id)
                            navigateBack()
                    }
                    )
                }
            },
        )


    }
}
