package com.appcreator.blueprint.components.data

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.StringDefault
import com.appcreator.blueprint.spec.inputs.DataSpecType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("StringSplitter")
@ComponentClass(
    group = "Data",
    title = "String Split",
    description = "Splits string the repeats over items",
    availableFrom = "0.0.1",
    producesDataFields = "\"index\",\"split-value\""
)
data class StringSplitterComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Value",
        availableFrom = "0.0.1",
        description = "Value to be split and repeated over",
        dataOptions = DataSpecType.Value
    )
    val valueToSplit: String?,

    @InputProperty(
        title = "Split Character",
        availableFrom = "0.0.1",
        description = "Character to split value with",
        allowDataFields = false
    )
    @StringDefault(",")
    val splitCharacter: String?,

    @InputProperty(
        title = "Component",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val content: Component?,

    @InputProperty(
        title = "Filter",
        availableFrom = "0.0.1",
        includeSiblingData = true
    )
    val filter: Condition?,

): Component
