package com.appcreator.blueprint.core

import androidx.compose.runtime.State
import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.blueprint.core.properties.LocalizableString
import io.ktor.http.encodeURLParameter

class EnvStore private constructor(
    private val defaultLocale: String,
    val parent: EnvStore?,
    val env: Map<String, Any> = emptyMap()
) {
    companion object {
        fun create(
            parent: EnvStore,
            env: Map<String, Any> = emptyMap()
        ) = EnvStore(
            defaultLocale = parent.defaultLocale,
            parent = parent,
            env = parent.env + env
        )

        fun create(
            defaultLocale: String,
            env: Map<String, Any> = emptyMap(),
        ) = EnvStore(
            defaultLocale = defaultLocale,
            parent = null,
            env = env
        )
    }

    fun injectLink(link: BlueprintLink): String {
        return injectValueWithMapping(link.path, link.params, emptyMap()) {
            it.encodeURLParameter()
        }
    }

    fun get(key: String): Any? =
        if (key.startsWith("~")) {
            try {
                val parts = key.split(".")
                val first = parts.first()
                val end = parts.drop(1).joinToString(separator = ".")
                val parentCount = first.removePrefix("~").toInt()
                val parentKey = if (parentCount == 1) {
                    end
                } else {
                    "~${parentCount - 1}.$end"
                }
                parent?.get(parentKey)
            } catch (ex: Exception) {
                null
            }
        } else {
            when (val value = env[key]) {
                is State<*> -> value.value
                else -> value
            }
        }

    fun injectValueWithMapping(
        value: String,
        mapping: Map<String, String>,
        defaults: Map<String, String>,
        encoded: (String) -> String = { it }
    ): String {
        return injectVariables(value, encoded) {
            val mappedValue = mapping[it]?.let { mapped -> injectVariables(mapped) }
            val defaultValue = defaults[it]?.let { default -> injectVariables(default) }
            when {
                mappedValue?.isNotEmpty() == true -> mappedValue
                defaultValue?.isNotEmpty() == true -> defaultValue
                else -> get(it)
            }
        }
    }

    fun injectVariables(value: String, encoded: (String) -> String = { it }, valueForKey: (String) -> Any? = { get(it) }): String {
        var updated = value

        envRegex.findAll(value).toList().reversed().forEach { match ->
            val key = match.value.trimStart('{').trimEnd('}')
            val replaceWith = valueForKey(key)
            replaceWith?.let {
                if (it is String) {
                    updated = updated.replaceRange(match.range, encoded(it))
                }
            }?: run {
                // Remove property and replace with blank
                updated = updated.replaceRange(match.range, "")
            }
        }
        return updated
    }

    fun injectUrlSpec(urlSpec: UrlSpec, mapping: Map<String, String>, defaults: Map<String, String>): UrlSpec {
        val url = UrlSpec(
            url = injectValueWithMapping(urlSpec.url, mapping, defaults),
            verb = urlSpec.verb,
            queries = urlSpec.queries.map { (key, value) ->
                injectValueWithMapping(key, mapping, defaults) to injectValueWithMapping(value, mapping, defaults)
            }.toMap(),
            headers = urlSpec.headers.map { (key, value) ->
                injectValueWithMapping(key, mapping, defaults) to injectValueWithMapping(value, mapping, defaults)
            }.toMap(),
            bodyType = urlSpec.bodyType,
            body = injectValueWithMapping(urlSpec.body, mapping, defaults)
        )
        return url
    }

    fun envFromList(key: String): List<EnvStore> {
        (get(key) as? List<Map<String, Any>>)?.let {
            return it.mapIndexed { index, item ->
                create(this, env = item + mapOf("index" to index.toString()))
            }
        }
        return emptyList()
    }

    fun injectLocalizableString(
        value: LocalizableString,
        locale: String?
    ): String {
        val localized = if (value.localizationEnabled) {
            value.values[locale ?: defaultLocale] ?: value.values[defaultLocale] ?: ""
        } else {
            value.values.values.firstOrNull() ?: ""
        }
        return injectVariables(localized)
    }

    override fun equals(other: Any?): Boolean {
        return env == (other as? EnvStore)?.env
    }

    fun listContains(listPath: String, item: Map<String, String>): Boolean {
        (get(listPath) as? List<Map<String, Any>>)?.let {
            return it.any { other -> item matches other }
        }
        return false
    }
}

infix fun Map<String, String>.matches(other: Map<String, Any>): Boolean {
    return all { (key, value) ->
        value.isBlank() || other[key] == value
    }
}
