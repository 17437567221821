package com.appcreator.blueprint.conversions

import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.ConversionClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("BasicMathsConversion")
@ConversionClass(
    title = "Basic Maths",
    availableFrom = "0.0.1"
)
data class BasicMathsConversion(
    @InputProperty(
        title = "Value",
        availableFrom = "0.0.1"
    )
    val lhs: String?,

    @InputProperty(
        title = "By",
        availableFrom = "0.0.1"
    )
    @GenericDefault("BasicMathsConversion.Maths.Add")
    val maths: Maths?,

    @InputProperty(
        title = "Other",
        availableFrom = "0.0.1"
    )
    val rhs: String?,

    @InputProperty(
        title = "Precision",
        availableFrom = "0.0.1"
    )
    @GenericDefault("0")
    val precision: Int?,
): Conversion {

    enum class Maths {
        Add,
        Subtract,
        Divide,
        Multiply,
        Remainder
    }

}
