package com.appcreator.blueprint.components.data

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("DataConversion")
@ComponentClass(
    group = "Data",
    title = "Data Transformer",
    availableFrom = "0.0.1"
)
data class DataConversionComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Output Path",
        availableFrom = "0.0.1",
        allowDataFields = false
    )
    val outputPath: String?,

    @InputProperty(
        title = "Conversion",
        availableFrom = "0.0.1",
    )
    val conversion: Conversion?,

    @InputProperty(
        title = "Component",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val content: Component?,

    ): Component
