package com.appcreator.creatorapp.editor.inputs

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.DataObject
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.spec.inputs.DataPathInputSpec
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.creatorapp.editor.local.LocalDataOptions
import com.appcreator.creatorapp.editor.panelcomponent.views.DataOptionsList

@Composable
fun DataPathInput(spec: DataPathInputSpec, save: () -> Unit) {

    val dataItems = LocalDataOptions.current
    val options = dataItems
        .filter {
            !spec.properties.settableDataOnly || it.settable
        }

    DropDownInputGroup(
        title = spec.properties.title,
        supportingText = spec.properties.description,
        dropDownTitle = spec.value?.value ?: " ",
        leadingIcon = {
            Icon(Icons.Default.DataObject, contentDescription = null)
        },
        dropDownContent = { close ->
            DataOptionsList(
                options = options,
                filter = {
                    spec.properties.dataOptions.split(",").contains(it.specType)
                },
                onSelect = {
                    spec.value = it
                    save()
                    close()
                },
                close = {
                    close()
                }
            )
        }
    )
}
