package com.appcreator.dto

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class Invitation(
    val id: String,
    val key: String,
    val email: String,
    val organisationName: String,
    val organisationId: String,
    val accessLevel: AccessLevel,
    val created: Instant
)