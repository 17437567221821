package com.appcreator.blueprint.spec.inputs

import androidx.compose.runtime.snapshots.SnapshotStateList
import com.appcreator.blueprint.core.properties.JsCallback
import com.appcreator.blueprint.spec.ListInputSpec

class JsCallbackListInputSpec(
    properties: InputProperties,
    value: SnapshotStateList<JsCallback>
): ListInputSpec<JsCallback>(properties, value)
