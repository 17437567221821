package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ContentCopy
import androidx.compose.material.icons.filled.CopyAll
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Snackbar
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.properties.JsCallback
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpec.Type
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.JsCallbackListInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.Modal
import com.appcreator.compose.LocalSnackbarAlert
import com.appcreator.compose.SnackbarData
import com.appcreator.creatorapp.editor.local.DataOption
import com.appcreator.creatorapp.editor.local.LocalDataOptions
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput
import com.appcreator.local.LocalSnackbar
import com.appcreator.local.SnackbarInfo
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.launch

@Composable
fun JsCallbackInput(spec: JsCallbackListInputSpec, save: () -> Unit) {

    Text(spec.properties.title)
    Text(
        spec.properties.description,
        style = MaterialTheme.typography.labelMedium
    )

    val childProperties = spec.properties.copy(title = "Callback Action", description = "")
    spec.value.forEachIndexed { index, jsCallback ->
        Spacer(Modifier.height(ThemeMargins.MedMargins))
        JsCallbackInputItem(
            properties = childProperties,
            jsCallback = jsCallback,
            update = {
                spec.value[index] = it
                save()
            },
            delete = {
                spec.value.remove(jsCallback)
                save()
            },
            save = save
        )
    }

    TextButton(onClick = {
        spec.value.add(JsCallback("", emptyList(), null))
    }) {
        Text("Add Callback")
    }

}

@Composable
private fun JsCallbackInputItem(
    properties: InputProperties,
    jsCallback: JsCallback,
    update: (JsCallback) -> Unit,
    delete: () -> Unit,
    save: () -> Unit
) {
    val snackbarAlert = LocalSnackbar.current
    val scope = rememberCoroutineScope()
    val jsonFields = remember { mutableStateListOf(*jsCallback.properties.toTypedArray()) }

    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "Name",
        value = jsCallback.name,
        leadingIcon = {
            IconButton(onClick = {
                    val parts = jsonFields.joinToString { """$it: "example value"""" }
val code = """
const properties = { $parts }
const message = { name: "updateTitle", properties: properties };
window.parent.postMessage(JSON.stringify(message), '*');
""".trimIndent()

                copyToClipboard(code)
                scope.launch {
                    snackbarAlert.send(SnackbarInfo("Javascript code to call action copied to clipboard"),)
                }
            }) {
                Icon(Icons.Default.ContentCopy, contentDescription = null)
            }
        },
        onValueChange = {
            update(jsCallback.copy(name = it))
        },
        trailingIcon = {
            IconButton(onClick = delete) {
                Icon(Icons.Default.Delete, contentDescription = null)
            }
        }
    )

    Column(Modifier.padding(start = ThemeMargins.LargeMargins)) {
        jsonFields.forEachIndexed { index, key ->
            CreatorTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Property Key",
                value = key,
                onValueChange = {
                    jsonFields[index] = it
                    update(jsCallback.copy(properties = jsonFields))
                },
                trailingIcon = {
                    IconButton(onClick = {
                        jsonFields.removeAt(index)
                        update(jsCallback.copy(properties = jsonFields))
                    }) {
                        Icon(Icons.Default.Delete, contentDescription = null)
                    }
                }
            )
        }
        TextButton(onClick = {
            jsonFields.add("")
        }) {
            Text("Add Property")
        }
    }

    val actionSpec = remember { ActionInputSpec(properties, mutableStateOf(jsCallback.action?.toBuilder())) }
    ActionInput(
        spec = actionSpec,
        extraDataTitle = "Callback - ${jsCallback.name}",
        extraData = jsonFields,
        save = {
            update(jsCallback.copy(action = actionSpec.value?.build()))
        }
    )
}

expect fun copyToClipboard(message: String)