package com.appcreator.blueprint.components.basic

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.AllowedComponent
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.DisplayIf
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.properties.CornerValues
import com.appcreator.blueprint.core.properties.SizeValue
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Button")
@ComponentClass(
    group = "Basic",
    title = "Button",
    availableFrom = "0.0.1"
)
data class ButtonComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Content",
        availableFrom = "0.0.1"
    )
    @AllowedComponent(TextComponent::class)
    @AllowedComponent(MaterialIconComponent::class)
    @AllowedComponent(ImageComponent::class)
    val content: Component?,

    @InputProperty(
        title = "Type",
        availableFrom = "0.0.1"
    )
    @GenericDefault("ButtonComponent.ButtonType.Default")
    val buttonType: ButtonType?,

    @InputProperty(
        section = "Layout",
        title = "Width",
        availableFrom = "0.0.1"
    )
    val width: SizeValue?,
    @InputProperty(
        section = "Layout",
        title = "Height",
        availableFrom = "0.0.1"
    )
    val height: SizeValue?,

    @InputProperty(
        section = "Layout",
        title = "Color",
        availableFrom = "0.0.1"
    )
    @DisplayIf("buttonType.value != ButtonComponent.ButtonType.Icon.name")
    val color: ColorReference?,

    @InputProperty(
        section = "Border",
        title = "Color",
        availableFrom = "0.0.1"
    )
    @DisplayIf("buttonType.value != ButtonComponent.ButtonType.Icon.name")
    val borderColor: ColorReference?,
    @InputProperty(
        section = "Border",
        title = "Radius",
        availableFrom = "0.0.1"
    )
    @DisplayIf("buttonType.value != ButtonComponent.ButtonType.Icon.name")
    val borderRadius: CornerValues?,
    @InputProperty(
        section = "Border",
        title = "Thickness",
        availableFrom = "0.0.1"
    )
    @DisplayIf("buttonType.value != ButtonComponent.ButtonType.Icon.name")
    val borderThickness: Int?,

    @InputProperty(
        section = "On Click",
        title = "Enabled",
        availableFrom = "0.0.1"
    )
    val buttonEnabled: Condition?,

    @InputProperty(
        section = "On Click",
        title = "Action",
        availableFrom = "0.0.1"
    )
    val action: Action?,

    @InputProperty(
        section = "On Click",
        title = "Analytics Name",
        description = "Value to be sent as button-name when clicked",
        availableFrom = "0.0.1"
    )
    @DisplayIf("content.value !is TextComponentBuilder")
    val analyticsButtonName: String?,

): Component {

    enum class ButtonType {
        Default,
        Icon,
        Text
    }

}

