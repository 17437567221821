package com.appcreator.creatorapp.editor.inputs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.appcreator.blueprint.spec.inputs.FloatInputSpec
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput

@Composable
fun FloatInput(spec: FloatInputSpec, save: () -> Unit) {
    var display by remember { mutableStateOf(spec.value?.toString() ?: "") }
    DataSourceTextInput(
        label = spec.properties.title,
        supportingText = spec.properties.description,
        value = display,
        allowDataItems = spec.properties.allowDataFields,
        update = {
            display = it
        }
    )
    LaunchedEffect(display) {
        spec.value = display.toFloatOrNull()
        save()
    }
}