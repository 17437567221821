package com.appcreator.creatorapp.editor.centerdata.rest

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.ArrowDropUp
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Switch
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.dataspecs.ConversionDataSpecBuilder
import com.appcreator.blueprint.dataspecs.ListDataSpecBuilder
import com.appcreator.blueprint.dataspecs.ValueDataSpecBuilder
import com.appcreator.creatorapp.editor.local.DataOption
import com.appcreator.styles.ThemeMargins


@Composable
fun DataShapeList(
    modifier: Modifier = Modifier,
    dataSpec: ListDataSpecBuilder,
    update: (DataSpec) -> Unit,
    listOption: MutableList<DataOption>,
) {

    val optionsList = mutableListOf<DataSpec>()
    val options = DataOption(if(dataSpec.key.value.isNullOrBlank()) "Root" else dataSpec.key.value!!, false, optionsList)

    Column(modifier = modifier
        .fillMaxWidth()
    ) {
        dataSpec.items.value.forEach { item ->

            val built = item.build()
            optionsList.find { it.key == item.build().key }?: run {
                optionsList.add(built)
            }

            when (item) {
                is ListDataSpecBuilder -> {
                    var expanded by remember(item.key.value) { mutableStateOf(false) }
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .clickable {
                                expanded = !expanded
                            },
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        if (expanded) {
                            Icon(Icons.Default.ArrowDropUp, contentDescription = null)
                        } else {
                            Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                        }
                        Text(item.key.value?: "")

                        Spacer(Modifier.weight(1f))

                        IconButton(
                            onClick = {
                                dataSpec.items.value.remove(item)
                                update(dataSpec.build())
                            }) {
                            Icon(Icons.Default.Delete, contentDescription = null)
                        }
                    }
                    if (expanded) {
                        Box(modifier = Modifier.padding(start = ThemeMargins.LargeMargins)) {
                            DataShapeList(
                                dataSpec = item,
                                update = {
                                    update(dataSpec.build())
                                },
                                listOption = listOption.toList().toMutableList()
                            )
                        }
                    }
                }

                is ValueDataSpecBuilder -> {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically
                    ) {

                        Text(item.key.value ?: "")

                        Spacer(Modifier.weight(1f))

                        Switch(item.visible.value == true, onCheckedChange = {
                            item.visible.value = it
                            update(dataSpec.build())
                        })
                        IconButton(
                            onClick = {
                                dataSpec.items.value.remove(item)
                                update(dataSpec.build())
                            }) {
                            Icon(Icons.Default.Delete, contentDescription = null)
                        }
                    }

                    // TODO show example
//                    Tooltip(
//                        target = ElementTarget.PreviousSibling,
//                        modifier = Modifier.zIndex(100),
//                        showDelayMs = 600,
//                        text = "example: ${item.value.value}"
//                    )
                }

                is ConversionDataSpecBuilder -> {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically
                    ) {

                        Text(item.key.value ?: "")

                        Spacer(Modifier.weight(1f))

                        val pausedList = listOption.toList() + DataOption(dataSpec.key.value?: "Root", false, optionsList.toList())
                        EditDataItemModal(
                            options = pausedList,
                            anchor = { show ->
                                IconButton(
                                    onClick = {
                                        show()
                                    }) {
                                    Icon(Icons.Default.Edit, contentDescription = null)
                                }
                            },
                            dataItem = item,
                            save = {
                                update(dataSpec.build())
                            }
                        )

                        IconButton(
                            onClick = {
                                dataSpec.items.value.remove(item)
                                update(dataSpec.build())
                            }) {
                            Icon(Icons.Default.Delete, contentDescription = null)
                        }
                    }
                }
            }
            HorizontalDivider()
        }

        listOption.add(options)
        AddDataItemModal(
            options = listOption.toList(),
            anchor = { show ->
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .clickable {
                            show()
                        }
                        .padding(vertical = ThemeMargins.SmallMargin),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    Text("Add+")
                }
            },
            onAdd = {
                dataSpec.items.value.add(it)
                update(dataSpec.build())
            }
        )

//        HorizontalDivider()
    }
}