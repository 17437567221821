package com.appcreator.blueprint.firebase.firestore

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.ActionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import com.appcreator.blueprint.spec.inputs.TypedDataValueListInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class FirebaseFirestoreSetDataActionBuilder(
  action: FirebaseFirestoreSetDataAction?,
  newId: Boolean = false,
) : ActionBuilder {
  override val spec: ActionSpec = ActionSpec(
          name = "FirebaseFirestoreSetDataAction",
          title = "Set Data",
          group = "Firebase Firestore",
          isExtraPlugin = true,
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
      )

  public val path: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Collection Path",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(action?.path),
          
          
      )

  public val `data`: TypedDataValueListInputSpec = TypedDataValueListInputSpec(
          properties = InputProperties(
          section = "",
          title = "Data",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateListOf(*(action?.data?: emptyList()).toTypedArray()),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(path, data)

  override fun build(): Action = FirebaseFirestoreSetDataAction(path.value, data.value)
}
