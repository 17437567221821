package com.appcreator.creatorapp.editor.centerdata.contentful

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material3.ExtendedFloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.contentful.ContentfulLoaderSpec
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreLoaderSpec
import com.appcreator.creatorapp.api.MutableDataSource
import com.appcreator.creatorapp.editor.BlueprintManager
import com.appcreator.creatorapp.editor.centerdata.rest.randomStringByKotlinRandom
import com.appcreator.dto.DataSource
import com.appcreator.styles.ThemeMargins


@Composable
fun ContentfulSideBar(
    blueprintManager: BlueprintManager,
    selectedItem: MutableDataSource?,
    selectItem: (MutableDataSource) -> Unit

) {

    Box(modifier = Modifier
        .width(250.dp)
        .fillMaxHeight()
        .padding(horizontal = ThemeMargins.SmallMargin)
    ) {

        Column(
            Modifier
            .verticalScroll(rememberScrollState())
        ) {

            blueprintManager.dataSources
                .filter { it.loaderSpec is ContentfulLoaderSpec }
                .forEach { item ->

                    NavigationDrawerItem(
                        label = {
                            val title = item.title.ifEmpty { item.id }
                            Text(title, maxLines = 1, overflow = TextOverflow.Ellipsis)
                        },
                        selected = item == selectedItem,
                        onClick = {
                            selectItem(item)
                        },
                        colors = NavigationDrawerItemDefaults.colors(unselectedContainerColor = Color.Unspecified)
                    )
                }

            Spacer(Modifier.height(64.dp))
        }


        ExtendedFloatingActionButton(
            modifier = Modifier.align(Alignment.BottomCenter),
            text = {
                Text("Add")
            },
            icon = {
                Icon(Icons.Default.Add, contentDescription = null)
            },
            onClick = {
                val id = randomStringByKotlinRandom()
                val newItem = MutableDataSource(
                    DataSource(
                        id = id,
                        title = "",
                        loaderSpec = ContentfulLoaderSpec(
                            id = id,
                            modelName = "",
                            modelId = "",
                            dataValues = null,
                            parameterSettings = null,
                            orderBy = null,
                            limit = 100,
                            where = null
                        )
                    )
                )
                blueprintManager.dataSources.add(newItem)
                selectItem(newItem)
            }
        )
    }

}