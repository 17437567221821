package com.appcreator.creatorapp.organisation

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.RemoveCircle
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.components.layouts.ModalSize
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.creatorapp.project.configurations.LoadingButton
import com.appcreator.dto.AccessLevel
import com.appcreator.dto.Invitation
import com.appcreator.dto.Organisation
import com.appcreator.dto.OrganisationResponse
import com.appcreator.local.LocalSnackbar
import com.appcreator.local.LocalUserState
import com.appcreator.styles.ThemeMargins


@Composable
fun OrganisationMembers(
    organisation: OrganisationResponse,
) {
    val apiClient = LocalApiClient.current
    Column(
        modifier = Modifier.padding(horizontal = ThemeMargins.LargeMargins)
            .sizeIn(maxWidth = 800.dp)
    ) {
        if (organisation.organisation.members.size > 1) {

            Text(
                text = "Members",
                style = MaterialTheme.typography.headlineSmall
            )
            Spacer(Modifier.height(ThemeMargins.MedMargins))
            Column(
                Modifier.border(1.dp, color = Color.LightGray, shape = RoundedCornerShape(8.dp))
                    .clip(RoundedCornerShape(8.dp))
            ) {
                organisation.organisation.members.forEachIndexed { index, user ->
                    Row(
                        modifier = Modifier
                            .heightIn(min = 44.dp)
                            .background(if (index % 2 == 1) Color.LightGray else Color.Unspecified)
                            .padding(horizontal = ThemeMargins.SmallMargin),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Column {
                            user.name?.let {
                                Text(it)
                            }
                            Text(
                                text = user.email,
                                style = MaterialTheme.typography.bodySmall
                            )
                        }
                        Spacer(Modifier.weight(1f))
                        Text(user.accessLevel.name)
                        // TODO allow user management
                        if (LocalUserState.current.admin) {
                            if(organisation.accessLevel >= user.accessLevel) {
                                RemoveModal(email = user.email) {

                                }
                            }
                        }
                    }
                }
            }
        }

        // Invitations is an Admin feature for now, will be tied to the subscription type of the org
        if (LocalUserState.current.admin) {

            LoadingComposable(loader = {
                mutableStateListOf(*(it.getInvitations(organisation.organisation.id).invitations.toTypedArray()))
            }) {
                Spacer(Modifier.height(ThemeMargins.LargeMargins))
                Row(verticalAlignment = Alignment.CenterVertically) {
                    Text(
                        text = "Invitations",
                        style = MaterialTheme.typography.headlineSmall
                    )
                    Spacer(Modifier.height(ThemeMargins.LargeMargins))
                    InviteModal(LocalUserState.current.admin, organisation) { invitation ->
                        it.find { it.email == invitation.email }?.let { old ->
                            it.remove(old)
                        }
                        it.add(0, invitation)
                    }
                }
                Spacer(Modifier.height(ThemeMargins.MedMargins))
                Column(
                    Modifier.border(
                        1.dp,
                        color = Color.LightGray,
                        shape = RoundedCornerShape(8.dp)
                    ).clip(RoundedCornerShape(8.dp))
                ) {
                    it.forEachIndexed { index, invitation ->
                        Row(
                            modifier = Modifier
                                .heightIn(min = 44.dp)
                                .background(if (index % 2 == 1) Color.LightGray else Color.Unspecified)
                                .padding(horizontal = ThemeMargins.SmallMargin),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Column {
                                Text(
                                    text = invitation.email,
                                    style = MaterialTheme.typography.bodySmall
                                )
                            }
                            Spacer(Modifier.weight(1f))
                            Text(invitation.accessLevel.name)
                            if(organisation.accessLevel >= invitation.accessLevel) {
                                RemoveModal(email = invitation.email) {
                                    apiClient.deleteInvitation(organisation.organisation.id, invitation.id)
                                    it.remove(invitation)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
private fun InviteModal(
    isAdmin: Boolean,
    organisation: OrganisationResponse,
    onInvited: (Invitation) -> Unit
) {
    ModalScaffold(
        size = ModalSize.Small,
        header = {
            Text("Invite User")
        },
        body = { close ->
            var email by remember { mutableStateOf("") }
            var group by remember { mutableStateOf(AccessLevel.Editor) }

            Column {
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Email",
                    value = email,
                    onValueChange = {
                        email = it
                    }
                )

                DropDownInputGroup(
                    modifier = Modifier.fillMaxWidth(),
                    title = "Group",
                    dropDownTitle = group.name,
                    dropDownContent = { close ->
                        AccessLevel.entries
                            .filter { isAdmin || it <= organisation.accessLevel }
                            .forEach {
                            DropdownItem(title = it.name) {
                                group = it
                                close()
                            }
                        }
                    }
                )

                Spacer(Modifier.weight(1f))

                val apiClient = LocalApiClient.current
                LoadingButton(
                    enabled = email.isEmailValid(),
                    action = {
                        onInvited(apiClient.invite(organisation.organisation.id, email, group))
                        close()
                    },
                    label = "Invite"
                )

            }
        },
        anchor = {
            TextButton(onClick = it) {
                Icon(Icons.Default.Add, contentDescription = null)
                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                Text("Add")
            }
        }
    )
}

@Composable
private fun RemoveModal(
    email: String,
    action: suspend () -> Unit
) {
    ModalScaffold(
        size = ModalSize.Small,
        header = {
            Text("Remove")
        },
        body = { close ->

            Column {
                Text("Are you sure?")
                Text("Remove user $email")

                Spacer(Modifier.weight(1f))
                LoadingButton(
                    enabled = email.isEmailValid(),
                    action = {
                        action()
                        close()
                    },
                    label = "Confirm"
                )
            }
        },
        anchor = {
            IconButton(onClick = it) {
                Icon(Icons.Default.RemoveCircle, contentDescription = null, tint = Color.Red)
            }
        }
    )
}

private fun String.isEmailValid(): Boolean {
    val emailRegex = "^[A-Za-z](.*)([@]{1})(.{1,})(\\.)(.{1,})"
    return emailRegex.toRegex().matches(this)
}
