package com.appcreator.compose.conditions.device

import kotlinx.browser.window

actual fun isMobileDevice(): Boolean {
    val toMatch = listOf(
        "Android",
        "webOS",
        "iPhone",
        "iPad",
        "iPod"
    )
    return toMatch.any { window.navigator.userAgent.contains(it) }
}