package com.appcreator.compose.interfaces

interface Analytics {
    fun setUserId(userId: String?)
    fun screenView(screenPath: String, screenName: String?)
    fun event(name: String, params: Map<String, String>)
}

class GroupAnalytics: Analytics {
    var children = mutableListOf<Analytics>()
    override fun setUserId(userId: String?) {
        children.forEach { it.setUserId(userId) }
    }

    override fun screenView(screenPath: String, screenName: String?) {
        children.forEach { it.screenView(screenPath, screenName) }
    }

    override fun event(name: String, params: Map<String, String>) {
        children.forEach { it.event(name, params) }
    }
}