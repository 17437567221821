package com.appcreator.creatorapp.project.newproject

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.project.configurations.FirebaseConfiguration
import com.appcreator.local.LocalOrganisation
import com.appcreator.local.LocalProject

@Composable
fun SetupFirebaseSlide(
    enableAuth: Boolean,
    enabledFirestore: Boolean,
    completed: () -> Unit
) {

    var setupComplete by remember { mutableStateOf(false) }
    FirebaseConfiguration(
        LocalOrganisation.current,
        LocalProject.current,
        inset = 0.dp,
        showOptions = false,
        saveAtTop = false,
        webOnly = true,
        forceEnableAuth = enableAuth,
        forceEnableFirestore = enabledFirestore
    ) {
        setupComplete = true
        completed()
    }

    if(setupComplete) {
        Text("You can update these values later if required in project setting.")
    }

}

@Composable
fun SetupFirebaseFirestoreSecuritySlide(rules: String?) {
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        readOnly = false,
        label = "Rules",
        value = rules?: "",
        onValueChange = {}
    )
}

