package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Checkbox
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.spec.inputs.BooleanInputSpec
import com.appcreator.styles.ThemeMargins

@Composable
fun BooleanInput(spec: BooleanInputSpec, save: () -> Unit) {
    Column(modifier = Modifier.padding(vertical = ThemeMargins.SmallMargin)) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Checkbox(
                checked = spec.value ?: false,
                onCheckedChange = {
                    spec.value = it
                    save()
                }
            )
            Text(spec.properties.title)
        }
        if(spec.properties.description.isNotBlank()) {
            Text(
                modifier = Modifier.padding(horizontal = 12.dp),
                text = spec.properties.description,
                style = MaterialTheme.typography.bodySmall
            )
        }
    }
}
