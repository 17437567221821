package com.appcreator.compose

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxScope
import androidx.compose.material3.pulltorefresh.PullToRefreshState
import androidx.compose.runtime.Composable

@Composable
actual fun PlatformPullToRefreshBox(
    isRefreshing: Boolean,
    onRefresh: () -> Unit,
    state: PullToRefreshState,
    content: @Composable BoxScope.() -> Unit
) {
    Box {
        content()
    }
}