package com.appcreator.blueprint.components

import com.appcreator.blueprint.components.CustomClientComponent
import com.appcreator.blueprint.components.CustomClientComponentBuilder
import com.appcreator.blueprint.components.CustomComponent
import com.appcreator.blueprint.components.CustomComponentBuilder
import com.appcreator.blueprint.components.Screen
import com.appcreator.blueprint.components.ScreenBuilder
import com.appcreator.blueprint.components.`data`.CountdownComponent
import com.appcreator.blueprint.components.`data`.CountdownComponentBuilder
import com.appcreator.blueprint.components.`data`.DataConversionComponent
import com.appcreator.blueprint.components.`data`.DataConversionComponentBuilder
import com.appcreator.blueprint.components.`data`.DataRepeaterComponent
import com.appcreator.blueprint.components.`data`.DataRepeaterComponentBuilder
import com.appcreator.blueprint.components.`data`.DatePartsComponent
import com.appcreator.blueprint.components.`data`.DatePartsComponentBuilder
import com.appcreator.blueprint.components.`data`.LoadingComponent
import com.appcreator.blueprint.components.`data`.LoadingComponentBuilder
import com.appcreator.blueprint.components.`data`.PagedLoaderComponent
import com.appcreator.blueprint.components.`data`.PagedLoaderComponentBuilder
import com.appcreator.blueprint.components.basic.ButtonComponent
import com.appcreator.blueprint.components.basic.ButtonComponentBuilder
import com.appcreator.blueprint.components.basic.ContainerComponent
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.basic.ImageComponent
import com.appcreator.blueprint.components.basic.ImageComponentBuilder
import com.appcreator.blueprint.components.basic.MaterialIconComponent
import com.appcreator.blueprint.components.basic.MaterialIconComponentBuilder
import com.appcreator.blueprint.components.basic.TextComponent
import com.appcreator.blueprint.components.basic.TextComponentBuilder
import com.appcreator.blueprint.components.basic.VideoComponent
import com.appcreator.blueprint.components.basic.VideoComponentBuilder
import com.appcreator.blueprint.components.basic.WebViewComponent
import com.appcreator.blueprint.components.basic.WebViewComponentBuilder
import com.appcreator.blueprint.components.forms.DatePickerModalComponent
import com.appcreator.blueprint.components.forms.DatePickerModalComponentBuilder
import com.appcreator.blueprint.components.forms.InputComponent
import com.appcreator.blueprint.components.forms.InputComponentBuilder
import com.appcreator.blueprint.components.forms.LocalStateComponent
import com.appcreator.blueprint.components.forms.LocalStateComponentBuilder
import com.appcreator.blueprint.components.logic.ABComponent
import com.appcreator.blueprint.components.logic.ABComponentBuilder
import com.appcreator.blueprint.components.logic.ConditionalComponent
import com.appcreator.blueprint.components.logic.ConditionalComponentBuilder
import com.appcreator.blueprint.components.modal.DialogComponent
import com.appcreator.blueprint.components.modal.DialogComponentBuilder
import com.appcreator.blueprint.components.modal.SheetComponent
import com.appcreator.blueprint.components.modal.SheetComponentBuilder
import com.appcreator.blueprint.components.navigation.NavigationComponent
import com.appcreator.blueprint.components.navigation.NavigationComponentBuilder
import com.appcreator.blueprint.components.navigation.NavigationDrawerComponent
import com.appcreator.blueprint.components.navigation.NavigationDrawerComponentBuilder
import com.appcreator.blueprint.components.navigation.NavigationItemComponent
import com.appcreator.blueprint.components.navigation.NavigationItemComponentBuilder
import com.appcreator.blueprint.components.pager.TabbedPageComponent
import com.appcreator.blueprint.components.pager.TabbedPageComponentBuilder
import com.appcreator.blueprint.components.pager.TabbedPagerComponent
import com.appcreator.blueprint.components.pager.TabbedPagerComponentBuilder
import com.appcreator.blueprint.contentful.ContentfulRichTextComponent
import com.appcreator.blueprint.contentful.ContentfulRichTextComponentBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.spec.ComponentBuilder
import kotlin.Boolean

public fun Component.toBuilder(newId: Boolean = false): ComponentBuilder {
  val builder = when(this) {
      is CustomComponent -> CustomComponentBuilder(this, newId)
      is CustomClientComponent -> CustomClientComponentBuilder(this, newId)
      is Screen -> ScreenBuilder(this, newId)
      is ButtonComponent -> ButtonComponentBuilder(this, newId)
      is ContainerComponent -> ContainerComponentBuilder(this, newId)
      is ImageComponent -> ImageComponentBuilder(this, newId)
      is MaterialIconComponent -> MaterialIconComponentBuilder(this, newId)
      is TextComponent -> TextComponentBuilder(this, newId)
      is VideoComponent -> VideoComponentBuilder(this, newId)
      is WebViewComponent -> WebViewComponentBuilder(this, newId)
      is CountdownComponent -> CountdownComponentBuilder(this, newId)
      is DataConversionComponent -> DataConversionComponentBuilder(this, newId)
      is DataRepeaterComponent -> DataRepeaterComponentBuilder(this, newId)
      is DatePartsComponent -> DatePartsComponentBuilder(this, newId)
      is LoadingComponent -> LoadingComponentBuilder(this, newId)
      is PagedLoaderComponent -> PagedLoaderComponentBuilder(this, newId)
      is DatePickerModalComponent -> DatePickerModalComponentBuilder(this, newId)
      is InputComponent -> InputComponentBuilder(this, newId)
      is LocalStateComponent -> LocalStateComponentBuilder(this, newId)
      is ABComponent -> ABComponentBuilder(this, newId)
      is ConditionalComponent -> ConditionalComponentBuilder(this, newId)
      is DialogComponent -> DialogComponentBuilder(this, newId)
      is SheetComponent -> SheetComponentBuilder(this, newId)
      is NavigationComponent -> NavigationComponentBuilder(this, newId)
      is NavigationDrawerComponent -> NavigationDrawerComponentBuilder(this, newId)
      is NavigationItemComponent -> NavigationItemComponentBuilder(this, newId)
      is TabbedPageComponent -> TabbedPageComponentBuilder(this, newId)
      is TabbedPagerComponent -> TabbedPagerComponentBuilder(this, newId)
      is ContentfulRichTextComponent -> ContentfulRichTextComponentBuilder(this, newId)
      else -> throw IllegalStateException("Trying to convert $this which has no mapping")
  }
  return builder
}
