package com.appcreator.creatorapp.editor.centerdata.contentful

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.contentful.ContentfulLoaderSpec
import com.appcreator.creatorapp.editor.contentfulConfig
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager

@Composable
fun ContentfulDataScreen() {
    val blueprintManager = LocalBlueprintManager.current
    val contentfulConfiguration = remember { blueprintManager.contentfulConfig() }
    if (contentfulConfiguration != null) {

        var selectedItem by remember { mutableStateOf(blueprintManager.dataSources.firstOrNull { it.loaderSpec is ContentfulLoaderSpec }) }

        Row(Modifier.fillMaxSize()) {

            ContentfulSideBar(blueprintManager, selectedItem) {
                selectedItem = it
            }
            VerticalDivider()

            selectedItem?.let {
                ContentfulRequestDetails(item = it, save = {
                    blueprintManager.notifyUpdates(true)
                }) {
                    blueprintManager.dataSources.remove(it)
                    selectedItem = null
                }
            }
        }

    } else {

        // TODO make look better
        Text("Enable in settings")
    }
}