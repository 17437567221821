package com.appcreator.blueprint.conversions

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ConversionBuilder
import com.appcreator.blueprint.spec.ConversionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.DataPathInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class DateFormatConversionBuilder(
  conversion: DateFormatConversion?,
  newId: Boolean = false,
) : ConversionBuilder {
  override val spec: ConversionSpec = ConversionSpec(
          group = "",
          name = "DateFormatConversion",
          title = "Date Formatter",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
          isExtraPlugin = false,
      )

  public val datePath: DataPathInputSpec = DataPathInputSpec(
          properties = InputProperties(
          section = "",
          title = "To format",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(conversion?.datePath),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(datePath)

  override fun build(): Conversion = DateFormatConversion(datePath.value)
}
