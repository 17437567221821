package com.appcreator.compose.conditions.data

import com.appcreator.blueprint.conditions.data.ContainsCondition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.conditions.Evaluator

class ContainsEvaluator(private val condition: ContainsCondition): Evaluator {
    override fun evaluateOnce(envStore: EnvStore): Boolean {
        val data = envStore.injectVariables(condition.data)
        val value = envStore.injectVariables(condition.value)
        return data.contains(value)
    }
}
