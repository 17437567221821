package com.appcreator.compose

import androidx.compose.runtime.Composable
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.properties.ColorReference
import kotlinx.coroutines.channels.Channel



data class SnackbarData(
    val message: String,
    val containerColor: ColorReference?,
    val contentColor: ColorReference?
)

val LocalSnackbarAlert = staticCompositionLocalOf<Channel<SnackbarData>?> {
   null
}

@Composable
expect fun SnackbarAlertContainer(modifier: Modifier = Modifier, content: @Composable () -> Unit)
