package com.appcreator.dto

import kotlinx.serialization.Serializable

@Serializable
data class PlansResponse(
    val publicKey: String,
    val plans: List<Plan>
)

@Serializable
data class Plan(
    val buttonId: String,
)
