package com.appcreator.compose.components.basic

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.basic.VideoComponent
import com.appcreator.compose.HtmlView
import com.appcreator.compose.extensions.value
import org.w3c.dom.HTMLElement

@Composable
actual fun VideoComposable(
    modifier: Modifier,
    component: VideoComponent
) {

    component.url?.value() ?.let {
        HtmlView(
            modifier = modifier.fillMaxWidth().height(300.dp),
            factory = {
                val video = createElement("video") as HTMLElement
                video.setAttribute("controls", "")
                val source = createElement("source") as HTMLElement
                source.setAttribute("src", it)
                source.setAttribute("type", "video/mp4")
                video.appendChild(source)
                video
            },
            update = {

            }
        )
    }
}
