package com.appcreator.creatorapp.editor.inputs

import androidx.compose.material3.Button
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.appcreator.blueprint.core.properties.UserDefinedComponent
import com.appcreator.blueprint.spec.inputs.UserDefinedComponentInputSpec
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.creatorapp.editor.local.LocalCustomComponentManager
import com.appcreator.local.LocalSnackbar
import com.appcreator.local.SnackbarInfo
import kotlinx.coroutines.launch

@Composable
fun UserDefinedComponentInput(spec: UserDefinedComponentInputSpec, save: () -> Unit) {
    val customComponentManager = LocalCustomComponentManager.current
    val selected = customComponentManager.components.find { it.id == spec.value?.id }

    var name by remember { mutableStateOf("") }
    val scope = rememberCoroutineScope()
    ModalScaffold(
        anchor = {
            DropDownInputGroup(
                title = spec.properties.title,
                supportingText = spec.properties.description,
                dropDownTitle = selected?.name ?: "Select Component",
                dropDownContent = { close ->
                    customComponentManager.components.forEach {
                        DropdownItem(it.name) {
                            spec.value = UserDefinedComponent(it.id, it.name)
                            save()
                            close()
                        }
                    }

                    HorizontalDivider()

                    DropdownItem("Create new") {
                        name = ""
                        close()
                        it()
                    }
                }
            )
        },
        header = {
            Text("Add Component")
        },
        body = {
//            InputGroup(modifier = Modifier.fillMaxWidth()) {
//                LeftAddon { Text("Name:") }
//                TextInput(
//                    text = name,
//                    onTextChanged = {
//                        name = it
//                    }
//                )
//            }
        },
        footer = {
            val snackbarChannel = LocalSnackbar.current
            Button(onClick = {
                scope.launch {
                    try {
                        val comp = customComponentManager.createComponent(name)
                        spec.value = UserDefinedComponent(comp.id, comp.name)
                        save()
                        it()
                    } catch (ex: Exception) {
                        ex.printStackTrace()
                        snackbarChannel.send(SnackbarInfo.Builder.fromNetworkException(ex))
                    }
                }
            }) {
                Text("Create")
            }
        }
    )
}