package com.appcreator.blueprint.spec.inputs

data class InputProperties(
    val section: String,
    val title: String,
    val description: String,
    val availableFrom: String,
    val displayInline: Boolean,
    val allowDataFields: Boolean,
    val dataOptions: String,
    val settableDataOnly: Boolean,
    val includeSiblingData: Boolean,
    val visible: () -> Boolean,
    val dataOptionsPathFilter: () -> List<String>,
    val alwaysContainer: Boolean,
    val extraDataAvailable: List<String>
)

object DataSpecType {
    const val List = "List"
    const val Value = "Value"
    const val RichText = "RichText"
}