package com.appcreator.creatorapp.project.configurations

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import com.appcreator.blueprint.contentful.ContentfulConsts
import com.appcreator.components.layouts.CheckboxGroup
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.Organisation
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.dto.configurations.ContentfulConfiguration
import com.appcreator.dto.configurations.ThirdPartyConfiguration
import com.appcreator.dto.configurations.ThirdPartyConfigurationTypes
import com.appcreator.styles.ThemeMargins
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.decodeFromJsonElement
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.jsonObject

private fun ThirdPartyConfiguration.configOrDefault(json: Json): ContentfulConfiguration {
    return config?.let { json.decodeFromJsonElement(it) } ?: ContentfulConfiguration(
        space = "",
        environment = "master",
        accessToken = ""
    )
}

@Composable
fun ContentfulConfiguration(
    forceEnabled: Boolean = false,
    organisation: Organisation,
    project: Project,
    inset: Dp = ThemeMargins.LargeMargins,
    saveButtonAtTop: Boolean = true,
    onSaved: (ContentfulConfiguration) -> Unit = {}
) {
    LoadingComposable(loader = {
        mutableStateOf(it.getThirdPartyConfiguration(organisation.id, project.id, ThirdPartyConfigurationTypes.contentful))
    }) {

        val json = LocalApiClient.current.json
        var display by remember { mutableStateOf(it.value) }
        val startConfig = remember { it.value.configOrDefault(json) }
        var displayConfig by remember { mutableStateOf(startConfig) }

        LaunchedEffect(forceEnabled) {
            display = display.copy(enabled = true, components = listOf(ContentfulConsts.group))
            if(it.value.enabled) { onSaved(displayConfig) }
        }

        val saveButton: @Composable () -> Unit = {
            val client = LocalApiClient.current
            LoadingButton(
                label = "Save Changes",
                enabled = it.value != display || startConfig != displayConfig,
                action = {
                    client.setThirdPartyConfiguration(
                        organisation.id,
                        project.id,
                        display.copy(config = json.encodeToJsonElement(displayConfig).jsonObject)
                    )
                    it.value = display
                    onSaved(displayConfig)
                }
            )
        }

        Column(Modifier.padding(horizontal = inset)) {
            if(!forceEnabled) {
                CheckboxGroup(
                    label = "Enabled",
                    checked = display.enabled,
                    onCheckedChange = {
                        display =
                            display.copy(enabled = it, components = listOf(ContentfulConsts.group))
                    }
                )
            }

            if(saveButtonAtTop) {
                saveButton()
            }
        }

        if (display.enabled) {
            Column(Modifier.padding(vertical = ThemeMargins.LargeMargins, horizontal = inset)) {
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Space ID",
                    value = displayConfig.space,
                    onValueChange = {
                        displayConfig = displayConfig.copy(space = it)
                    }
                )

                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Environment",
                    value = displayConfig.environment,
                    onValueChange = {
                        displayConfig = displayConfig.copy(environment = it)
                    }
                )

                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Access Token",
                    value = displayConfig.accessToken,
                    supportingText = "Content Delivery API - access token",
                    onValueChange = {
                        displayConfig = displayConfig.copy(accessToken = it)
                    }
                )
            }
        }

        if(!saveButtonAtTop) {
            Spacer(Modifier.height(ThemeMargins.LargeMargins))
            saveButton()
        }
    }
}

