package com.appcreator.creatorapp.editor

import com.appcreator.blueprint.components.basic.ContainerComponent
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.ComponentPosition
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentListInputSpec
import com.appcreator.creatorapp.editor.local.NodeExplorer

class NodeMoveHelpers(
    val nodeExplorer: NodeExplorer,
    val blueprintManager: BlueprintManager,
    val closeSidebar: () -> Unit
) {


    val addComponent: (ComponentBuilder, DragCompleteInfo) -> Unit = { new, info ->
        nodeExplorer.findNode(info.component._nodeId, blueprintManager.screenBuilder)?.let { toDropNode ->
            (toDropNode as? ContainerComponentBuilder)?.let {
                val index = info.indexProvider?.invoke() ?: -1
                if (index < 0) {
                    it.content.value.add(new)
                } else {
                    it.content.value.add(index, new)
                }

                if(it.positioning.value == ContainerComponent.Positioning.Exact.name) {
                    // Clean out old pos just in case
                    it.exactPositions.value.find { it.positionOf == new._nodeRelativeId.value }?.let { pos ->
                        it.exactPositions.value.remove(pos)
                    }

                    it.exactPositions.value.add(
                        ComponentPosition(
                            positionOf = new._nodeRelativeId.value ?: "",
                            x = info.dropLocation.x,
                            y = info.dropLocation.y
                        )
                    )
                }

                blueprintManager.notifyUpdates(false)
                blueprintManager.selectedComponent.value = new

                // close side bar after adding
                closeSidebar()

            }
        }
    }

    val deleteComponent: (ComponentBuilder, ComponentBuilder) -> Unit = { toDelete, parent ->
        parent.removeChild(toDelete)?.let {
            if(it.properties.alwaysContainer) {
                (it as? ComponentInputSpec)?.let { spec ->
                    spec.value = ContainerComponentBuilder(null, false)
                }
            }
        }
    }

    val moveNode: (Component, DragCompleteInfo?) -> Unit = { toMove, info ->
        nodeExplorer.findNode(toMove._nodeId, blueprintManager.screenBuilder)?.let { toMoveBuilder ->
            val currentParent = nodeExplorer.parent(toMoveBuilder)
            info?.let {
                nodeExplorer.findNode(info.component._nodeId, blueprintManager.screenBuilder)?.let { toDropNode ->
                    if (currentParent?._nodeId?.value == toDropNode._nodeId.value) {
                        (currentParent as? ContainerComponentBuilder)?.let {
                            val currentPosition = currentParent.exactPositions.value.find { it.positionOf == toMove._nodeRelativeId }?: ComponentPosition(
                                positionOf = toMove._nodeRelativeId ?: "",
                                x = info.dropLocation.x,
                                y = info.dropLocation.y
                            )

                            currentParent.exactPositions.value.remove(currentPosition)
                            currentParent.exactPositions.value.add(currentPosition.copy(x = info.dropLocation.x, y = info.dropLocation.y))
                        }
                    } else {
                        // can't move into itself
                        if (toMoveBuilder._nodeId.value != info.component._nodeId) {
                            currentParent?.let { deleteComponent(toMoveBuilder, currentParent) }
                            addComponent(toMoveBuilder, info)
                        }
                    }
                }
            }
        }
    }

    private fun ComponentBuilder.removeChild(child: ComponentBuilder): InputSpec? {
        inputSpecs.forEach {
            when(it) {
                is ComponentInputSpec -> {
                    if (it.value == child) {
                        it.value = null
                        return it
                    }
                }
                is ComponentListInputSpec -> {
                    if (it.value.contains(child)) {
                        it.value.remove(child)
                        return it
                    }
                }
            }
        }
        return null
    }
}