package com.appcreator.compose.extensions

import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.calculateStartPadding
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.layout.width
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.properties.SizeValue
import com.appcreator.compose.AbsoluteSize
import com.appcreator.compose.LocalAbsoluteSize


@Composable
fun SizingView(
    width: SizeValue?,
    height: SizeValue?,
    minWidth: Int? = null,
    maxWidth: Int? = null,
    minHeight: Int? = null,
    maxHeight: Int? = null,
    horizontalSpacing: Int = 0,
    verticalSpacing: Int = 0,
    aspectRatio: Float? = null,
    padding: PaddingValues = PaddingValues(),
    margin: PaddingValues = PaddingValues(),
    content: @Composable (Modifier, hasWidth: Boolean, hasHeight: Boolean) -> Unit
) {
    val absoluteSize: AbsoluteSize? = LocalAbsoluteSize.current
    val widthDp = absoluteSize?.width(0, width, margin)?.coerceIn(
        minimumValue = minWidth?.dp,
        maximumValue = maxWidth?.dp?.coerceIn(minimumValue = minWidth?.dp, maximumValue = null)
    )
    val heightDp = if(aspectRatio != null) widthDp?.times(aspectRatio) else absoluteSize?.height(0, height, margin)?.coerceIn(
        minimumValue = minHeight?.dp,
        maximumValue = maxHeight?.dp?.coerceIn(minimumValue = minHeight?.dp, maximumValue = null)
    )

    val modifier = Modifier
        .sizeIn(
            minWidth = minWidth?.dp ?: Dp.Unspecified,
            maxWidth = maxWidth?.dp ?: Dp.Unspecified,
            minHeight = minHeight?.dp ?: Dp.Unspecified,
            maxHeight = maxHeight?.dp ?: Dp.Unspecified,
        )
        .let {
            if (absoluteSize != null) {
                widthDp?.let { dp -> it.width(dp) }?: it
            } else {
                when (width) {
                    is SizeValue.Percent -> it.fillMaxWidth((width.value.toFloat() / 100f).coerceAtMost(1f))
                    is SizeValue.Px -> it.width(width.value.dp)
                    null -> it
                }
            }
        }
        .let {
            if (absoluteSize != null) {
                heightDp?.let { dp -> it.height(dp) }?: it
            } else if(aspectRatio != null) {
                    it.aspectRatio(aspectRatio)
            } else {
                when (height) {
                    is SizeValue.Percent -> it.fillMaxHeight((height.value.toFloat() / 100f).coerceAtMost(1f))
                    is SizeValue.Px -> it.height(height.value.dp)
                    null -> it
                }
            }
        }


    CompositionLocalProvider(LocalAbsoluteSize provides absoluteSize?.let {
        AbsoluteSize(absoluteSize, widthDp, heightDp, PaddingValues(
            start = padding.calculateStartPadding(LayoutDirection.Ltr) + horizontalSpacing.dp / 2,
            top = padding.calculateTopPadding() + verticalSpacing.dp / 2,
            end = padding.calculateRightPadding(LayoutDirection.Ltr),
            bottom = padding.calculateBottomPadding())
        )
    }) {
        content(modifier, width != null, height != null)
    }

}

