package com.appcreator.blueprint.actions.navigation

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.ActionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.IntInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class CloseScreenActionBuilder(
  action: CloseScreenAction?,
  newId: Boolean = false,
) : ActionBuilder {
  override val spec: ActionSpec = ActionSpec(
          name = "CloseScreenAction",
          title = "Close Screen",
          group = "Navigation",
          isExtraPlugin = false,
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
      )

  public val closeType: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "",
          title = "Type",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((action?.closeType?: CloseScreenAction.CloseType.Single).name),
          
          options = CloseScreenAction.CloseType.entries.map { it.name },
      )

  public val count: IntInputSpec = IntInputSpec(
          properties = InputProperties(
          section = "",
          title = "Count",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && closeType.value == CloseScreenAction.CloseType.Count.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(action?.count),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(closeType, count)

  override fun build(): Action = CloseScreenAction(closeType.value?.let {
      CloseScreenAction.CloseType.valueOf(it) }?: CloseScreenAction.CloseType.Single, count.value)
}
