package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.LoaderConfig
import com.appcreator.blueprint.core.LoaderSpecReference
import com.appcreator.blueprint.spec.inputs.LoaderConfigInputSpec
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput
import com.appcreator.styles.ThemeMargins

@Composable
fun LoaderConfigInput(spec: LoaderConfigInputSpec, save: () -> Unit) {

    val blueprintManager = LocalBlueprintManager.current
    val options = blueprintManager.dataSources
    val selected = options.find { it.id == spec.value?.loaderSpec?.id }

    DropDownInputGroup(
        title = spec.properties.title,
        dropDownTitle = selected?.title?.ifEmpty { selected.id } ?: "Select data source",
        supportingText = spec.properties.description,
        dropDownContent = { close ->
            options.forEach {
                DropdownItem(it.title.ifEmpty { it.id }) {
                    spec.value = LoaderConfig(
                        loaderSpec = LoaderSpecReference(it.id),
                        parameters = emptyMap(),
                        supportsPaging = it.loaderSpec.supportsPaging
                    )
                    save()
                    close()
                }
            }
        }
    )

    val globalData = blueprintManager.globalData
    val loader = spec.value?.loaderSpec?.let { blueprintManager.blueprint.loaderSpec(it) }

    Column(Modifier.padding(start = ThemeMargins.LargeMargins)) {


        loader?.parameters
            ?.filter { param -> globalData.find { it.key == param } == null }
            ?.forEach { parameter ->

                val specValue = spec.value!!

                Box(Modifier.height(ThemeMargins.SmallMargin))


                DataSourceTextInput(
                    label = parameter,
                    value = specValue.parameters[parameter] ?: "",
                    update = { input ->
                        spec.value =
                            spec.value?.copy(parameters = specValue.parameters.plus(parameter to input))
                        save()
                    }
                )
            }
    }
}
