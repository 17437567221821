package com.appcreator.compose.components.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.forms.LocalStateComponent
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.InputEnvComposable

@Composable
fun LocalStateComposable(modifier: Modifier, component: LocalStateComponent) {
    val envStore = LocalEnvStore.current
    val initial = remember { component.items?.associate { it.key to it.mapContents(envStore) }?: emptyMap() }
    InputEnvComposable(initial = initial, key = component.rememberKey?: component._nodeId, component.remember == true) {
        component.content?.let { component ->
            ComponentComposable(modifier, component)
        }
    }
}

fun DataSpec.mapContents(envStore: EnvStore): Any =
    when (this) {
        is ListDataSpec -> listOf<Any>()
        is ValueDataSpec -> envStore.injectVariables(value?: "")
        else -> ""
    }
