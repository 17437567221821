package com.appcreator.local

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Snackbar
import androidx.compose.material3.SnackbarDuration
import androidx.compose.material3.SnackbarHost
import androidx.compose.material3.SnackbarHostState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.Modifier
import com.appcreator.compose.components.data.toExceptionParams
import com.appcreator.dto.ErrorResponse
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.receiveAsFlow

class SnackbarInfo(
    val message: String
) {

    object Builder {
        suspend fun fromNetworkException(ex: Exception): SnackbarInfo {
            when(ex) {
                is ClientRequestException -> try {
                    ex.response.body<ErrorResponse>().error.message?.let {
                        return SnackbarInfo(it)
                    }

                } catch (_: Exception) {}
            }
            val params = ex.toExceptionParams()
            return SnackbarInfo(params.message)
        }
    }

}

val LocalSnackbar = staticCompositionLocalOf<Channel<SnackbarInfo>> {
   error("No SnackbarContainer")
}

@Composable
fun SnackbarContainer(modifier: Modifier = Modifier, content: @Composable () -> Unit) {
    val snackbarHostState = remember { SnackbarHostState() }
    val channel = remember { Channel<SnackbarInfo>(Channel.CONFLATED) }
    LaunchedEffect(channel) {
        channel.receiveAsFlow().collect { info ->
            snackbarHostState.showSnackbar(
                message = info.message,
                duration = SnackbarDuration.Short
            )
        }
    }

    Scaffold(
        modifier = modifier,
        snackbarHost = {
            SnackbarHost(snackbarHostState) {
                Snackbar(
                    snackbarData = it,
                    containerColor = MaterialTheme.colorScheme.errorContainer,
                    contentColor = MaterialTheme.colorScheme.onErrorContainer
                )
            }
        }) {
        CompositionLocalProvider(LocalSnackbar provides channel) {
            Column(Modifier.padding(it)) { content() }
        }
    }

}