package com.appcreator.compose

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.navigation.NavBackStackEntry
import androidx.navigation.NavHostController
import androidx.navigation.compose.currentBackStackEntryAsState
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.Destination
import com.appcreator.blueprint.ScreenSpec
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.blueprint.findDestination
import io.ktor.http.encodeURLParameter

interface Navigator {

    val includesToolbar: Boolean
    val nestingCount: Int
    fun navigate(link: BlueprintLink, envStore: EnvStore, options: OptionsBuilder.() -> Unit = {})
    fun navigate(destination: Destination, options: OptionsBuilder.() -> Unit)
    fun findDestination(link: BlueprintLink, envStore: EnvStore): Destination?
    @Composable
    fun selected(): ScreenSpec?
    fun canGoBack(): Boolean
    fun back()
    fun back(count: Int)
    fun closeModal()
    fun registerModal(dismiss: () -> Unit)
    fun unregisterModal()
    fun popToRoot()

    class OptionsBuilder {
        // TODO work out how to best break this down with iOS in mind
        var popUpToRoot: Boolean = false
        var singleTop: Boolean = false
    }

}

class KotlinXNavigator(
    override val includesToolbar: Boolean,
    val parent: Navigator?, // TODO allow for root screen changes and bottom sheets
    val startDestination: String,
    val blueprint: Blueprint,
    val navController: NavHostController,
    override val nestingCount: Int = parent?.let { it.nestingCount + 1 }?: 0
): Navigator {

    companion object {
        const val route = "route"
    }

    private var modelStack = mutableListOf<() -> Unit>()

    override fun navigate(
        link: BlueprintLink,
        envStore: EnvStore,
        options: Navigator.OptionsBuilder.() -> Unit
    ) {
        // Close all models when navigating
        modelStack.reversed().forEach {
            it()
        }

        val builder = Navigator.OptionsBuilder().apply(options)
        val queryRaw: String = envStore.injectLink(link)
        val query = queryRaw.encodeURLParameter()

        // Single top
        val currentPath = navController.currentBackStackEntry?.arguments?.getString(route)
        if (currentPath == query) return

        navController.navigate("screen?$route=$query") {
            if (builder.popUpToRoot) {
                navController.graph.startDestinationRoute?.let {
                    popUpTo(it) {
                        saveState = true
                        inclusive = true
                    }
                }
            }
            if (builder.singleTop) {
                launchSingleTop = true
                restoreState = true
            }
        }
    }

    override fun navigate(destination: Destination, options: Navigator.OptionsBuilder.() -> Unit) {
        val builder = Navigator.OptionsBuilder().apply(options)
        val query: String =  destination.screen.path.encodeURLParameter()

        navController.navigate("screen?$route=$query") {
            if (builder.popUpToRoot) {
                navController.graph.startDestinationRoute?.let {
                    popUpTo(it) {
                        saveState = true
                        inclusive = false
                    }
                }
            }
            if (builder.singleTop) {
                launchSingleTop = true
                restoreState = true
            }
        }
    }

    override fun findDestination(link: BlueprintLink, envStore: EnvStore): Destination? =
        blueprint.findDestination(envStore.injectLink(link))

    @Composable
    override fun selected(): ScreenSpec? {
        val backStackEntry by navController.currentBackStackEntryAsState()
        val route = backStackEntry?.arguments?.getString(route)
        return route?.let {
            blueprint.findDestination(route)?.screen
        }
    }

    override fun canGoBack(): Boolean =
        navController.currentBackStack.value.count() > 2 || modelStack.isNotEmpty()

    override fun back() {
//        println("BACK CALLED")
//        when {
//            modelStack.isNotEmpty() -> modelStack.last()()
//            canGoBack() -> navController.popBackStack()
//            else -> parent?.back()
//        }
        modelStack.reversed().forEach {
            it()
        }
        if(canGoBack()) {
            navController.popBackStack()
        } else {
            parent?.back()
        }
    }

    override fun back(count: Int) {
        modelStack.reversed().forEach {
            it()
        }
//        val stack = navController.currentBackStack.value
//        println("STACK" + stack.joinToString { it.pathFromEntry()?: "unknoiw" })
//        val target = stack.getOrElse(stack.lastIndex - count) {
//            stack.first()
//        }
//        println("STACK" + target.pathFromEntry())

        (1..count).forEach { _ ->
            navController.popBackStack()
        }
    }

    override fun closeModal() {
        modelStack.reversed().forEach {
            it()
        }
    }

    override fun registerModal(dismiss: () -> Unit) {
        modelStack.add(dismiss)
    }

    override fun unregisterModal() {
        modelStack.removeLast()
    }

    override fun popToRoot() {
        println(navController.graph.startDestinationRoute)
        println(navController.graph.route)

        navController.graph.startDestinationRoute?.let {
            navController.popBackStack(it, inclusive = false)
        }
    }

    @Composable
    fun inStack(link: BlueprintLink?, envStore: EnvStore): Boolean {
        link?.path ?: return false
        return navController
            .currentBackStack
            .collectAsState()
            .value
            .map {
                it.arguments?.getString(route)
            }
            .any {
                it == envStore.injectLink(link)
            }
    }

    fun parentPath(): String {
        return parent?.let {
            ((parent as KotlinXNavigator).navController.currentBackStack.value.lastOrNull()?.pathFromEntry()?: parent.startDestination.trimEnd('/')) + "/_/"
        }?: ""
    }

}

fun NavBackStackEntry.pathFromEntry(): String? {
    return arguments?.getString("route")
        ?: destination.arguments["route"]?.defaultValue as? String
}