package com.appcreator.creatorapp.editor

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Surface
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import com.appcreator.app.utils.onTrack
import com.appcreator.blueprint.components.basic.ContainerComponent
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.properties.ComponentPosition
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentListInputSpec
import com.appcreator.components.layouts.ScrollBars
import com.appcreator.compose.LocalBlueprint
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.components.InputEnvStore
import com.appcreator.compose.components.LocalComponentModifierProvider
import com.appcreator.compose.di.Setup
import com.appcreator.compose.extensions.defaultJSEvaluationConfig
import com.appcreator.compose.tracingPaperBackground
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.Navigation
import com.appcreator.creatorapp.editor.local.ComponentClipboard
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.creatorapp.editor.local.LocalComponentClipboard
import com.appcreator.creatorapp.editor.local.LocalCustomComponentManager
import com.appcreator.creatorapp.editor.local.LocalNodeExplorer
import com.appcreator.creatorapp.editor.local.LocalScreenHistory
import com.appcreator.creatorapp.editor.local.LocalScreenOptions
import com.appcreator.creatorapp.editor.local.LocalSelectedComponent
import com.appcreator.creatorapp.editor.local.NodeExplorer
import com.appcreator.creatorapp.editor.local.ScreenHistory
import com.appcreator.creatorapp.editor.panelcomponent.ComponentPanel
import com.appcreator.creatorapp.editor.panelcomponenttree.ComponentTreePanel
import com.appcreator.creatorapp.editor.paneldevicepreview.DevicePreviewPanel
import com.appcreator.creatorapp.editor.panelsidebar.LeftSidebarPanel
import com.appcreator.creatorapp.editor.panelsidebar.RightSidebarPanel
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.creatorapp.local.LocalSettings
import com.appcreator.creatorapp.local.SettingsKeys
import com.appcreator.dto.Organisation
import com.appcreator.local.LocalSnackbar
import com.appcreator.styles.ThemeMargins
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import kotlinx.coroutines.launch

val LocalMousePosition = staticCompositionLocalOf<State<Offset>> {
    error("No instance of LocalMousePosition provided")
}

val LocalDrag = staticCompositionLocalOf<DragHolder> {
    error("No instance of LocalDrag provided")
}

enum class PreviewSize(val modifier: Modifier) {
    Phone(Modifier
        .height(1250.dp)
        .aspectRatio(0.5625f, matchHeightConstraintsFirst = true)
        .padding(40.dp)
    ),
    Tablet(Modifier
        .width(2000.dp)
        .aspectRatio(1.3f, matchHeightConstraintsFirst = true)
        .padding(40.dp)
    ),
    Fill(Modifier.fillMaxSize()),
}

@Composable
fun ProjectEditorScreen(
    organisation: Organisation,
    projectId: String,
    blueprintId: String,
    notFound: () -> Unit,
    navigate: (String) -> Unit,
    openNewTab: (String) -> Unit
) {

    val orgId = organisation.id

    LoadingComposable(
        loader = {
            it.getBlueprint(orgId, projectId, blueprintId) to it.getUserComponents(orgId, projectId)
        }, notFound = notFound
    ) { (blueprintResponse, userComponents) ->

        val scope = rememberCoroutineScope()
        val screenHistory = remember { ScreenHistory() }
        val config = defaultJSEvaluationConfig()
        val client = LocalApiClient.current
        val snackbarChannel = LocalSnackbar.current

        val blueprintManager = remember {
            BlueprintManager(
                blueprint = blueprintResponse.blueprint,
                dataSources = blueprintResponse.dataSources,
                config = blueprintResponse.thirdPartyConfiguration,
                json = client.json,
                screenHistory = screenHistory,
                orgId = organisation.id,
                projectId = projectId,
                blueprintId = blueprintId,
                apiClient = client,
                coroutineScope = scope,
                snackbarChannel = snackbarChannel
            ).also {
                Setup.initialize(
                    screenFetcher = { _ -> it },
                    blueprintProvider = { _ -> it },
                    jsEvaluationConfig = config,
                )
            }
        }

        val customComponentManager = remember {
            CustomComponentManager(
                orgId = orgId,
                projectId = projectId,
                apiClient = client,
                _components = userComponents.components
            )
        }

        val hoverAndDragManager = remember { HoverAndDragManager(blueprintManager) }

        val globalDefaults = remember {
            blueprintManager.blueprint.globalData?.associate {
                it.key to it.value( blueprintManager.blueprint.defaultEnvironment)
            }?: emptyMap()
        }
        val globalState = remember { InputEnvStore(globalDefaults, null, false,null) }


        CompositionLocalProvider(
            LocalBlueprintManager provides blueprintManager,
            LocalSelectedComponent provides blueprintManager.selectedComponent,
            LocalScreenOptions provides blueprintManager.blueprint.screens,
            LocalNodeExplorer provides NodeExplorer(blueprintManager),
            LocalComponentClipboard provides remember { ComponentClipboard() },
            LocalScreenHistory provides screenHistory,
            LocalCustomComponentManager provides customComponentManager,
            LocalDrag provides hoverAndDragManager,
            LocalInputEnvStore provides globalState,
            LocalEnvStore provides EnvStore.create(defaultLocale =  blueprintManager.blueprint.defaultLocale, env = globalState.input),
            LocalBlueprint provides blueprintManager.blueprint,
            LocalTheme provides blueprintManager.blueprint.theme,
            LocalInPreview provides true
        ) {

            val openPreview: () -> Unit = {
                scope.launch {
                    try {
                        val response = client.getPreview(orgId, projectId)
                        openNewTab(response.url)
                    } catch (ex: Exception) {
                        ex.printStackTrace()
                    }
                }
            }

            BoxWithConstraints {
                if (maxWidth < 800.dp || maxHeight < 400.dp) {

                    Box(Modifier.fillMaxSize().tracingPaperBackground()) {
                        Column(Modifier.align(Alignment.Center).width(450.dp),
                            horizontalAlignment = Alignment.CenterHorizontally,
                            verticalArrangement = Arrangement.spacedBy(ThemeMargins.MedMargins)
                        ) {
                            Text(
                                text = "Your screen is a little too small!",
                                textAlign = TextAlign.Center,
                                style = MaterialTheme.typography.headlineSmall
                            )
                            Text(
                                text = "Increase your browser size or switch to a larger device. You can preview your app in the mean time.",
                                textAlign = TextAlign.Center
                            )
                            Button(onClick = openPreview) {
                                Text(
                                    text = "Preview",
                                )
                            }
                        }
                    }

                } else {

                    ProjectEditorContent(
                        organisation = organisation,
                        blueprintManager = blueprintManager,
                        dragManager = hoverAndDragManager,
                        projectId = projectId,
                        openPreview = openPreview,
                        openSettings = {
                            navigate("/${Navigation.Organisations.route}/$orgId/${Navigation.Projects.route}/$projectId")
                        },
                        openOrganisation = {
                            navigate("/${Navigation.Organisations.route}/$orgId")
                        }
                    )
                }
            }
        }
    }
}

@Composable
private fun ProjectEditorContent(
    organisation: Organisation,
    blueprintManager: BlueprintManager,
    dragManager: HoverAndDragManager,
    projectId: String,
    openPreview: () -> Unit,
    openSettings: () -> Unit,
    openOrganisation: () -> Unit
) {

    var previewSize by remember { mutableStateOf(PreviewSize.Phone) }
    val mouseLocation = remember { mutableStateOf(Offset.Zero) }
    val density = LocalDensity.current
    val settings = LocalSettings.current
    var showNodes by remember { mutableStateOf(settings[SettingsKeys.ShowingNodes, false]) }

    val rightBarContent = remember { mutableStateOf<(@Composable () -> Unit)?>(null) }
    val nodeExplorer = LocalNodeExplorer.current

    val nodeMoveHelpers = NodeMoveHelpers(nodeExplorer, blueprintManager) {
        rightBarContent.value = null
    }

    CompositionLocalProvider(LocalMousePosition provides mouseLocation) {
        BoxWithConstraints(Modifier
            .fillMaxSize()
            .onTrack {
                mouseLocation.value = with(density) { Offset(it.x.toPx(), it.y.toPx()) }
            }
        ) {
            val supportsBothPanels = maxWidth > 1500.dp

            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding(start = 64.dp, end = 64.dp, top = 64.dp)
            ) {
                CompositionLocalProvider(
                    LocalComponentModifierProvider provides dragManager
                ) {
                    DevicePreviewPanel(previewSize, blueprintManager, dragManager, nodeMoveHelpers.moveNode)
                }

                Surface {
                    BoxWithConstraints {
                        Row(modifier = Modifier) {

                            AnimatedVisibility(supportsBothPanels || !showNodes) {
                                Box(Modifier.fillMaxHeight().width(400.dp)) {

                                    val state = rememberScrollState()
                                    LaunchedEffect(LocalSelectedComponent.current.value) {
                                        state.scrollTo(0)
                                    }

                                    Column(
                                        Modifier
                                            .fillMaxSize()
                                            .verticalScroll(state)
                                            .padding(ThemeMargins.SmallMargin)
                                            .padding(end = if (state.canScrollBackward || state.canScrollForward) 8.dp else 0.dp)
                                    ) {
                                        ComponentPanel(blueprintManager, nodeMoveHelpers.deleteComponent)
                                    }

                                    ScrollBars(state)

                                    VerticalDivider()
                                }
                            }

                            AnimatedVisibility(showNodes) {
                                VerticalDivider()
                                ComponentTreePanel(dragManager, nodeMoveHelpers.addComponent, nodeMoveHelpers.deleteComponent, blueprintManager)
                            }
                        }
                        AnimatedContent(
                            targetState = rightBarContent.value
                        ) {
                            if (it != null) {
                                Box(
                                    Modifier
                                        .clickable(
                                            interactionSource = remember { MutableInteractionSource() },
                                            indication = null, onClick = {
                                                rightBarContent.value = null
                                            })
                                        .fillMaxHeight()
                                        .width(if (showNodes && supportsBothPanels) 800.dp else 400.dp)
                                        .background(MaterialTheme.colorScheme.background.copy(alpha = 0.6f))
                                )
                            }
                        }
                    }
                }
            }

            val dragItem = LocalDrag.current

            LeftSidebarPanel(organisation.id, projectId)
            RightSidebarPanel(
                modifier = Modifier
                    .padding(top = 54.dp)
                    .align(Alignment.CenterEnd),
                content = rightBarContent,
//                dragging = dragItem.dragItem != null,
                addComponent = nodeMoveHelpers.addComponent
            )

            HeaderBar(
                blueprintManager = blueprintManager,
                organisation = organisation,
                previewSize = previewSize,
                setPreviewSize = {
                    previewSize = it
                },
                showingNodes = showNodes,
                showNodes = {
                    showNodes = it
                    settings[SettingsKeys.ShowingNodes] = it
                },
                openPreview = openPreview,
                openOrganisation = openOrganisation,
                openSettings = openSettings
            )

            dragItem.dragItem?.let {
                Box(Modifier.offset {
                    IntOffset(
                        mouseLocation.value.x.toInt(),
                        mouseLocation.value.y.toInt()
                    )
                }) {
                    it.render()
                }
            }
        }
    }
}

