package com.appcreator.compose.components.basic

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.IconButton
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.basic.ButtonComponent
import com.appcreator.blueprint.components.basic.TextComponent
import com.appcreator.compose.LocalAnalytics
import com.appcreator.compose.LocalAnchorAction
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.evaluator
import com.appcreator.compose.di.performer
import com.appcreator.compose.extensions.SizingView
import com.appcreator.compose.extensions.composeColor
import com.appcreator.compose.extensions.toShape
import com.appcreator.compose.extensions.value
import kotlinx.coroutines.launch

private sealed class ButtonState {
    data object Normal: ButtonState()
    data object Loading: ButtonState()
}

@Composable
fun ButtonComposable(
    modifier: Modifier,
    component: ButtonComponent
) {
    ButtonComposableInternal(modifier, component)
}

@Composable
internal fun ButtonComposableInternal(
    modifier: Modifier,
    component: ButtonComponent
) {
    val envStore = LocalEnvStore.current
    val theme = LocalTheme.current
    val deferred = component.action?.let { Container.performer(it)?.deferred() }
    val scope = rememberCoroutineScope()

    var state by remember { mutableStateOf<ButtonState>(ButtonState.Normal) }

    val borderStroke = if (component.borderThickness != null && component.borderColor != null) {
        BorderStroke((component.borderThickness?: 0).dp, theme?.color(component.borderColor)?.composeColor() ?: Color.Unspecified)
    } else null

    SizingView(component.width, component.height) { mod, _, _ ->
        val loading = state == ButtonState.Loading
        val content: @Composable () -> Unit = {
            Box(contentAlignment = Alignment.Center) {
                Row(Modifier.alpha(if (loading) 0.0f else 1.0f)) {
                    component.content?.let { content ->
                        ComponentComposable(Modifier, content)
                    }
                }
                if (loading) {
                    CircularProgressIndicator(modifier.size(14.dp), strokeWidth = 2.dp)
                }
            }
        }

        val analytics = LocalAnalytics.current
        val buttonName = component.findButtonName()

        val anchorAction = LocalAnchorAction.current
        val onClick: () -> Unit = {
            scope.launch {
                buttonName?.let {
                    analytics.event("click", mapOf("button_name" to it))
                }
                state = ButtonState.Loading
                try {
                    anchorAction?.invoke()
                    deferred?.perform(envStore)
                } catch (ex: Exception) {
                    ex.printStackTrace()
                    // TODO something
                }
                state = ButtonState.Normal
            }
        }

        val enabled = !loading && (component.buttonEnabled?.let { Container.evaluator(it)?.evaluate(envStore) } ?: true)
        when(component.buttonType) {
            ButtonComponent.ButtonType.Icon -> IconButton(
                modifier = mod.then(modifier),
                onClick = onClick,
                enabled = enabled,
                content = content
            )
            ButtonComponent.ButtonType.Text -> TextButton(
                modifier = mod.then(modifier),
                onClick = onClick,
                enabled = enabled,
                content = {
                    content()
                }
            )
            else ->
                Button(
                    modifier = mod.then(modifier),
                    colors = ButtonDefaults.buttonColors(
                        containerColor = theme?.color(component.color)?.composeColor()
                            ?: Color.Unspecified,
                        contentColor = LocalContentColor.current
                    ),
                    border = borderStroke,
                    shape = component.borderRadius?.toShape() ?: ButtonDefaults.shape,
                    onClick = onClick,
                    enabled = enabled,
                    content = {
                        content()
                    }
                )
        }
    }
}

@Composable
private fun ButtonComponent.findButtonName(): String? {
    return when(content) {
        is TextComponent -> (content as TextComponent).value?.value()
        else -> analyticsButtonName?.let { LocalEnvStore.current.injectVariables(it) }
    }
}