package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.RadioButtonChecked
import androidx.compose.material.icons.filled.RadioButtonUnchecked
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.properties.ComponentPosition
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.inputs.ComponentPositionListInputSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.styles.ThemeMargins


@Composable
fun ComponentPositionInput(
    spec: ComponentPositionListInputSpec,
    forNode: ComponentBuilder,
    save: () -> Unit
) {

    val position = spec.value.find { it.positionOf == forNode._nodeRelativeId.value } ?: ComponentPosition(positionOf = forNode._nodeRelativeId.value ?: "", x = 0f, y = 0f)

    Text(
        "Position",
        style = MaterialTheme.typography.titleLarge
    )
    Text(
        "Set values in range of 0-100",
        style = MaterialTheme.typography.bodySmall
    )

    Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.spacedBy(ThemeMargins.SmallMargin)) {

        AlignmentGrid(
            xAlign = position.xAlign,
            yAlign = position.yAlign
        ) { x, y ->
            spec.value.remove(position)
            spec.value.add(position.copy(xAlign = x, yAlign = y))
            save()
        }


        var xValue by remember { mutableStateOf(((position.x ?: 0f) * 100f).toInt().toString()) }
        CreatorTextInput(
            modifier = Modifier.width(80.dp),
            label = "X",
            value = xValue,
            onValueChange = {
                xValue = it
                it.toIntOrNull()?.let { percent ->
                    spec.value.remove(position)
                    spec.value.add(position.copy(x = percent.toFloat() / 100f))
                    save()
                }
            }
        )
        var yValue by remember { mutableStateOf(((position.y ?: 0f) * 100f).toInt().toString()) }
        CreatorTextInput(
            modifier = Modifier.width(80.dp),
            label = "Y",
            value = yValue,
            onValueChange = {
                yValue = it
                it.toIntOrNull()?.let { percent -> spec.value.remove(position)
                    spec.value.add(position.copy(y = percent.toFloat() / 100f))
                    save()
                }
            }
        )
    }
    Spacer(Modifier.height(ThemeMargins.MedMargins))


}

@Composable
private fun AlignmentGrid(
    xAlign: ComponentPosition.XAlign,
    yAlign: ComponentPosition.YAlign,
    update: (ComponentPosition.XAlign,  ComponentPosition.YAlign) -> Unit
) {

    Column {
        ComponentPosition.YAlign.entries.forEach { y ->
            Row {
                ComponentPosition.XAlign.entries.forEach { x ->
                    IconButton(
                        modifier = Modifier.size(16.dp),
                        onClick = {
                            update(x, y)
                        }) {
                        Icon(
                            modifier = Modifier.size(10.dp),
                            imageVector = if(xAlign == x && yAlign == y) Icons.Default.RadioButtonChecked else Icons.Default.RadioButtonUnchecked,
                            contentDescription = null
                        )
                    }

                }
            }
        }
    }

}