package com.appcreator.client.contentful.richtext

import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.TextLayoutResult
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.BaselineShift
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.style.TextIndent
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp


private const val LINK = "URL"
private const val ENTRY = "ENTRY"

private val lineHeight = 20.sp

@Composable
internal fun TextItem(
    node: RichNode,
    padding: Dp = 16.dp,
    context: Context,
    openLink: (String) -> Unit,
    openEntry: (String) -> Unit
) {
    val currentStyle = context.typography.bodyMedium
    val annotatedString = remember {
        val builder = AnnotatedString.Builder()
        builder.pushStyle(currentStyle.toSpanStyle())
        append(node, context, builder)
        builder.toAnnotatedString()
    }

    if (annotatedString.isEmpty()) {
        Spacer(Modifier.height(36.dp))
    } else {

        val onClick: (Int) -> Unit = {
            annotatedString.getStringAnnotations(LINK, it, it).firstOrNull()
                ?.let { stringAnnotation ->
                    openLink(stringAnnotation.item)
                }
            annotatedString.getStringAnnotations(ENTRY, it, it).firstOrNull()
                ?.let { stringAnnotation ->
                    openEntry(stringAnnotation.item)
                }
        }

        val layoutResult = remember { mutableStateOf<TextLayoutResult?>(null) }
        val gesture = Modifier.pointerInput(onClick) {
            detectTapGestures(onTap = { pos ->
                layoutResult.value?.let { layout ->
                    val layoutPos = layout.getOffsetForPosition(pos)
                    onClick(layoutPos)
                }
            })
        }

        Text(
            modifier = Modifier.padding(bottom = padding).fillMaxWidth().then(gesture),
            text = annotatedString,
            color = MaterialTheme.colorScheme.onSurface,
            onTextLayout = {
                layoutResult.value = it
            }
        )
    }
}

private fun append(node: RichNode, context: Context, builder: AnnotatedString.Builder) {
    when (node) {
        is RichNode.Document -> {
            node.content.forEach { append(it, context, builder) }
        }
        is HeadingBlock -> header(node, context, builder)
        is RichNode.HyperLink -> hyperLink(node, context, builder)
        is RichNode.EntryHyperLink -> entryLink(node, context, builder)
        is RichNode.ListItem -> listItem(node, context, builder)
        is RichNode.Paragraph -> paragraph(node, context, builder)
        is RichNode.Quote -> quote(node, context, builder)
        is RichNode.Text -> text(node, builder)
        is RichNode.OrderedList -> {
            node.content.forEach { append(it, context, builder) }
        }
        is RichNode.UnorderedList -> {
            node.content.forEach { append(it, context, builder) }
        }
        is RichBlock -> {
            node.content.forEach { append(it, context, builder) }
        }
        else -> {}
    }
}

private fun paragraph(
    node: RichNode.Paragraph,
    context: Context,
    builder: AnnotatedString.Builder
) {
    inheritedFormat(
        context = context,
        defaultFormat = ParagraphStyle(
            lineHeight = lineHeight
        ),
        builder = builder
    ) {
        node.content.forEach { append(it, context, builder) }
    }
}

private fun inheritedFormat(
    context: Context,
    builder: AnnotatedString.Builder,
    defaultFormat: ParagraphStyle? = null,
    build: (Context) -> Unit
) {

    when (context.inheritedFormat) {
        is InheritedFormat.None -> {
            defaultFormat?.let { builder.pushStyle(defaultFormat) }
            build(context)
            defaultFormat?.let { builder.pop() }
            return
        }
        is InheritedFormat.Quote -> {
            builder.pushStyle(
                ParagraphStyle(
                    textIndent = TextIndent(
                        firstLine = 16.sp,
                        restLine = 16.sp
                    ),
                    lineHeight = lineHeight
                )
            )
        }
    }
    build(context.copy(inheritedFormat = InheritedFormat.None))
    builder.pop()
}

private fun text(node: RichNode.Text, builder: AnnotatedString.Builder) {
    val styles = node.marks.mapNotNull {
        when (it) {
            is RichMark.Bold -> SpanStyle(fontWeight = FontWeight.Bold)
            is RichMark.Italic -> SpanStyle(fontStyle = FontStyle.Italic)
            is RichMark.Underline -> SpanStyle(textDecoration = TextDecoration.Underline)
            is RichMark.Code -> null //
            is RichMark.Subscript -> SpanStyle(baselineShift = BaselineShift.Subscript)
            is RichMark.Superscript -> SpanStyle(baselineShift = BaselineShift.Superscript)
            is RichMark.strikethrough -> SpanStyle(textDecoration = TextDecoration.LineThrough)
        }
    }
    styles.forEach { builder.pushStyle(it) }
    builder.append(node.value)
    styles.forEach { _ -> builder.pop() }
}

private fun header(node: HeadingBlock, context: Context, builder: AnnotatedString.Builder) {
    val style = when (node.level) {
        HeadingLevel.H1 -> context.typography.headlineLarge
        HeadingLevel.H2 -> context.typography.headlineMedium
        HeadingLevel.H3 -> context.typography.headlineSmall
        HeadingLevel.H4 -> context.typography.titleLarge
        HeadingLevel.H5 -> context.typography.titleMedium
        HeadingLevel.H6 -> context.typography.titleSmall
    }
    inheritedFormat(
        context = context,
        builder = builder
    ) {
        builder.pushStyle(style.toSpanStyle())
        node.content.forEach { append(it, context, builder) }
        builder.pop()
    }
}

private fun hyperLink(node: RichNode.HyperLink, context: Context, builder: AnnotatedString.Builder) {
    builder.pushStyle(SpanStyle(color = context.linkColor))
    val start = builder.length
    node.content.forEach { append(it, context, builder) }
    val end = builder.length

    builder.addStringAnnotation(
        tag = LINK,
        annotation = node.data.uri,
        start = start,
        end = end
    )

    builder.pop()
}

private fun entryLink(node: RichNode.EntryHyperLink, context: Context, builder: AnnotatedString.Builder) {
    builder.pushStyle(SpanStyle(color = context.linkColor))
    val start = builder.length
    node.content.forEach { append(it, context, builder) }
    val end = builder.length

    builder.addStringAnnotation(
        tag = ENTRY,
        annotation = node.data.target.sys.id,
        start = start,
        end = end
    )

    builder.pop()
}

private fun listItem(node: RichNode.ListItem, context: Context, builder: AnnotatedString.Builder) {
    node.content.forEach { append(it, context, builder) }
}


private fun quote(node: RichNode.Quote, context: Context, builder: AnnotatedString.Builder) {
    node.content.forEach { append(it, context, builder) }
}
