package com.appcreator.blueprint.dataspecs

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.DataSpecBuilder
import com.appcreator.blueprint.spec.DataSpecSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class RichTextDataSpecBuilder(
  dataSpec: RichTextDataSpec?,
  newId: Boolean = false,
) : DataSpecBuilder {
  override val spec: DataSpecSpec = DataSpecSpec(
          name = "RichTextDataSpec",
          title = "RichText",
          availableFrom = "0.0.1",
      )

  public val key: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Key",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(dataSpec?.key?: ""),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(key)

  override fun build(): DataSpec = RichTextDataSpec(key.value?: "")
}
