package com.appcreator.blueprint.conditions.device

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ConditionBuilder
import com.appcreator.blueprint.spec.ConditionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class IsMobileDeviceConditionBuilder(
  condition: IsMobileDeviceCondition?,
  newId: Boolean = false,
) : ConditionBuilder {
  override val spec: ConditionSpec = ConditionSpec(
          name = "IsMobileDeviceCondition",
          title = "Is Mobile Device",
          group = "Other",
          isExtraPlugin = false,
          description = "Detects if the app is running on a mobile phone, only useful to web apps",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
      )

  override val inputSpecs: List<InputSpec> = listOf()

  override fun build(): Condition = IsMobileDeviceCondition()
}
