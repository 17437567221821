package com.appcreator.blueprint.components.forms

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("LocalState")
@ComponentClass(
    group = "Forms",
    title = "Local State",
    availableFrom = "0.0.1"
)
data class LocalStateComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Data Items",
        description = "Data items can be a single value or a list of items, these items will be accessible within the content and are read/write",
        availableFrom = "0.0.1",
    )
    val items: List<DataSpec>?,

    @InputProperty(
        title = "Component",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val content: Component?,

    @InputProperty(
        section = "Advanced",
        title = "Remember State",
        description = "Should values be remembered when exiting and reloading screen or restarting app",
        availableFrom = "0.0.1"
    )
    val remember: Boolean?,

    @InputProperty(
        section = "Advanced",
        title = "Remember Key",
        description = "Key to store values with, allow resorting the same state in different places, leave blank if this is not required",
        availableFrom = "0.0.1"
    )
    val rememberKey: String?

): Component
