package com.appcreator.blueprint.actions

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.ActionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class TriggerActionBuilder(
  action: TriggerAction?,
  newId: Boolean = false,
) : ActionBuilder {
  override val spec: ActionSpec = ActionSpec(
          name = "TriggerAction",
          title = "Trigger",
          group = "Other",
          isExtraPlugin = false,
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
      )

  public val triggerId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Trigger Id",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(action?.triggerId),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(triggerId)

  override fun build(): Action = TriggerAction(triggerId.value)
}
