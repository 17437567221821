package com.appcreator.local

import androidx.compose.runtime.staticCompositionLocalOf
import com.appcreator.dto.Organisation
import com.appcreator.dto.Project

val LocalOrganisation = staticCompositionLocalOf<Organisation> {
    error("No instance of Organisation provided")
}

val LocalProject = staticCompositionLocalOf<Project> {
    error("No instance of Project provided")
}