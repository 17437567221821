package com.appcreator.blueprint

import com.appcreator.blueprint.core.urlDecode

// Note: Queries are not handled

fun Blueprint.findDestination(link: String): Destination? {

    return screens.find { it.path.matchesDeeplink(link) }?.let {
        val params = it.path.toParams(link)
        Destination(
            screen = it,
            properties = params.arguments
        )
    }
}

private fun String.matchesDeeplink(link: String): Boolean {
    val matchers = toPathComponents()
        .map { PathMather(it) }.toMutableList()
    val paths = link.toPathComponents()
        .map { it.urlDecode() }
        .toMutableList() // TODO ignore schema and queries
    return matchers.removeFirst().matches(paths, matchers)
}


fun String.linkParameter(): List<String> =
    toPathComponents()
        .filter { it.isParameterName() }
        .map { it.parameterName() }

private fun String.toPathComponents(): List<String> {
    return split("/").filter { it.isNotEmpty() }
}

private fun String.isParameterName(): Boolean {
    return first() == '{' && last() == '}'
}

private fun String.parameterName(): String {
    return trimStart('{').trimEnd('}')
}

private data class LinkParams(
    val link: String,
    val arguments: Map<String, String>
)

private fun String.toParams(link: String): LinkParams {
    val arguments = mutableMapOf<String, String>()
    val paths = link.toPathComponents()
    toPathComponents()
        .forEachIndexed { index, value ->
            if(value.isParameterName()) {
                arguments[value.parameterName()] = paths[index].urlDecode()
            }
        }
    return LinkParams(
        link = link,
        arguments = arguments
    )
}

private class PathMather(
    val path: String
) {
    fun matches(remainingPaths: MutableList<String>, remainingMatchers: MutableList<PathMather>): Boolean {
        if (path == "*") return true
        if (remainingPaths.isEmpty()) return false

        val thisPath = remainingPaths.removeFirst()
        val thisMatches = (path.isParameterName()) || path == thisPath
        if (!thisMatches) return false

        if(remainingPaths.isEmpty() && remainingMatchers.isEmpty()) return true
        if(remainingPaths.isEmpty() || remainingMatchers.isEmpty()) return false

        return remainingMatchers.removeFirst().matches(remainingPaths, remainingMatchers)
    }
}