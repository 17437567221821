package com.appcreator.blueprint.contentful

import com.appcreator.blueprint.contentful.ContentfulRichTextComponent.Keys.externalLink
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.ExtraDataOptions
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.spec.inputs.DataSpecType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
@SerialName("ContentfulRichText")
@ComponentClass(
    group = ContentfulConsts.group,
    title = "Rich Text",
    isExtraPlugin = true,
    availableFrom = "0.0.1"
)
data class ContentfulRichTextComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Content",
        dataOptions = DataSpecType.RichText,
        availableFrom = "0.0.1"
    )
    val richText: DataPath?,

    @InputProperty(
        title = "External Link Click",
        dataOptions = DataSpecType.RichText,
        availableFrom = "0.0.1"
    )
    @ExtraDataOptions(externalLink)
    val onExternalLinkClick: Action?,

    @InputProperty(
        title = "Entry Link Click",
        dataOptions = DataSpecType.RichText,
        availableFrom = "0.0.1"
    )
    @ExtraDataOptions(ContentfulLoaderSpec.Keys.entryTitle)
    @ExtraDataOptions(ContentfulLoaderSpec.Keys.contentType)
    @ExtraDataOptions(ContentfulLoaderSpec.Keys.entryId)
    val onEntryLinkClick: Action?,

): Component {

    object Keys {
        const val externalLink = "externalLink"
    }
}

