package com.appcreator.dto.configurations

import kotlinx.serialization.Serializable


@Serializable
data class AndroidConfigurationResponse(
    val packageId: String?,
    val sha1: String?,
    val sha256: String?
)

fun AndroidConfigurationResponse.isValid(): Boolean =
    !packageId.isNullOrBlank()