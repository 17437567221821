package com.appcreator.dto.configurations

import kotlinx.serialization.Serializable

@Serializable
data class ProjectAdminConfiguration(
    val isTemplate: Boolean,
    val templateConfiguration: ProjectTemplate?,
    val contentfulSpecJson: String?,
)

@Serializable
data class ProjectTemplate(
    val enabled: Boolean,
    val id: String,
    val group: String,
    val name: String,
    val description: String,
    val image: String,

    // This is not scalable but problem for the future
    val requiresContentful: Boolean?,
    val requiresFirebase: Boolean?,
    val requiresFirestore: Boolean?,
    val firestoreConfig: FirestoreConfig?,
    val requiresFirebaseAuth: Boolean?
)

@Serializable
data class FirestoreConfig(
    val security: String,
    val indexes: List<FirestoreIndex>
)

@Serializable
data class FirestoreIndex(
    val image: String,
)
