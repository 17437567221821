package com.appcreator.blueprint.components.basic

import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.properties.FontReference
import com.appcreator.blueprint.core.properties.LocalizableString
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Text")
@ComponentClass(
    group = "Basic",
    title = "Text",
    availableFrom = "0.0.1"
)
data class TextComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Value",
        availableFrom = "0.0.1"
    )
    @GenericDefault("com.appcreator.blueprint.core.properties.LocalizableString(values = mapOf(\"gb\" to \"Text\"))")
    val value: LocalizableString?,

    @InputProperty(
        title = "Markdown",
        availableFrom = "0.0.1"
    )
    val markdown: Boolean?,

    @InputProperty(
        section = "Configuration",
        title = "Font",
        availableFrom = "0.0.1"
    )
    val font: FontReference?,
    @InputProperty(
        section = "Configuration",
        title = "Text Color",
        availableFrom = "0.0.1"
    )
    val textColor: ColorReference?,
    @InputProperty(
        section = "Configuration",
        title = "Alignment",
        availableFrom = "0.0.1"
    )
    @GenericDefault("TextComponent.Alignment.Start")
    val alignment: Alignment?,

    @InputProperty(
        section = "Configuration",
        title = "Redacted",
        description = "Hide text, useful for creating placeholder screens while content is loading",
        availableFrom = "0.0.1"
    )
    @GenericDefault("false")
    val redacted: Boolean?

): Component {

    enum class Alignment {
        Start,
        Center,
        End
    }

}
