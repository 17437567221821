package com.appcreator.compose.components.basic

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.outlined.Language
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.basic.WebViewComponent
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.properties.JsCallback
import com.appcreator.compose.HtmlView
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventListener

private class JsCallbackHandler(
    private val scope: CoroutineScope,
    private val deferred: Performer.Deferred?,
    private val envStore: EnvStore
) {

    fun handle(
        params: Map<String, String>?,
    ) {
        scope.launch {
            val updated = params?.let {
                EnvStore.create(envStore, it)
            }?: envStore

            deferred?.perform(updated)
        }
    }

    companion object {
        @Composable
        fun create(jsCallback: JsCallback): JsCallbackHandler {
            return JsCallbackHandler(
                scope = rememberCoroutineScope(),
                deferred = jsCallback.action?.let { Container.performer(it)?.deferred() },
                envStore = LocalEnvStore.current
            )
        }
    }
}

@Composable
actual fun WebViewComposable(
    modifier: Modifier,
    component: WebViewComponent
) {

    if (LocalInPreview.current) {
        Column(Modifier.fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            Icon(Icons.Outlined.Language, contentDescription = null, modifier = Modifier.size(44.dp))
            component.url?.let {
                Text(
                    textAlign = TextAlign.Center,
                    modifier = Modifier.padding(24.dp).widthIn(max = 400.dp),
                    text = it,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis
                )
            }
            Text(
                textAlign = TextAlign.Center,
                modifier = Modifier.padding(horizontal = 24.dp).widthIn(max = 400.dp),
                text = "WebView - use preview to load content"
            )
            Text(
                textAlign = TextAlign.Center,
                style = MaterialTheme.typography.labelLarge,
                modifier = Modifier.padding(horizontal = 24.dp).widthIn(max = 400.dp),
                text = "Some content will not show in an iFrame but will load fine on Android and iOS, if you are having issues test on a real device"
            )
        }
    } else {
        val handlers = component.callbacks?.associate { it.name to JsCallbackHandler.create(it) }

        DisposableEffect(Unit) {
            val listener = AppCreatorBridge(handlers?: emptyMap())
            window.addEventListener("message", listener)
            onDispose {
                window.removeEventListener("message", listener)
            }
        }

        val envStore = LocalEnvStore.current
        component.url?.let {
            HtmlView(
                key = it,
                modifier = Modifier.fillMaxSize(),
                factory = {
                    val iframe = window.document.createElement("iframe")
                    iframe.setAttribute("src", envStore.injectVariables(it))
                    iframe
            })
        }
    }
}

@Serializable
private data class EventMessage(
    val name: String,
    val properties: Map<String, String>?
)

private class AppCreatorBridge(
    private val handlers: Map<String, JsCallbackHandler>
): EventListener {
    override fun handleEvent(event: Event) {
        try {
            val message = event.asDynamic().data as String
            val eventMessage: EventMessage = Json.decodeFromString(message)
            handlers[eventMessage.name]?.handle(eventMessage.properties)
        } catch (ex: Exception) {
            ex.printStackTrace()
        }
    }
}
