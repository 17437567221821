package com.appcreator.blueprint.dataspecs

import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.DataSpecClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.StringDefault
import com.appcreator.blueprint.spec.inputs.DataSpecType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Conversion")
@DataSpecClass(
    title = "Conversion",
    availableFrom = "0.0.1",
)
data class ConversionDataSpec(
    @InputProperty(
        title = "Key",
        availableFrom = "0.0.1"
    )
    @StringDefault("")
    override val key: String,

    @InputProperty(
        title = "Preview Value",
        availableFrom = "0.0.1"
    )
    val previewValue: String?,

    @InputProperty(
        title = "Conversion",
        availableFrom = "0.0.1",
    )
    val conversion: Conversion?,

    override val specType: String = DataSpecType.Value

): DataSpec {
    override fun copySpec(key: String): DataSpec = copy(key = key)
}