package com.appcreator.blueprint.conditions.device

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("IsMobileDeviceCondition")
@ConditionClass(
    group = "Other",
    title = "Is Mobile Device",
    description = "Detects if the app is running on a mobile phone, only useful to web apps",
    availableFrom = "0.0.1"
)
class IsMobileDeviceCondition: Condition