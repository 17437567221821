package com.appcreator.creatorapp.iconcreator


import androidx.compose.runtime.Composable

enum class BackgroundType {
    Color,
    Image
}

// This could likely be done in common but no real need to get android working
@Composable
expect fun IconCreatorScreen(
    orgId: String,
    projectId: String,
    hasSaved: () -> Unit
)