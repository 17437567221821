package com.appcreator.creatorapp

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.compose.components.data.DefaultErrorComposable
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.shared.api.ApiClient
import com.appcreator.styles.ThemeMargins
import io.ktor.client.plugins.ClientRequestException
import io.ktor.http.HttpStatusCode
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

sealed class LoadingState<T> {
    class PreLoading<T>: LoadingState<T>()
    class Loading<T>: LoadingState<T>()
    data class Error<T>(val error: Throwable): LoadingState<T>()
    data class Loaded<T>(val data: T): LoadingState<T>()
}

@Composable
fun <T> LoadingComposable(
    key: Any? = Unit,
    loader: suspend (ApiClient) -> T,
    notFound: (() -> Unit)? = null,
    content: @Composable (T) -> Unit
) {
    val apiClient = LocalApiClient.current
    RawLoadingComposable(
        key = key,
        loader = { loader(apiClient) },
        notFound = notFound,
        content = content
    )
}

@Composable
fun <T> RawLoadingComposable(key: Any? = Unit, loader: suspend () -> T, notFound: (() -> Unit)? = null, content: @Composable (T) -> Unit) {
    var state by remember { mutableStateOf<LoadingState<T>>(LoadingState.PreLoading()) }
    LaunchedEffect(key) {
        val job = launch {
            delay(300)
            state = LoadingState.Loading()
        }
        state = try {
            LoadingState.Loaded(loader()).also {
                job.cancel()
            }
        } catch (ex: Throwable) {
            ex.printStackTrace()
            LoadingState.Error<T>(ex).also {
                job.cancel()
                if (ex is ClientRequestException && ex.response.status == HttpStatusCode.NotFound) {
                    notFound?.invoke()
                }
            }
        }
    }

    val scope = rememberCoroutineScope()

    when(state) {
        is LoadingState.Loading -> Box(Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
            CircularProgressIndicator()
        }
        is LoadingState.Error -> DefaultErrorComposable(
            modifier = Modifier.padding(ThemeMargins.LargeMargins),
            throwable = (state as LoadingState.Error<T>).error,
            retry = {
                scope.launch {
                    state = LoadingState.Loading()
                    state = try {
                        LoadingState.Loaded(loader())
                    } catch (ex: Throwable) {
                        LoadingState.Error(ex)
                    }
                }
            }
        )
        is LoadingState.Loaded -> content((state as LoadingState.Loaded<T>).data)
        else -> {}
    }
}
