package com.appcreator.creatorapp.editor.paneldevicepreview

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.appcreator.app.utils.click
import com.appcreator.app.utils.onDrag
import com.appcreator.blueprint.Blueprint
import com.appcreator.blueprint.Destination
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.compose.AbsoluteSize
import com.appcreator.compose.LocalAbsoluteSize
import com.appcreator.compose.LocalDrawOnRoot
import com.appcreator.compose.LocalVerticalScrollingParent
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.components.navigation.Navigation
import com.appcreator.compose.components.navigation.rememberNavigator
import com.appcreator.compose.theme.Theme
import com.appcreator.creatorapp.editor.DragCompleteInfo
import com.appcreator.creatorapp.editor.DragItem
import com.appcreator.creatorapp.editor.HoverAndDragManager

@Composable
fun ComposeAppPreview(
    blueprint: Blueprint,
    destination: State<Destination>,
    dragManager: HoverAndDragManager,
    selectNode: (Component) -> Unit,
    moveNode: (Component, DragCompleteInfo?) -> Unit
) {

    val dragNode = remember { mutableStateOf<Component?>(null) }
    Box(modifier = Modifier.background(Color.White)
        .click {
            dragManager.hoveredPreviewNode?.let(selectNode)
        }
        .onDrag {
            if (it) {
                dragManager.hoveredPreviewNode?.let { node ->
                    dragNode.value = node
                    dragManager.startDrag(
                        DragItem(node.toBuilder(false)) {
                            BoxWithConstraints(
                                Modifier.sizeIn(
                                    maxWidth = 340.dp,
                                    maxHeight = 400.dp
                                )
                            ) {
                                CompositionLocalProvider(
                                    LocalAbsoluteSize provides AbsoluteSize(this),
                                    LocalVerticalScrollingParent provides true
                                ) {
                                    ComponentComposable(Modifier, node)
                                }

                            }
                        }
                    )
                }
            } else {
                dragManager.completeDrag()?.let { info ->
                    dragNode.value?.let { node ->
                        moveNode(node, info)
                    }
                }
                dragNode.value = null
            }
        }
    ) {
        val drawOnRoot by LocalDrawOnRoot.current

        AnimatedContent(destination.value) {
            Box {
                blueprint.Theme {
                    val navigator = rememberNavigator(
                        false,
                        it.screen.path,
                        parent = null
                    )
                    Navigation(Modifier, navigator)
                    drawOnRoot?.let {
                        Box(Modifier.fillMaxSize().background(Color.Black.copy(alpha = 0.6f))) {
                            it()
                        }
                    }
                }
            }
        }
    }
}
