package com.appcreator.blueprint.conditions

import com.appcreator.blueprint.conditions.JavascriptConditionBuilder
import com.appcreator.blueprint.conditions.`data`.ContainsConditionBuilder
import com.appcreator.blueprint.conditions.`data`.ContainsItemConditionBuilder
import com.appcreator.blueprint.conditions.`data`.DateConditionBuilder
import com.appcreator.blueprint.conditions.`data`.EqualsConditionBuilder
import com.appcreator.blueprint.conditions.`data`.NumberCompareConditionBuilder
import com.appcreator.blueprint.conditions.device.IsMobileDeviceConditionBuilder
import com.appcreator.blueprint.conditions.logic.AndConditionBuilder
import com.appcreator.blueprint.conditions.logic.NotConditionBuilder
import com.appcreator.blueprint.conditions.logic.OrConditionBuilder
import com.appcreator.blueprint.conditions.permission.CheckPermissionConditionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoggedInConditionBuilder
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigConditionBuilder
import com.appcreator.blueprint.revenuecat.RevenuecatHasEntitlementConditionBuilder
import com.appcreator.blueprint.spec.ConditionBuilderProvider
import com.appcreator.blueprint.spec.ConditionSpec
import kotlin.collections.Map

public fun conditionOptions(): Map<ConditionSpec, ConditionBuilderProvider> = mapOf(
        ConditionSpec(
    name = "JavascriptCondition",
    title = "Custom Code",
    group = "Other",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ConditionBuilderProvider { JavascriptConditionBuilder(null) },
        ConditionSpec(
    name = "ContainsCondition",
    title = "Contains",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ConditionBuilderProvider { ContainsConditionBuilder(null) },
        ConditionSpec(
    name = "ContainsItemCondition",
    title = "List Contains Item",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ConditionBuilderProvider { ContainsItemConditionBuilder(null) },
        ConditionSpec(
    name = "DateCondition",
    title = "Compare Dates",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ConditionBuilderProvider { DateConditionBuilder(null) },
        ConditionSpec(
    name = "EqualsCondition",
    title = "Equals",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ConditionBuilderProvider { EqualsConditionBuilder(null) },
        ConditionSpec(
    name = "NumberCompareCondition",
    title = "Compare Numbers",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ConditionBuilderProvider { NumberCompareConditionBuilder(null) },
        ConditionSpec(
    name = "IsMobileDeviceCondition",
    title = "Is Mobile Device",
    group = "Other",
    isExtraPlugin = false,
    description = "Detects if the app is running on a mobile phone, only useful to web apps",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ConditionBuilderProvider { IsMobileDeviceConditionBuilder(null) },
        ConditionSpec(
    name = "AndCondition",
    title = "And",
    group = "Logic",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = true,
) to ConditionBuilderProvider { AndConditionBuilder(null) },
        ConditionSpec(
    name = "NotCondition",
    title = "Not",
    group = "Logic",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = true,
) to ConditionBuilderProvider { NotConditionBuilder(null) },
        ConditionSpec(
    name = "OrCondition",
    title = "Or",
    group = "Logic",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = true,
) to ConditionBuilderProvider { OrConditionBuilder(null) },
        ConditionSpec(
    name = "CheckPermissionCondition",
    title = "Check Permission",
    group = "Other",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ConditionBuilderProvider { CheckPermissionConditionBuilder(null) },
        ConditionSpec(
    name = "FirebaseAuthLoggedInCondition",
    title = "Is logged in",
    group = "Firebase Auth",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ConditionBuilderProvider { FirebaseAuthLoggedInConditionBuilder(null) },
        ConditionSpec(
    name = "FirebaseRemoteConfigCondition",
    title = "Firebase Remote Config",
    group = "Firebase Remote Config",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ConditionBuilderProvider { FirebaseRemoteConfigConditionBuilder(null) },
        ConditionSpec(
    name = "RevenuecatHasEntitlementCondition",
    title = "Has Entitlement",
    group = "Revenue Cat",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ConditionBuilderProvider { RevenuecatHasEntitlementConditionBuilder(null) }
    )
