package com.appcreator.creatorapp.editor.centerdata.contentful

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Info
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.PlainTooltip
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.TooltipBox
import androidx.compose.material3.TooltipDefaults
import androidx.compose.material3.rememberTooltipState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.contentful.ContentfulLoaderSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreLoaderSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.api.MutableDataSource
import com.appcreator.styles.ThemeMargins


@Composable
fun ContentfulRequest(
    data: MutableDataSource,
    spec: ContentfulLoaderSpec,
    save: () -> Unit
) {

    val items = remember { mutableStateListOf(*(spec.dataValues ?: emptyList()).toTypedArray()) }
    items.forEachIndexed { index, value ->
        Row(
            modifier = Modifier.widthIn(max = 750.dp),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(ThemeMargins.MedMargins)
        ) {
            CreatorTextInput(
                modifier = Modifier.weight(1f),
                label = "Key",
                value = value.field,
                onValueChange = {
                    items[index] = value.copy(field = it)
                    data.loaderSpec = spec.copy(dataValues = items)
                    save()
                },
                trailingIcon = {
                    IconButton(onClick = {
                        items.remove(value)
                        data.loaderSpec = spec.copy(dataValues = items)
                        save()
                    }) {
                        Icon(
                            Icons.Default.Delete,
                            contentDescription = null
                        )
                    }
                }
            )
            if(value.type != ContentfulLoaderSpec.ContentfulDataType.Entry
                && value.type != ContentfulLoaderSpec.ContentfulDataType.EntryList) {
                CreatorTextInput(
                    modifier = Modifier.weight(1f),
                    label = "Preview Value",
                    value = value.preview ?: "",
                    singleLine = true,
                    onValueChange = {
                        items[index] = value.copy(preview = it)
                        data.loaderSpec = spec.copy(dataValues = items)
                        save()
                    }
                )
            }
            DropDownInputGroup(
                modifier = Modifier.width(180.dp),
                title = "Type",
                dropDownTitle = value.type.name,
                dropDownContent = { close ->
                    ContentfulLoaderSpec.ContentfulDataType.entries.forEach {
                        DropdownItem(title = it.name) {
                            items[index] = value.copy(type = it)
                            data.loaderSpec = spec.copy(dataValues = items)
                            save()
                            close()
                        }
                    }
                }
            )
        }
    }

    TextButton(onClick = {
        items.add(ContentfulLoaderSpec.ContentfulData("", "", ContentfulLoaderSpec.ContentfulDataType.Default))
        data.loaderSpec = spec.copy(dataValues = items)
        save()
    }) {
        Text("Add Value")
    }

}
