package com.appcreator.creatorapp.editor.paneldevicepreview

import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.Component
import com.appcreator.compose.tracingPaperBackground
import com.appcreator.creatorapp.editor.BlueprintManager
import com.appcreator.creatorapp.editor.DragCompleteInfo
import com.appcreator.creatorapp.editor.HoverAndDragManager
import com.appcreator.creatorapp.editor.PreviewSize
import com.appcreator.creatorapp.editor.local.LocalNodeExplorer


@Composable
fun RowScope.DevicePreviewPanel(
    size: PreviewSize,
    navigator: BlueprintManager,
    dragManager: HoverAndDragManager,
    moveNode: (Component, DragCompleteInfo?) -> Unit
) {
    val nodeExplorer = LocalNodeExplorer.current
    Box(Modifier.tracingPaperBackground().fillMaxHeight().weight(1f), contentAlignment = Alignment.Center) {
        Box(size.modifier
            .border(2.dp, color = Color.Black)
            .clip(RectangleShape), contentAlignment = Alignment.Center) {
            ComposeAppPreview(
                blueprint = navigator.blueprint,
                destination = navigator.destinationState,
                dragManager = dragManager,
                selectNode = {
                    nodeExplorer.findNode(it._nodeId, navigator.screenBuilder)?.let { builder ->
                        navigator.selectedComponent.value = builder
                    }
                },
                moveNode
            )
        }
    }
}

