package com.appcreator.compose.actions

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.SequenceAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalInPreview
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer

class SequencePerformer(private val action: SequenceAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        val defers = action.actions.mapNotNull { Container.performer(it)?.deferred() }
        val error = action.errorAction?.let { Container.performer(it)?.deferred() }
        val inPreview = LocalInPreview.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                if (inPreview) return
                try {
                    defers.forEach {
                        it.perform(envStore)
                    }
                } catch (ex: Exception) {
                    ex.printStackTrace()
                    error?.perform(envStore) ?: throw ex
                }
            }
        }
    }
}