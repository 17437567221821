package com.appcreator.creatorapp.editor.panelcomponentpicker

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.Card
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.app.utils.onDrag
import com.appcreator.blueprint.components.componentOptions
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ComponentBuilderProvider
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.editor.DragCompleteInfo
import com.appcreator.creatorapp.editor.DragItem
import com.appcreator.creatorapp.editor.LocalDrag
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.styles.ThemeMargins

@Composable
fun ComponentListPanel(
    add: (ComponentBuilder, DragCompleteInfo) -> Unit
) {

    val options = filteredOptions(emptyList())
    Box(modifier = Modifier.padding(horizontal = ThemeMargins.SmallMargin)) {
        val dragHolder = LocalDrag.current
        ComponentList(
            options = options,
            renderer = { spec, provider ->
                Card(
                    modifier = Modifier
                        .padding(ThemeMargins.SmallMargin)
                        .onDrag {
                            if(it) {
                                dragHolder.startDrag(
                                    DragItem(provider.block()) {
                                        Card(Modifier.size(120.dp)) {
                                            Box(
                                                modifier = Modifier
                                                    .padding(ThemeMargins.SmallMargin)
                                            ) {
                                                Text(spec.title)
                                            }
                                        }
                                    }
                                )
                            } else {
                                dragHolder.completeDrag()?.let { info ->
                                    add(provider.block(), info)
                                }
                            }
                        }
                ) {
                    Box(modifier = Modifier
                        .defaultMinSize(minHeight = 120.dp)
                        .padding(ThemeMargins.SmallMargin)
                    ) {
                        Text(spec.title)
                    }
                }
            }
        )
    }
}



@Composable
fun ComponentList(
    options: Map<ComponentSpec, ComponentBuilderProvider>,
    selected: (ComponentBuilderProvider) -> Unit,
) {

    ComponentList(options) { spec, provider ->
        Card(
            modifier = Modifier.padding(ThemeMargins.SmallMargin),
            onClick = {
                selected(provider)
            }
        ) {
            Box(modifier = Modifier
                .defaultMinSize(minHeight = 120.dp)
                .padding(ThemeMargins.SmallMargin)) {
                Text(spec.title)
            }
        }
    }
}


@Composable
fun ComponentList(
    options: Map<ComponentSpec, ComponentBuilderProvider>,
    renderer: @Composable (ComponentSpec, ComponentBuilderProvider) -> Unit
) {

    var search by remember { mutableStateOf("") }

    val sortedOptions = options
        .filterKeys { search.isEmpty() || it.title.contains(search, ignoreCase = true) }
        .toList()
        .groupBy { it.first.group }

    Column(modifier = Modifier.fillMaxSize()) {

        CreatorTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Search",
            value = search,
            onValueChange = {
                search = it
            },
            leadingIcon = {
                Icon(Icons.Default.Search, contentDescription = null)
            }
        )

        LazyVerticalGrid(
            columns = GridCells.Adaptive(160.dp),
            contentPadding = PaddingValues(vertical = ThemeMargins.MedMargins)
        ) {
            sortedOptions.forEach { (group, items) ->
                item(span = { GridItemSpan(maxLineSpan) }) {
                    Text(group)
                }

                items(items) {
                    renderer(it.first, it.second)
                }
            }
        }
    }
}

@Composable
fun filteredOptions(allowedComponents: List<String>): Map<ComponentSpec, ComponentBuilderProvider> {
    val blueprintManager = LocalBlueprintManager.current
    val accessibleOptions = componentOptions()
    return accessibleOptions.filterKeys {
        (allowedComponents.isEmpty() && it.accessible) || allowedComponents.contains(it.name)
    }.filterKeys {
        blueprintManager.enabledExtras.contains(it.group) || !it.isExtraPlugin
    }
}
