package com.appcreator.creatorapp.organisation

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.components.layouts.Modal
import com.appcreator.compose.HtmlView
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.dto.Organisation
import com.appcreator.local.LocalUserState
import kotlinx.browser.window

@Composable
actual fun OrganisationSelectPlan(organisation: Organisation) {
    val user = LocalUserState.current
    LoadingComposable(loader = {
        it.getPlans()
    }) { data ->

        Box(modifier = Modifier.fillMaxWidth(), contentAlignment = Alignment.Center) {
            data.plans.forEach {
                HtmlView(
                    factory = {
                        val buy = window.document.createElement("stripe-buy-button")
                        buy.setAttribute("client-reference-id", organisation.id)
                        buy.setAttribute("customer-email", user.email ?: "")
                        buy.setAttribute("buy-button-id", it.buttonId)
                        buy.setAttribute("publishable-key", data.publicKey)
                        buy
                    }
                )
            }
        }
    }
}