package com.appcreator.compose.conditions.device

import com.appcreator.blueprint.conditions.device.IsMobileDeviceCondition
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.conditions.Evaluator

class IsMobileDeviceEvaluator(private val condition: IsMobileDeviceCondition): Evaluator {

    override fun evaluateOnce(envStore: EnvStore): Boolean = isMobileDevice()

}

expect fun isMobileDevice(): Boolean