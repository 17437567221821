package com.appcreator.blueprint.loaderspec

import com.appcreator.blueprint.core.DataShape
import com.appcreator.blueprint.core.LoaderSpec
import kotlinx.serialization.Serializable

@Serializable
data class UnsupportedLoaderSpec(
    override val id: String,
    override val parameters: Set<String>,
    override val dataShape: DataShape?,
    override val parameterSettings: Map<String, LoaderSpec.ParameterSetting>?
) : LoaderSpec {
    override val supportsPaging: Boolean
        get() = false
}
