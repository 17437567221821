package com.appcreator.blueprint.components

import com.appcreator.blueprint.components.CustomClientComponentBuilder
import com.appcreator.blueprint.components.CustomComponentBuilder
import com.appcreator.blueprint.components.ScreenBuilder
import com.appcreator.blueprint.components.`data`.CountdownComponentBuilder
import com.appcreator.blueprint.components.`data`.DataConversionComponentBuilder
import com.appcreator.blueprint.components.`data`.DataRepeaterComponentBuilder
import com.appcreator.blueprint.components.`data`.DatePartsComponentBuilder
import com.appcreator.blueprint.components.`data`.LoadingComponentBuilder
import com.appcreator.blueprint.components.`data`.PagedLoaderComponentBuilder
import com.appcreator.blueprint.components.`data`.StringSplitterComponentBuilder
import com.appcreator.blueprint.components.basic.ButtonComponentBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.basic.ImageComponentBuilder
import com.appcreator.blueprint.components.basic.MaterialIconComponentBuilder
import com.appcreator.blueprint.components.basic.TextComponentBuilder
import com.appcreator.blueprint.components.basic.VideoComponentBuilder
import com.appcreator.blueprint.components.basic.WebViewComponentBuilder
import com.appcreator.blueprint.components.forms.DatePickerModalComponentBuilder
import com.appcreator.blueprint.components.forms.InputComponentBuilder
import com.appcreator.blueprint.components.forms.LocalStateComponentBuilder
import com.appcreator.blueprint.components.logic.ABComponentBuilder
import com.appcreator.blueprint.components.logic.ConditionalComponentBuilder
import com.appcreator.blueprint.components.modal.DialogComponentBuilder
import com.appcreator.blueprint.components.modal.SheetComponentBuilder
import com.appcreator.blueprint.components.navigation.NavigationComponentBuilder
import com.appcreator.blueprint.components.navigation.NavigationDrawerComponentBuilder
import com.appcreator.blueprint.components.navigation.NavigationItemComponentBuilder
import com.appcreator.blueprint.components.pager.TabbedPageComponentBuilder
import com.appcreator.blueprint.components.pager.TabbedPagerComponentBuilder
import com.appcreator.blueprint.contentful.ContentfulRichTextComponentBuilder
import com.appcreator.blueprint.spec.ComponentBuilderProvider
import com.appcreator.blueprint.spec.ComponentSpec
import kotlin.collections.Map

public fun componentOptions(): Map<ComponentSpec, ComponentBuilderProvider> = mapOf(
        ComponentSpec(
    group = "Other",
    name = "CustomComponent",
    title = "Custom",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { CustomComponentBuilder(null) },
        ComponentSpec(
    group = "Other",
    name = "CustomClientComponent",
    title = "Custom",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { CustomClientComponentBuilder(null) },
        ComponentSpec(
    group = "",
    name = "Screen",
    title = "Screen",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = false,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ScreenBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "ButtonComponent",
    title = "Button",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ButtonComponentBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "ContainerComponent",
    title = "Container",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ContainerComponentBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "ImageComponent",
    title = "Image",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ImageComponentBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "MaterialIconComponent",
    title = "Icon",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { MaterialIconComponentBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "TextComponent",
    title = "Text",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { TextComponentBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "VideoComponent",
    title = "Video",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { VideoComponentBuilder(null) },
        ComponentSpec(
    group = "Basic",
    name = "WebViewComponent",
    title = "Web View",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { WebViewComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "CountdownComponent",
    title = "Countdown Timer",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf("countdown.years",
    "countdown.months",
    "countdown.days",
    "countdown.hours",
    "countdown.minutes",
    "countdown.seconds"),
    producedFieldsEditable = false
) to ComponentBuilderProvider { CountdownComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "DataConversionComponent",
    title = "Data Transformer",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { DataConversionComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "DataRepeaterComponent",
    title = "Repeating Item",
    isExtraPlugin = false,
    description = "Repeats child component base on data",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf("index"),
    producedFieldsEditable = false
) to ComponentBuilderProvider { DataRepeaterComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "DatePartsComponent",
    title = "Date Elements",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf("date-parts.year",
    "date-parts.month-number",
    "date-parts.month-name",
    "date-parts.month-name-short",
    "date-parts.day-number",
    "date-parts.day-name",
    "date-parts.day-name-short",
    "date-parts.hour-24",
    "date-parts.minute",
    "date-parts.second"),
    producedFieldsEditable = false
) to ComponentBuilderProvider { DatePartsComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "LoadingComponent",
    title = "Data Loader",
    isExtraPlugin = false,
    description = "Loads data",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { LoadingComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "PagedLoaderComponent",
    title = "Paging Data Loader",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { PagedLoaderComponentBuilder(null) },
        ComponentSpec(
    group = "Data",
    name = "StringSplitterComponent",
    title = "String Split",
    isExtraPlugin = false,
    description = "Splits string the repeats over items",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf("index","split-value"),
    producedFieldsEditable = false
) to ComponentBuilderProvider { StringSplitterComponentBuilder(null) },
        ComponentSpec(
    group = "Forms",
    name = "DatePickerModalComponent",
    title = "Date Picker",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { DatePickerModalComponentBuilder(null) },
        ComponentSpec(
    group = "Forms",
    name = "InputComponent",
    title = "Text Input",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { InputComponentBuilder(null) },
        ComponentSpec(
    group = "Forms",
    name = "LocalStateComponent",
    title = "Local State",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { LocalStateComponentBuilder(null) },
        ComponentSpec(
    group = "Logic",
    name = "ABComponent",
    title = "Conditional Switch",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ABComponentBuilder(null) },
        ComponentSpec(
    group = "Logic",
    name = "ConditionalComponent",
    title = "Conditional",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ConditionalComponentBuilder(null) },
        ComponentSpec(
    group = "Modal",
    name = "DialogComponent",
    title = "Dialog",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { DialogComponentBuilder(null) },
        ComponentSpec(
    group = "Modal",
    name = "SheetComponent",
    title = "Sheet",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { SheetComponentBuilder(null) },
        ComponentSpec(
    group = "Navigation",
    name = "NavigationComponent",
    title = "Navigation",
    isExtraPlugin = false,
    description = "Add screens to navigate between",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { NavigationComponentBuilder(null) },
        ComponentSpec(
    group = "Navigation",
    name = "NavigationDrawerComponent",
    title = "Navigation Drawer",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = true,
    producesDataFields = listOf("drawer-state"),
    producedFieldsEditable = true
) to ComponentBuilderProvider { NavigationDrawerComponentBuilder(null) },
        ComponentSpec(
    group = "Navigation",
    name = "NavigationItemComponent",
    title = "Tab Item",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = false,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { NavigationItemComponentBuilder(null) },
        ComponentSpec(
    group = "Pager",
    name = "TabbedPageComponent",
    title = "Tabbed Page",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { TabbedPageComponentBuilder(null) },
        ComponentSpec(
    group = "Pager",
    name = "TabbedPagerComponent",
    title = "Tabbed Pager",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf("pager-selected-page"),
    producedFieldsEditable = true
) to ComponentBuilderProvider { TabbedPagerComponentBuilder(null) },
        ComponentSpec(
    group = "Contentful",
    name = "ContentfulRichTextComponent",
    title = "Rich Text",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
    producesDataFields = listOf(),
    producedFieldsEditable = false
) to ComponentBuilderProvider { ContentfulRichTextComponentBuilder(null) }
    )
