package com.appcreator.compose.components.navigation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.staticCompositionLocalOf
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.core.properties.BlueprintLink
import com.appcreator.compose.KotlinXNavigator
import com.appcreator.compose.KotlinXNavigator.Companion.route
import com.appcreator.compose.LocalBlueprint
import com.appcreator.compose.LocalInPreview
import io.ktor.http.URLBuilder
import io.ktor.http.path
import kotlinx.browser.window
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.drop
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventListener

@Composable
actual fun deeplinkProvider(): () -> String? = {
    window.location.href
}

@Composable
actual fun pushDeeplink(): (String) -> Unit = {
    window.history.pushState("Page", window.name, it)
}

val LocalListenerStack = staticCompositionLocalOf { mutableListOf<EventListener>() }

@Composable
actual fun NavigationPlatformSetup(
    startDestination: String,
    navigator: KotlinXNavigator
) {


    val inPreview = LocalInPreview.current
    val deeplinkProvider = deeplinkProvider()
    val deeplinkUpdater = pushDeeplink()
    LaunchedEffect(Unit) {
        if(!inPreview) {
            navigator.navController.currentBackStackEntryFlow
                .drop(1)
                .collectLatest { backstack ->

                val path = backstack.arguments?.getString("route")
                    ?: backstack.destination.arguments["route"]?.defaultValue as? String

                path?.let {
                    deeplinkProvider()?.let { deeplink ->
                        val urlBuilder = URLBuilder(deeplink)
                        var parts =
                            urlBuilder.pathSegments.joinToString("/").split("_").toMutableList()

                        parts = parts.take(navigator.nestingCount).toMutableList()
                        parts.add(it)

                        val pathParts = parts.joinToString(separator = "/_/") { part ->
                            part.trimStart('/').trimEnd('/')
                        }.split("/")

                        urlBuilder.path(*pathParts.toTypedArray())
                        deeplinkUpdater(urlBuilder.buildString())
                    }
                }
            }
        }
    }
    val blueprint = LocalBlueprint.current
    val listeners = LocalListenerStack.current
    DisposableEffect(Unit) {
        val listener = object: EventListener {
            override fun handleEvent(event: Event) {
                if (listeners.last() == this) {
                    val stack = navigator.navController.currentBackStack.value.filter {
                        it.arguments?.getString(route) != null
                    }

                    if (stack.count() > 1) {
                        navigator.back()
                    } else if (stack.last().arguments?.getString(route) != startDestination) {
                        navigator.navigate(
                            link = BlueprintLink(startDestination, emptyMap()),
                            envStore = EnvStore.create(blueprint.defaultLocale),
                        ) {
                            popUpToRoot = true
                        }
                    }
                }
            }
        }
        listeners.add(listener)
        window.addEventListener("popstate", listener)
        onDispose {
            window.removeEventListener("popstate", listener)
            listeners.remove(listener)
        }
    }

}
