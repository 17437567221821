package com.appcreator.creatorapp.editor.centerdata.global

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.GlobalData
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager

@Composable
fun GlobalDataEditor() {
    val blueprintManager = LocalBlueprintManager.current
    var selectedItem by remember { mutableStateOf<GlobalData?>(null) }

    Row(Modifier.fillMaxSize()) {

        DataItemsSideBar(blueprintManager, selectedItem) {
            selectedItem = it
        }

        selectedItem?.let {
            GlobalDataItemDetails(
                blueprintManager = blueprintManager,
                item = it,
                update = { new ->
                    val index = blueprintManager.editableGlobalData.indexOf(it)
                    selectedItem = new
                    blueprintManager.editableGlobalData[index] = new
                    blueprintManager.notifyUpdates(true)
                },
                remove = {
                    selectedItem = null
                    blueprintManager.editableGlobalData.remove(it)
                    blueprintManager.notifyUpdates(true)
                }
            )
        }?: run {
//            GlobalEnvironments(
//                blueprintManager = blueprintManager
//            ) {
//                blueprintManager.notifyUpdates(true)
//            }
        }
    }
}
