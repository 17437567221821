package com.appcreator.creatorapp.editor.inputs

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.DashboardCustomize
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material.icons.filled.MoveDown
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import com.appcreator.blueprint.components.componentOptions
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.components.layouts.ClickableInput
import com.appcreator.components.layouts.ModalInputGroup
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.creatorapp.editor.panelcomponentpicker.ComponentList
import com.appcreator.creatorapp.editor.panelcomponentpicker.filteredOptions
import com.appcreator.creatorapp.editor.utils.displayLabel


@Composable
fun ComponentInput(spec: ComponentInputSpec, save: () -> Unit) {

    val blueprintManager = LocalBlueprintManager.current

    if (spec.properties.alwaysContainer) {

        ClickableInput(
            onClick = {
                blueprintManager.selectedComponent.value = spec.value
            },
            title = spec.properties.title,
            value = "Edit",
            leadingIcon = {
                Icon(Icons.Default.DashboardCustomize, contentDescription = null)
            },
            trailing = {
                Icon(Icons.Default.MoveDown, contentDescription = null)
            }
        )

    } else {

        ModalInputGroup(
            title = spec.properties.title,
            modalTitle = spec.value?.displayLabel() ?: "Select",
            supportingText = spec.properties.description,
            leadingIcon = {
                Icon(Icons.Default.DashboardCustomize, contentDescription = null)
            },
            header = {
                Text("Select component")
            },
            body = { close ->
                ComponentList(
                    options = filteredOptions(spec.allowedComponents)
                ) {
                    spec.value = it.block()
                    save()
                    close()
                }
            },
            trailingIcon = spec.value?.let {{
                    IconButton(onClick = {
                        blueprintManager.selectedComponent.value = spec.value
                    }) {
                        Icon(Icons.Default.Edit, contentDescription = null)
                    }
                }
            }
        )
    }

}

