package com.appcreator.compose.conditions

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.appcreator.blueprint.core.EnvStore


@Composable
actual fun JavascriptEvaluator.platformEvaluate(envStore: EnvStore): Boolean? {
    val result = remember { mutableStateOf(evaluateOnce(envStore)) }
    LaunchedEffect(condition.scriptContent?.script) {
        result.value = evaluateAsync(envStore)
    }
    return result.value
}