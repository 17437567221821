package com.appcreator.compose.components.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.data.StringSplitterComponent
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.evaluator


@Composable
fun StringSplitterComposable(modifier: Modifier, component: StringSplitterComponent) {
    val envStore = LocalEnvStore.current
    val items = rememberStringRepeaterItems(envStore, component)
    items?.let { list ->
        list.forEach {
            CompositionLocalProvider(LocalEnvStore provides it) {
                component.content?.let { component ->
                    ComponentComposable(Modifier, component)
                }
            }
        }
    }
}

@Composable
private fun rememberStringRepeaterItems(
    envStore: EnvStore,
    component: StringSplitterComponent
): List<EnvStore>? {

    return component.valueToSplit?.let { path ->
        val injected = envStore.injectVariables(path)
        val split = component.splitCharacter ?: ","
        val list = injected.split(split).mapIndexed { index, s ->
            EnvStore.create(envStore, mapOf("index" to index.toString(), "split-value" to s))
        }
        component.filter?.let { filter ->
            val evaluator = Container.evaluator(filter)
            val items = list.filter { evaluator?.evaluate(it) == true }
            return items
        } ?: list
    }
}
