package com.appcreator.compose.actions.data

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.setValue
import com.appcreator.blueprint.actions.data.SetTitleAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalScreenTitle
import com.appcreator.compose.actions.Performer

class SetTitlePerformer(private val action: SetTitleAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        var title by LocalScreenTitle.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                action.title?.let {
                    title = envStore.injectVariables(it)
                }
            }
        }
    }
}
