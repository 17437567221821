package com.appcreator.blueprint.firebase.auth

import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.ConditionClass
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("FirebaseAuthLoggedIn")
@ConditionClass(
    group = FirebaseAuthConsts.group,
    title = "Is logged in",
    isExtraPlugin = true,
    availableFrom = "0.0.1"
)
class FirebaseAuthLoggedInCondition: Condition