package com.appcreator.blueprint.conditions.logic

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ConditionBuilder
import com.appcreator.blueprint.spec.ConditionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ConditionListInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class OrConditionBuilder(
  condition: OrCondition?,
  newId: Boolean = false,
) : ConditionBuilder {
  override val spec: ConditionSpec = ConditionSpec(
          name = "OrCondition",
          title = "Or",
          group = "Logic",
          isExtraPlugin = false,
          description = "",
          availableFrom = "0.0.1",
          accessible = false,
          hasDocs = true,
      )

  public val conditions: ConditionListInputSpec = ConditionListInputSpec(
          properties = InputProperties(
          section = "",
          title = "Conditions",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateListOf(*(condition?.conditions?.map { it.toBuilder(newId) }?:
          emptyList()).toTypedArray()),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(conditions)

  override fun build(): Condition = OrCondition(conditions.value.map { it.build() })
}
