package com.appcreator.blueprint.actions.data

import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.ActionClass
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.StringDefault
import com.appcreator.blueprint.core.properties.DataPath
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("SetList")
@ActionClass(
    group = "Data",
    title = "Update List",
    availableFrom = "0.0.1"
)
class SetListAction(
    @InputProperty(
        title = "Key",
        availableFrom = "0.0.1",
        dataOptions = "List",
        settableDataOnly = true
    )
    val key: DataPath?,
    @InputProperty(
        title = "Value",
        dataOptions = "List",
        availableFrom = "0.0.1"
    )
    val value: DataPath?,
): Action
