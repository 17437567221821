package com.appcreator.compose.components.basic

import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.font.toFontFamily
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.sp
import com.appcreator.blueprint.components.basic.TextComponent
import com.appcreator.blueprint.core.properties.Font
import com.appcreator.blueprint.core.properties.FontReference
import com.appcreator.compose.LocalFontResources
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.extensions.composeColor
import com.appcreator.compose.extensions.value
import com.mikepenz.markdown.compose.Markdown
import com.mikepenz.markdown.m3.markdownColor
import com.mikepenz.markdown.m3.markdownTypography
import org.jetbrains.compose.resources.Font

@Composable
fun TextComposable(modifier: Modifier, component: TextComponent) {
    val theme = LocalTheme.current
    val color = theme?.color(component.textColor)
    val style = component.font.toTextStyle()
    component.value?.value()?.let {

        val alignment = when(component.alignment) {
            TextComponent.Alignment.Start -> TextAlign.Start
            TextComponent.Alignment.Center -> TextAlign.Center
            TextComponent.Alignment.End -> TextAlign.End
            null -> null
        }

        when {
            component.markdown == true -> {
                Markdown(
                    modifier = modifier,
                    content = it,
                    colors = markdownColor(text = color?.composeColor() ?: MaterialTheme.colorScheme.onBackground),
                    typography = markdownTypography(paragraph = style)
                )
            }
            component.redacted == true -> {
                val composeColor = color?.composeColor() ?: Color.Gray
                Text(
                    modifier = modifier.drawWithContent {
                        drawRoundRect(composeColor, cornerRadius = CornerRadius(4f, 4f))
                    },
                    text = it,
                    style = style,
                    textAlign = alignment
                )
            }
            else -> {
                Text(
                    modifier = modifier,
                    text = it,
                    style = style,
                    color = color?.composeColor() ?: MaterialTheme.colorScheme.onBackground,
                    textAlign = alignment
                )
            }
        }
    }
}

@Composable
fun FontReference?.toTextStyle(): TextStyle {
    val theme = LocalTheme.current
    return theme?.font(this).toTextStyle()
}

@Composable
fun Font?.toTextStyle(): TextStyle {
    return this?.let {
        customFont?.let { custom ->
            val font = LocalFontResources.current["_${custom.id}"]?.let {
                Font(resource = it).toFontFamily()
            } ?: fontFromUrl(custom.url) ?: FontFamily.Default

            TextStyle(
                fontFamily = font,
                fontSize = fontSize.sp,
                fontWeight = FontWeight(fontWeight),
                lineHeight = lineHeight?.sp ?: TextUnit.Unspecified
            )

        }?: TextStyle(
            fontSize = fontSize.sp,
            fontWeight = FontWeight(fontWeight),
        )

    }?: TextStyle.Default
}

@Composable
expect fun fontFromUrl(url: String): FontFamily?
