package com.appcreator.creatorapp.project.configurations

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.components.layouts.CheckboxGroup
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.dto.OrganisationResponse
import com.appcreator.dto.Project
import com.appcreator.dto.configurations.FirestoreConfig
import com.appcreator.dto.configurations.FirestoreIndex
import com.appcreator.dto.configurations.ProjectTemplate
import com.appcreator.styles.ThemeMargins

@Composable
fun AdminConfiguration(org: OrganisationResponse, project: Project) {

    Column(modifier = Modifier.padding(ThemeMargins.LargeMargins).widthIn(max = 1600.dp)) {


        LoadingComposable(loader = {
            mutableStateOf(it.getProjectAdminConfiguration(org.organisation.id, projectId = project.id))
        }) { cloud ->


            Text(
                style = MaterialTheme.typography.headlineMedium,
                text = "Template"
            )

            var display by remember { mutableStateOf(cloud.value) }
            var config by remember { mutableStateOf(cloud.value.templateConfiguration) }

            CheckboxGroup(
                label = "Make Project a template",
                checked = display.isTemplate,
                onCheckedChange = {
                    display = display.copy(isTemplate = it)
                    if(it && config == null) {
                        config = ProjectTemplate(
                            enabled = true,
                            id = "",
                            group = "",
                            name = "",
                            description = "",
                            image = "",
                            requiresContentful = false,
                            requiresFirebase = false,
                            requiresFirestore = false,
                            firestoreConfig = null,
                            requiresFirebaseAuth = false
                        )
                    }
                }
            )

            if (display.isTemplate) {

                CheckboxGroup(
                    label = "Enabled",
                    checked = config?.enabled?: true,
                    onCheckedChange = {
                        config = config?.copy(enabled = it)
                    }
                )

                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Group",
                    value = config?.group ?: "",
                    onValueChange = {
                        config = config?.copy(group = it)
                    }
                )

                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Name",
                    value = config?.name ?: "",
                    onValueChange = {
                        config = config?.copy(name = it)
                    }
                )

                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Description",
                    value = config?.description ?: "",
                    onValueChange = {
                        config = config?.copy(description = it)
                    }
                )

                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Image",
                    value = config?.image ?: "",
                    onValueChange = {
                        config = config?.copy(image = it)
                    }
                )

                CheckboxGroup(
                    label = "Requires Contentful",
                    checked = config?.requiresContentful?: true,
                    onCheckedChange = {
                        config = config?.copy(requiresContentful = it)
                    }
                )
                if (config?.requiresContentful == true) {
                    CreatorTextInput(
                        modifier = Modifier.fillMaxWidth().height(300.dp),
                        label = "Contentful Spec Json",
                        value = display.contentfulSpecJson?: "",
                        onValueChange = {
                            display = display.copy(contentfulSpecJson = it)
                        }
                    )
                }

                CheckboxGroup(
                    label = "Requires Firestore",
                    checked = config?.requiresFirestore?: true,
                    onCheckedChange = {
                        config = config?.copy(requiresFirestore = it, requiresFirebase = it || config?.requiresFirebaseAuth == true)
                    }
                )

                if (config?.requiresFirestore == true) {
                    CreatorTextInput(
                        modifier = Modifier.fillMaxWidth().height(300.dp),
                        label = "Fire Store Security",
                        value = config?.firestoreConfig?.security ?: "",
                        onValueChange = {
                            config = config?.copy(firestoreConfig = FirestoreConfig(it, config?.firestoreConfig?.indexes ?: emptyList()))
                        }
                    )

                    CreatorTextInput(
                        modifier = Modifier.fillMaxWidth(),
                        label = "Index Slugs",
                        supportingText = "Slugs for Contentful index pages, put each on a new line",
                        value = config?.firestoreConfig?.indexes?.joinToString(separator = "\n") { it.image } ?: "",
                        onValueChange = {
                            config = config?.copy(firestoreConfig = FirestoreConfig(
                                config?.firestoreConfig?.security ?: "",
                                it.split("\n").map { FirestoreIndex(it) }
                            ))
                        }
                    )
                }

                CheckboxGroup(
                    label = "Requires FirebaseAuth",
                    checked = config?.requiresFirebaseAuth?: true,
                    onCheckedChange = {
                        config = config?.copy(requiresFirebaseAuth = it, requiresFirebase = it || config?.requiresFirestore == true)
                    }
                )
            }

            Spacer(Modifier.height(ThemeMargins.LargeMargins))

            val apiClient = LocalApiClient.current
            LoadingButton(
                enabled = cloud.value != display || display.templateConfiguration != config,
                action = {
                    val newConfig = display.copy(templateConfiguration = config)
                    apiClient.setProjectAdminConfiguration(
                        orgId = org.organisation.id,
                        projectId = project.id,
                        config = newConfig
                    )
                    cloud.value = newConfig
                    display = newConfig
                },
                label = "Save"
            )
        }
    }

}