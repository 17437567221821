package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Image
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.properties.ImageResource
import com.appcreator.blueprint.spec.inputs.ImageResourceInputSpec
import com.appcreator.components.layouts.ModalInputGroup
import com.appcreator.creatorapp.editor.centerimages.ImageCenterContent
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.dto.FileResponse
import com.appcreator.styles.ThemeMargins

@Composable
fun ImageResourceInput(spec: ImageResourceInputSpec, save: () -> Unit) {

    val blueprintManager = LocalBlueprintManager.current

    val selected: MutableState<FileResponse?> = remember { mutableStateOf(null) }
    ModalInputGroup(
        title = spec.properties.title,
        modalTitle = spec.value?.name ?: spec.properties.title,
        supportingText = spec.properties.description,
        header =  {
            Text("Select Image")
        },
        body = { close ->
            ImageCenterContent(
                blueprintManager.orgId,
                blueprintManager.projectId,
                selected
            )
        },
        leadingIcon = {
            Icon(Icons.Default.Image, contentDescription = null)
        },
        footer = { close ->
            Button(
                modifier = Modifier.padding(ThemeMargins.MedMargins),
                enabled = selected.value != null,
                onClick = {
                    selected.value?.let {
                        spec.value = ImageResource(
                            id = it.id,
                            name = it.title,
                            url = it.url,
                            fileExtension = it.fileExtension
                        )
                        save()
                    }
                    close()
                }
            ) {
                Text("Use Selected")
            }
        }
    )
}
