package com.appcreator.creatorapp.organisation

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.creatorapp.project.configurations.LoadingButton
import com.appcreator.dto.Organisation
import com.appcreator.dto.OrganisationSubscription
import com.appcreator.styles.ThemeMargins

@Composable
fun OrganisationPayments(
    organisation: Organisation,
) {
    Column(modifier = Modifier.sizeIn(maxWidth = 800.dp)) {
        organisation.subscription?.let {
            OrganisationManagePlan(organisation, it)
        } ?: run {
            if(organisation.showPaymentPlans) {
                OrganisationSelectPlan(organisation)
            }
        }
    }
}

@Composable
private fun OrganisationManagePlan(
    organisation: Organisation,
    subscription: OrganisationSubscription
) {

    Column(Modifier.padding(horizontal = ThemeMargins.LargeMargins)) {
        val apiClient = LocalApiClient.current
        val uriHandler = LocalUriHandler.current
        if(subscription.isValid) {
            Text("This organisation has an active subscription")
        } else {
            Text("Subscription inactive")
        }
        Spacer(Modifier.height(ThemeMargins.MedMargins))
        LoadingButton(
            label = "Manage subscription",
            action = {
                val session = apiClient.createManagePaymentsSession(organisation.id)
                uriHandler.openUri(session.url)
            }
        )
    }
}



@Composable
expect fun OrganisationSelectPlan(organisation: Organisation)
