package com.appcreator.blueprint.actions

import com.appcreator.blueprint.actions.AlertActionBuilder
import com.appcreator.blueprint.actions.ConditionActionBuilder
import com.appcreator.blueprint.actions.CopyToClipboardActionBuilder
import com.appcreator.blueprint.actions.DelayActionBuilder
import com.appcreator.blueprint.actions.SequenceActionBuilder
import com.appcreator.blueprint.actions.TriggerActionBuilder
import com.appcreator.blueprint.actions.`data`.AddListItemActionBuilder
import com.appcreator.blueprint.actions.`data`.ApiRequestActionBuilder
import com.appcreator.blueprint.actions.`data`.DataConversionActionBuilder
import com.appcreator.blueprint.actions.`data`.RemoveListItemActionBuilder
import com.appcreator.blueprint.actions.`data`.SetListActionBuilder
import com.appcreator.blueprint.actions.`data`.SetTitleActionBuilder
import com.appcreator.blueprint.actions.`data`.SetValueActionBuilder
import com.appcreator.blueprint.actions.alerts.SnackbarAlertActionBuilder
import com.appcreator.blueprint.actions.navigation.CloseScreenActionBuilder
import com.appcreator.blueprint.actions.navigation.OpenExternalActionBuilder
import com.appcreator.blueprint.actions.navigation.OpenScreenActionBuilder
import com.appcreator.blueprint.actions.navigation.SetNavigationDrawerActionBuilder
import com.appcreator.blueprint.actions.permission.PermissionRequestActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthDeleteUserActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoginActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLogoutActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthResetPasswordActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSetDisplayNameActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSignUpActionBuilder
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreDeleteDataActionBuilder
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreSetDataActionBuilder
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingSubscribeToTopicActionBuilder
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingUnsubscribeToTopicActionBuilder
import com.appcreator.blueprint.firebase.storage.FirebaseStorageUploadImageActionBuilder
import com.appcreator.blueprint.revenuecat.RevenuecatManageSubscriptionsActionBuilder
import com.appcreator.blueprint.spec.ActionBuilderProvider
import com.appcreator.blueprint.spec.ActionSpec
import kotlin.collections.Map

public fun actionOptions(): Map<ActionSpec, ActionBuilderProvider> = mapOf(
        ActionSpec(
    name = "AlertAction",
    title = "Alert",
    group = "",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = true,
) to ActionBuilderProvider { AlertActionBuilder(null) },
        ActionSpec(
    name = "ConditionAction",
    title = "Conditional Action",
    group = "Logic",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { ConditionActionBuilder(null) },
        ActionSpec(
    name = "CopyToClipboardAction",
    title = "Copy to clipboard Alert",
    group = "Alerts",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { CopyToClipboardActionBuilder(null) },
        ActionSpec(
    name = "DelayAction",
    title = "Delay Action",
    group = "Logic",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { DelayActionBuilder(null) },
        ActionSpec(
    name = "SequenceAction",
    title = "Sequence",
    group = "",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = false,
    hasDocs = true,
) to ActionBuilderProvider { SequenceActionBuilder(null) },
        ActionSpec(
    name = "TriggerAction",
    title = "Trigger",
    group = "Other",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { TriggerActionBuilder(null) },
        ActionSpec(
    name = "SnackbarAlertAction",
    title = "Snackbar Alert",
    group = "Alerts",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { SnackbarAlertActionBuilder(null) },
        ActionSpec(
    name = "AddListItemAction",
    title = "Add Item to List",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { AddListItemActionBuilder(null) },
        ActionSpec(
    name = "ApiRequestAction",
    title = "Send API Request",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { ApiRequestActionBuilder(null) },
        ActionSpec(
    name = "DataConversionAction",
    title = "Convert Data",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { DataConversionActionBuilder(null) },
        ActionSpec(
    name = "RemoveListItemAction",
    title = "Remove Item from list",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { RemoveListItemActionBuilder(null) },
        ActionSpec(
    name = "SetListAction",
    title = "Update List",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { SetListActionBuilder(null) },
        ActionSpec(
    name = "SetTitleAction",
    title = "Set Title",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { SetTitleActionBuilder(null) },
        ActionSpec(
    name = "SetValueAction",
    title = "Update Value",
    group = "Data",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { SetValueActionBuilder(null) },
        ActionSpec(
    name = "CloseScreenAction",
    title = "Close Screen",
    group = "Navigation",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { CloseScreenActionBuilder(null) },
        ActionSpec(
    name = "OpenExternalAction",
    title = "Open External Link",
    group = "Navigation",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { OpenExternalActionBuilder(null) },
        ActionSpec(
    name = "OpenScreenAction",
    title = "Open Screen",
    group = "Navigation",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { OpenScreenActionBuilder(null) },
        ActionSpec(
    name = "SetNavigationDrawerAction",
    title = "Configure Navigation Drawer",
    group = "Navigation",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { SetNavigationDrawerActionBuilder(null) },
        ActionSpec(
    name = "PermissionRequestAction",
    title = "Request Permission",
    group = "Other",
    isExtraPlugin = false,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { PermissionRequestActionBuilder(null) },
        ActionSpec(
    name = "FirebaseAuthDeleteUserAction",
    title = "Delete User",
    group = "Firebase Auth",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { FirebaseAuthDeleteUserActionBuilder(null) },
        ActionSpec(
    name = "FirebaseAuthLoginAction",
    title = "Login",
    group = "Firebase Auth",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { FirebaseAuthLoginActionBuilder(null) },
        ActionSpec(
    name = "FirebaseAuthLogoutAction",
    title = "Logout",
    group = "Firebase Auth",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { FirebaseAuthLogoutActionBuilder(null) },
        ActionSpec(
    name = "FirebaseAuthResetPasswordAction",
    title = "Reset Password",
    group = "Firebase Auth",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { FirebaseAuthResetPasswordActionBuilder(null) },
        ActionSpec(
    name = "FirebaseAuthSetDisplayNameAction",
    title = "Set Display Name",
    group = "Firebase Auth",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { FirebaseAuthSetDisplayNameActionBuilder(null) },
        ActionSpec(
    name = "FirebaseAuthSignUpAction",
    title = "Sign Up",
    group = "Firebase Auth",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { FirebaseAuthSignUpActionBuilder(null) },
        ActionSpec(
    name = "FirebaseFirestoreDeleteDataAction",
    title = "Delete Data",
    group = "Firebase Firestore",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { FirebaseFirestoreDeleteDataActionBuilder(null) },
        ActionSpec(
    name = "FirebaseFirestoreSetDataAction",
    title = "Set Data",
    group = "Firebase Firestore",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { FirebaseFirestoreSetDataActionBuilder(null) },
        ActionSpec(
    name = "FirebaseMessagingSubscribeToTopicAction",
    title = "Subscribe to topic",
    group = "Firebase Messaging",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { FirebaseMessagingSubscribeToTopicActionBuilder(null) },
        ActionSpec(
    name = "FirebaseMessagingUnsubscribeToTopicAction",
    title = "Unsubscribe to topic",
    group = "Firebase Messaging",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { FirebaseMessagingUnsubscribeToTopicActionBuilder(null) },
        ActionSpec(
    name = "FirebaseStorageUploadImageAction",
    title = "Upload Image/Video",
    group = "Firebase Storage",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { FirebaseStorageUploadImageActionBuilder(null) },
        ActionSpec(
    name = "RevenuecatManageSubscriptionsAction",
    title = "Manage Subscriptions",
    group = "Revenue Cat",
    isExtraPlugin = true,
    description = "",
    availableFrom = "0.0.1",
    accessible = true,
    hasDocs = true,
) to ActionBuilderProvider { RevenuecatManageSubscriptionsActionBuilder(null) }
    )
