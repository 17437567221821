package com.appcreator.app.file

import kotlinx.browser.window

actual fun openFilePicker(
    accept: String,
    onPick: (FileInfo) -> Unit
) {
    window.document.loadFromDisk(accept) {
        onPick(
            FileInfo(
                data = it,
                name = filename,
                mimeType = mimeType ?: file.type,
                size = event.total.toLong()
            )
        )
    }
}