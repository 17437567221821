package com.appcreator.dto

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class Organisation(
    val id: String,
    val name: String,
    val subscription: OrganisationSubscription?,
    val members: List<OrganisationUser>,
    val canCreateProject: Boolean,
    val showPaymentPlans: Boolean
)

@Serializable
data class OrganisationSubscription(
    val isValid: Boolean,
    val expires: Instant?,
    val customerId: String,
    val name: String
)

@Serializable
data class OrganisationUser(
    val accessLevel: AccessLevel,
    val email: String,
    val name: String?
)
