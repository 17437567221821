package com.appcreator.compose.components.basic

import androidx.compose.foundation.Image
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import coil3.compose.AsyncImagePainter
import com.appcreator.compose.gif.AnimatedImageImpl

@Composable
actual fun PlatformImage(
    modifier: Modifier,
    scale: ContentScale,
    success: AsyncImagePainter.State.Success,
    default: @Composable (Modifier) -> Unit
) {
    (success.result.image as? AnimatedImageImpl)?.let {
        Image(
            modifier = Modifier,
            bitmap = it.animate(),
            contentScale = scale,
            contentDescription = null
        )
    }?: default(modifier)
}