package com.appcreator.compose.actions.navigation

import androidx.compose.material3.DrawerValue
import androidx.compose.runtime.Composable
import com.appcreator.blueprint.actions.navigation.SetNavigationDrawerAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.components.navigation.DrawerStates

class SetNavigationDrawerPerformer(private val action: SetNavigationDrawerAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        val inputEnvStore = LocalInputEnvStore.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                when(action.drawerAction) {
                    SetNavigationDrawerAction.Action.Open -> inputEnvStore.set(DrawerStates.key, DrawerValue.Open.name)
                    else -> inputEnvStore.set(DrawerStates.key, DrawerValue.Closed.name)
                }
            }
        }
    }

}
