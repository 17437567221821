package com.appcreator.creatorapp.editor.inputs

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.FormatSize
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import com.appcreator.blueprint.core.properties.FontReference
import com.appcreator.blueprint.spec.inputs.FontReferenceInputSpec
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager

@Composable
fun FontInput(spec: FontReferenceInputSpec, save: () -> Unit) {
    val blueprintManager = LocalBlueprintManager.current
    val name = blueprintManager.theme.fonts.find { it.id == spec.value?.id }?.name?: spec.value?.custom?.name?: "Default"
    DropDownInputGroup(
        title = spec.properties.title,
        supportingText = spec.properties.description,
        dropDownTitle = name,
        dropDownContent = { close ->
            blueprintManager.theme.fonts.forEach {
                DropdownItem(it.name) {
                    spec.value = FontReference(it.id, null)
                    save()
                    close()
                }
            }
        },
        leadingIcon = {
            Icon(Icons.Default.FormatSize, contentDescription = null)
        }
    )
}
