package com.appcreator.blueprint.components

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.navigation.NavigationComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.blueprint.spec.inputs.ColorReferenceInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.LocalizableStringInputSpec
import com.appcreator.blueprint.spec.inputs.ScreenPathInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class ScreenBuilder(
  component: Screen?,
  newId: Boolean = false,
) : ComponentBuilder {
  override val spec: ComponentSpec = ComponentSpec(
          group = "",
          name = "Screen",
          title = "Screen",
          isExtraPlugin = false,
          description = "",
          availableFrom = "0.0.1",
          accessible = false,
          hasDocs = false,
          producesDataFields = listOf(),
          producedFieldsEditable = false
      )

  override val _nodeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(if(newId || component?._nodeId == null) Uuid.random().toString()
          else component._nodeId),
          
          
      )

  override val _nodeLabel: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeLabel",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?._nodeLabel),
          
          
      )

  override val _nodeRelativeId: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "_nodeRelativeId",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(if(component?._nodeRelativeId == null) Uuid.random().toString()
          else component._nodeRelativeId),
          
          
      )

  public val lastUpdated: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Last Updated",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.lastUpdated),
          
          
      )

  public val id: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "ID",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !true },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.id?: ""),
          
          
      )

  public val path: ScreenPathInputSpec = ScreenPathInputSpec(
          properties = InputProperties(
          section = "",
          title = "Path",
          description =
          "The unique URL to your screen, you can add input parameters to the path, for example blog/{blogId}",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = false,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.path),
          
          
      )

  public val title: LocalizableStringInputSpec = LocalizableStringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Title",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.title),
          
          
      )

  public val screenType: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "",
          title = "Screen Type",
          description =
          "In most cases only the root screen of your app will be a Navigation screen all others will be Content",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.screenType?: Screen.ScreenType.Content).name),
          
          options = Screen.ScreenType.entries.map { it.name },
      )

  public val navigationColor: ColorReferenceInputSpec = ColorReferenceInputSpec(
          properties = InputProperties(
          section = "Navigation Bar",
          title = "Color",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && screenType.value == Screen.ScreenType.Content.name &&
          navigationStyle.value != Screen.NavigationStyle.Hidden.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.navigationColor),
          
          
      )

  public val navigationStyle: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Navigation Bar",
          title = "Style",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && screenType.value == Screen.ScreenType.Content.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.navigationStyle?: Screen.NavigationStyle.Large).name),
          
          options = Screen.NavigationStyle.entries.map { it.name },
      )

  public val navigationCustomTitle: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Navigation Bar",
          title = "Custom Title",
          description =
          "Replace the default text title with any Component for example an Image of your logo",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && screenType.value == Screen.ScreenType.Content.name &&
          navigationStyle.value != Screen.NavigationStyle.Hidden.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.navigationCustomTitle?.toBuilder(newId)),
          
          
      )

  public val navigationContent: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "Navigation Bar",
          title = "Content Color",
          description = "Color to use for items like text shown on the navigation bar",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && screenType.value == Screen.ScreenType.Content.name &&
          navigationStyle.value != Screen.NavigationStyle.Hidden.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((component?.navigationContent?:
          Screen.NavigationContent.Automatic).name),
          
          options = Screen.NavigationContent.entries.map { it.name },
      )

  public val navigationItems: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Navigation Bar",
          title = "Items",
          description = "Custom component show on the right of the Navigation Bar",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && screenType.value == Screen.ScreenType.Content.name &&
          navigationStyle.value != Screen.NavigationStyle.Hidden.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.navigationItems?.toBuilder(newId)),
          
          
      )

  public val content: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Content",
          title = "Container",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && screenType.value == Screen.ScreenType.Content.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = true,
          extraDataAvailable = "".split(",")
      ) ,
          value =  mutableStateOf(component?.content?.toBuilder(newId) ?:
          ContainerComponentBuilder(null, newId)),
          
          
      )

  public val navigation: ComponentInputSpec = ComponentInputSpec(
          properties = InputProperties(
          section = "Navigation",
          title = "Navigation",
          description = "",
          availableFrom = "0.0.1",
          displayInline = true,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false && screenType.value == Screen.ScreenType.Navigation.name },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.navigation?.toBuilder(newId)?:
          NavigationComponentBuilder(null)),
          
          
      )

  public val onLoadAction: ActionInputSpec = ActionInputSpec(
          properties = InputProperties(
          section = "Other",
          title = "On Appear Action",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(component?.onLoadAction?.toBuilder(newId)),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(_nodeId, _nodeLabel, _nodeRelativeId,
      lastUpdated, id, path, title, screenType, navigationColor, navigationStyle,
      navigationCustomTitle, navigationContent, navigationItems, content, navigation, onLoadAction)

  override fun build(): Component = Screen(_nodeId.value?: Uuid.random().toString(),
      _nodeLabel.value, _nodeRelativeId.value, lastUpdated.value, id.value?: "", path.value,
      title.value, screenType.value?.let { Screen.ScreenType.valueOf(it) }?:
      Screen.ScreenType.Content, navigationColor.value, navigationStyle.value?.let {
      Screen.NavigationStyle.valueOf(it) }?: Screen.NavigationStyle.Large,
      navigationCustomTitle.value?.build(), navigationContent.value?.let {
      Screen.NavigationContent.valueOf(it) }?: Screen.NavigationContent.Automatic,
      navigationItems.value?.build(), content.value?.build(), navigation.value?.build()?:
      NavigationComponentBuilder(null).build(), onLoadAction.value?.build())
}
