package com.appcreator.creatorapp.project.newproject

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import androidx.compose.ui.window.Dialog
import androidx.compose.ui.window.DialogProperties
import com.appcreator.client.contentful.richtext.RichTextRenderer
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.creatorapp.project.configurations.LoadingButton
import com.appcreator.dto.ChecklistItem
import com.appcreator.local.LocalOrganisation
import com.appcreator.local.LocalProject
import com.appcreator.local.SnackbarContainer
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.launch

@Composable
fun BlockingChecklist(
    checklist: List<ChecklistItem>
) {

    var visible by remember { mutableStateOf(true) }
    if (visible) {
        Dialog(
            properties = DialogProperties(usePlatformDefaultWidth = false),
            onDismissRequest = {
                // visible = false
            }
        ) {
            Box(
                Modifier.sizeIn(maxWidth = 1400.dp, maxHeight = 1200.dp)
                    .padding(ThemeMargins.SmallMargin)
            ) {
                Card(
                    modifier = Modifier.fillMaxSize(),
                    colors = CardDefaults.cardColors(containerColor = MaterialTheme.colorScheme.surfaceContainer),
                    elevation = CardDefaults.cardElevation(defaultElevation = 0.dp)
                ) {
                    println(checklist)
                    SnackbarContainer {
                        val slides = remember { checklist.map { it.toSlides(checklist).plus(SetupSlide.SetupCompleteSlide) }.flatten() }
                        val state = rememberPagerState {
                            slides.size
                        }
                        val scope = rememberCoroutineScope()
                        HorizontalPager(state, userScrollEnabled = false) { page ->
                            Box(Modifier.fillMaxSize()) {
                                val slide = slides[page]
                                slide.content {
                                    Row {
                                        if(page > 0) {
                                            Button(
                                                onClick = {
                                                    scope.launch {
                                                        state.animateScrollToPage(page = page - 1)
                                                    }
                                                }) {
                                                Text("Back")
                                            }
                                        }
                                        Spacer(Modifier.weight(1f))

                                        if (page == slides.lastIndex) {
                                            val apiClient = LocalApiClient.current
                                            val org = LocalOrganisation.current
                                            val project = LocalProject.current
                                            LoadingButton(
                                                enabled = it,
                                                label = "Done",
                                                action = {
                                                    apiClient.setChecklistCompleted(org.id, project.id)
                                                    visible = false
                                                }
                                            )
                                        } else {
                                            Button(
                                                enabled = it,
                                                onClick = {
                                                    scope.launch {
                                                        state.animateScrollToPage(page = page + 1)
                                                    }
                                                }) {
                                                Text("Next")
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

private fun ChecklistItem.toSlides(all: List<ChecklistItem>): List<SetupSlide> {
    return when(tag) {
        "contentful" -> listOf(SetupSlide.Contentful(this, extraConfig["template"]))
        "firebase" -> listOf(SetupSlide.Firebase(
            checklistItem = this,
            auth = all.any { it.tag == "firebase-auth" },
            firestore = all.any { it.tag == "firebase-firestore" }
        ))
        "firebase-auth" -> listOf(SetupSlide.FirebaseAuth(this))
        "firebase-firestore" -> listOf(
            SetupSlide.FirebaseFirestoreOverview(this),
            SetupSlide.FirebaseFirestoreSecurity(extraConfig["security"]),
            SetupSlide.FirebaseFirestoreIndexOverview,
        ) + (extraConfig["indexes"]?.split(",")?.map { SetupSlide.FirebaseFirestoreIndex(it) }?: emptyList())
        else -> emptyList()
    }
}

sealed class SetupSlide(
    val content: @Composable (@Composable (Boolean) -> Unit) -> Unit
) {

    class Contentful(checklistItem: ChecklistItem, templateId: String?): SetupSlide({
        SlideScaffold(
            checklistItem = checklistItem,
            requiresCompletion = true,
            content = {
                SetupContentfulSlide(templateId?: "", it)
            },
            footer = it
        )
    })
    class Firebase(
        checklistItem: ChecklistItem,
        auth: Boolean,
        firestore: Boolean
    ): SetupSlide({
        SlideScaffold(
            checklistItem = checklistItem,
            requiresCompletion = true,
            content = {
                SetupFirebaseSlide(auth, firestore, it)
            },
            footer = it
        )
    })
    class FirebaseAuth(checklistItem: ChecklistItem): SetupSlide({
        SlideScaffold(
            checklistItem = checklistItem,
            requiresCompletion = false,
            content = {},
            footer = it
        )
    })
    class FirebaseFirestoreOverview(checklistItem: ChecklistItem): SetupSlide({
        SlideScaffold(
            checklistItem = checklistItem,
            requiresCompletion = false,
            content = {},
            footer = it
        )
    })
    class FirebaseFirestoreSecurity(rules: String?): SetupSlide({
        SlideScaffold(
            title = "Firestore Security",
            description = null,
            slug = "firestore-security",
            requiresCompletion = false,
            content = {
                SetupFirebaseFirestoreSecuritySlide(rules)
            },
            footer = it
        )
    })
    data object FirebaseFirestoreIndexOverview: SetupSlide({
        SlideScaffold(
            title = "Firestore Indexing",
            description = null,
            slug = "firestore-indexes",
            requiresCompletion = false,
            content = {},
            footer = it
        )
    })
    class FirebaseFirestoreIndex(image: String?): SetupSlide({
        SlideScaffold(
            title = "Add Index",
            description = null,
            slug = image,
            requiresCompletion = false,
            content = {},
            footer = it
        )
    })
    data object SetupCompleteSlide: SetupSlide({
        SlideScaffold(
            title = "Setup Completed!",
            description = null,
            slug = "setup-checklist-completed",
            requiresCompletion = false,
            content = {},
            footer = it
        )
    })

}

@Composable
fun SlideScaffold(
    checklistItem: ChecklistItem,
    requiresCompletion: Boolean,
    content: @Composable (() -> Unit) -> Unit,
    footer: @Composable (Boolean) -> Unit
) {
    SlideScaffold(
        title = checklistItem.title,
        description = null,
        slug = checklistItem.tag,
        requiresCompletion = requiresCompletion,
        content = content,
        footer = footer
    )
}

@Composable
fun SlideScaffold(
    title: String,
    description: String?,
    slug: String? = null,
    requiresCompletion: Boolean,
    content: @Composable (() -> Unit) -> Unit,
    footer: @Composable (Boolean) -> Unit
) {
    var pageComplete by remember { mutableStateOf(!requiresCompletion) }
    Column(modifier = Modifier
        .padding(horizontal = ThemeMargins.LargeMargins)
        .fillMaxSize()
        .verticalScroll(rememberScrollState())) {

        Spacer(Modifier.height(ThemeMargins.LargeMargins))
        Column(modifier = Modifier) {
            Text(
                text = title,
                style = MaterialTheme.typography.headlineMedium
            )
            description?.let {
                Text(
                    text = it,
                    style = MaterialTheme.typography.bodyMedium
                )
            }
            slug?.let {
                Spacer(Modifier.height(ThemeMargins.LargeMargins))
                ContentfulSlideLoader(it) {
                    val uriHandler = LocalUriHandler.current
                    RichTextRenderer(
                        modifier = Modifier,
                        richText = it.richTextInfo,
                        openLink = { link ->
                            uriHandler.openUri(link)
                        },
                        openEntry = {}
                    )

                    content {
                        pageComplete = true
                    }

                }
            }?: content {
                pageComplete = true
            }

        }
        Spacer(Modifier.height(ThemeMargins.LargeMargins))
        Spacer(Modifier.weight(1f))
        footer(pageComplete)
        Spacer(Modifier.height(ThemeMargins.LargeMargins))
    }
}