package com.appcreator.blueprint.components.modal

import com.appcreator.blueprint.core.AlwaysContainer
import com.appcreator.blueprint.core.properties.ColorReference
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@SerialName("Dialog")
@Serializable
@ComponentClass(
    group = "Modal",
    title = "Dialog",
    availableFrom = "0.0.1"
)
data class DialogComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "Anchor",
        description = "Component to be used as the button to open the picker, alternatively dialogs can be opened with a trigger action",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val button: Component?,
    @InputProperty(
        title = "Trigger ID",
        description = "ID to be used by a Trigger action to open dialog",
        availableFrom = "0.0.1"
    )
    val triggerId: String?,
    @InputProperty(
        title = "Content",
        availableFrom = "0.0.1"
    )
    @AlwaysContainer
    val content: Component?,

    @InputProperty(
        title = "Background Color",
        availableFrom = "0.0.1"
    )
    val sheetColor: ColorReference?,

    @InputProperty(
        section = "Preview",
        title = "Show Dialog",
        availableFrom = "0.0.1"
    )
    val showInPreview: Boolean?
): Component
