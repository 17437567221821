package com.appcreator.compose

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Snackbar
import androidx.compose.material3.SnackbarDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.compose.extensions.composeColor
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.receiveAsFlow
import kotlin.time.Duration.Companion.seconds


@Composable
actual fun SnackbarAlertContainer(modifier: Modifier, content: @Composable () -> Unit) {
    var snackbarAction by remember { mutableStateOf<SnackbarData?>(null) }
    val channel = remember { Channel<SnackbarData>(Channel.CONFLATED) }
    LaunchedEffect(channel) {
        channel.receiveAsFlow().collect { info ->
            snackbarAction = info
            delay(4.seconds)
            snackbarAction = null
        }
    }

    val theme = LocalTheme.current
    Box {
        CompositionLocalProvider(LocalSnackbarAlert provides channel) {
            content()
        }
        AnimatedContent(modifier = modifier.padding(16.dp).align(Alignment.BottomCenter), targetState = snackbarAction) {
            it?.let {
                Snackbar(
                    content = { Text(it.message) },
                    containerColor = theme?.color(it.containerColor)?.composeColor()
                        ?: SnackbarDefaults.color,
                    contentColor = theme?.color(it.contentColor)?.composeColor()
                        ?: SnackbarDefaults.contentColor,
                )
            }
        }
    }
}