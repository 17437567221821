package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.DataArray
import androidx.compose.material.icons.filled.DataObject
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.ListDataSpecBuilder
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpecBuilder
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.DataSpecBuilder
import com.appcreator.blueprint.spec.inputs.DataSpecListInputSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput
import com.appcreator.styles.ThemeMargins

@Composable
fun DataSpecListInput(spec: DataSpecListInputSpec, save: () -> Unit) {

    Text(spec.properties.title)

    Box(Modifier.height(ThemeMargins.SmallMargin))

    spec.value.forEachIndexed { index, item ->
        DataItem(
            item = item,
            rootItem = true,
            update = {
                spec.value[index] = it.toBuilder()
                save()
            },
            save = save,
            delete = {
                spec.value.remove(item)
                save()
            }
        )
        Box(Modifier.height(ThemeMargins.MedMargins))
    }

    if (spec.properties.description.isNotEmpty()) {
        Text(
            modifier = Modifier.padding(horizontal = 12.dp),
            text = spec.properties.description,
            style = MaterialTheme.typography.bodySmall
        )
    }

    TextButton(
        onClick = {
            spec.value.add(ValueDataSpec(key = "", valueType = ValueDataSpec.Type.String, value = "", visible = true).toBuilder())
            save()
        }) {
        Icon(Icons.Default.Add, contentDescription = null)
        Spacer(Modifier.width(ThemeMargins.SmallMargin))
        Text("Add")
    }
}


@Composable
private fun DataItem(
    item: DataSpecBuilder,
    rootItem: Boolean,
    update: (DataSpec) -> Unit,
    save: () -> Unit,
    delete: () -> Unit
) {

    if(rootItem) {
        val selected = when (item) {
            is ValueDataSpecBuilder -> "Item"
            is ListDataSpecBuilder -> "List"
            else -> "Unknown"
        }
        DropDownInputGroup(
            title = "Data Type",
            dropDownTitle = selected,
            leadingIcon = {
                Icon(
                    when(item) {
                        is ValueDataSpecBuilder -> Icons.Default.DataObject
                        else -> Icons.Default.DataArray
                    }, contentDescription = null
                )
            }
        ) {
            DropdownItem("Item") {
                update(ValueDataSpec(item.build().key, valueType = ValueDataSpec.Type.String, value = "", visible = true))
            }
            DropdownItem("List") {
                update(ListDataSpec(item.build().key, emptyList()))
            }
        }
    }

    when(item) {
        is ValueDataSpecBuilder -> FlatDataItem(item, rootItem, save, delete)
        is ListDataSpecBuilder -> ListDataItem(item, save, delete)
        else -> {}
    }
}

@Composable
private fun FlatDataItem(
    item: ValueDataSpecBuilder,
    rootItem: Boolean,
    save: () -> Unit,
    delete: () -> Unit
) {
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "Key",
        value = item.key.value ?: "",
        onValueChange = {
            item.key.value = it
            save()
        },
        trailingIcon = {
            IconButton(onClick = {
                delete()
            }) {
                Icon(Icons.Default.Delete, contentDescription = null)
            }
        }
    )

    if (rootItem) {
        DataSourceTextInput(
            label = "Initial",
            value = item.value.value ?: "",
            allowDataItems = true,
            update = {
                item.value.value = it
                save()
            }
        )
    }
}

@Composable
private fun ListDataItem(
    list: ListDataSpecBuilder,
    save: () -> Unit,
    delete: () -> Unit
) {
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = "Key",
        value = list.key.value ?: "",
        onValueChange = {
            list.key.value = it
            save()
        },
        trailingIcon = {
            IconButton(onClick = {
                delete()
            }) {
                Icon(Icons.Default.Delete, contentDescription = null)
            }
        }
    )

    Column(Modifier.fillMaxWidth().padding(start = ThemeMargins.LargeMargins)) {
        list.items.value.forEachIndexed { index, item ->
            DataItem(
                item = item,
                rootItem = false,
                update = {
                    list.items.value[index] = it.toBuilder()
                    save()
                },
                save = save,
                delete = {
                    list.items.value.remove(item)
                    save()
                }
            )
        }
    }

    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.End
    ) {

        Button(
            onClick = {
                list.items.value.add(ValueDataSpec(key = "", valueType = ValueDataSpec.Type.String, value = "", visible = true).toBuilder())
                save()
            }) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Text("Add")
                Icon(Icons.Default.Add, contentDescription = null)
            }
        }
    }

}