package com.appcreator.dto

import kotlinx.serialization.Serializable

@Serializable
data class ManagePaymentSession(
    val url: String,
)


