package com.appcreator.blueprint

import com.appcreator.blueprint.actions.AlertAction
import com.appcreator.blueprint.actions.ConditionAction
import com.appcreator.blueprint.actions.CopyToClipboardAction
import com.appcreator.blueprint.actions.DelayAction
import com.appcreator.blueprint.actions.SequenceAction
import com.appcreator.blueprint.actions.TriggerAction
import com.appcreator.blueprint.actions.data.SetValueAction
import com.appcreator.blueprint.actions.UnsupportedAction
import com.appcreator.blueprint.actions.alerts.SnackbarAlertAction
import com.appcreator.blueprint.actions.data.AddListItemAction
import com.appcreator.blueprint.actions.data.ApiRequestAction
import com.appcreator.blueprint.actions.data.DataConversionAction
import com.appcreator.blueprint.actions.data.RemoveListItemAction
import com.appcreator.blueprint.actions.data.SetListAction
import com.appcreator.blueprint.actions.data.SetTitleAction
import com.appcreator.blueprint.actions.navigation.CloseScreenAction
import com.appcreator.blueprint.actions.navigation.OpenExternalAction
import com.appcreator.blueprint.actions.navigation.OpenScreenAction
import com.appcreator.blueprint.actions.navigation.SetNavigationDrawerAction
import com.appcreator.blueprint.actions.permission.PermissionRequestAction
import com.appcreator.blueprint.components.CustomComponent
import com.appcreator.blueprint.components.UnsupportedComponent
import com.appcreator.blueprint.components.basic.ButtonComponent
import com.appcreator.blueprint.components.basic.ContainerComponent
import com.appcreator.blueprint.components.basic.ImageComponent
import com.appcreator.blueprint.components.forms.InputComponent
import com.appcreator.blueprint.components.basic.MaterialIconComponent
import com.appcreator.blueprint.components.basic.TextComponent
import com.appcreator.blueprint.components.basic.VideoComponent
import com.appcreator.blueprint.components.basic.WebViewComponent
import com.appcreator.blueprint.components.data.CountdownComponent
import com.appcreator.blueprint.components.data.DataConversionComponent
import com.appcreator.blueprint.components.data.DataRepeaterComponent
import com.appcreator.blueprint.components.data.DatePartsComponent
import com.appcreator.blueprint.components.data.LoadingComponent
import com.appcreator.blueprint.components.forms.LocalStateComponent
import com.appcreator.blueprint.components.data.PagedLoaderComponent
import com.appcreator.blueprint.components.data.StringSplitterComponent
import com.appcreator.blueprint.components.forms.CheckboxComponent
import com.appcreator.blueprint.components.logic.ABComponent
import com.appcreator.blueprint.components.logic.ConditionalComponent
import com.appcreator.blueprint.components.forms.DatePickerModalComponent
import com.appcreator.blueprint.components.forms.TimePickerComponent
import com.appcreator.blueprint.components.modal.DialogComponent
import com.appcreator.blueprint.components.modal.SheetComponent
import com.appcreator.blueprint.components.navigation.NavigationComponent
import com.appcreator.blueprint.components.navigation.NavigationDrawerComponent
import com.appcreator.blueprint.components.navigation.NavigationItemComponent
import com.appcreator.blueprint.components.pager.TabbedPageComponent
import com.appcreator.blueprint.components.pager.TabbedPagerComponent
import com.appcreator.blueprint.conditions.JavascriptCondition
import com.appcreator.blueprint.conditions.UnsupportedCondition
import com.appcreator.blueprint.conditions.data.ContainsCondition
import com.appcreator.blueprint.conditions.data.NumberCompareCondition
import com.appcreator.blueprint.conditions.data.ContainsItemCondition
import com.appcreator.blueprint.conditions.data.DateCondition
import com.appcreator.blueprint.conditions.data.EqualsCondition
import com.appcreator.blueprint.conditions.device.IsMobileDeviceCondition
import com.appcreator.blueprint.conditions.logic.AndCondition
import com.appcreator.blueprint.conditions.logic.NotCondition
import com.appcreator.blueprint.conditions.logic.OrCondition
import com.appcreator.blueprint.conditions.permission.CheckPermissionCondition
import com.appcreator.blueprint.contentful.ContentfulLoaderSpec
import com.appcreator.blueprint.contentful.ContentfulRichTextComponent
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.Condition
import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.blueprint.dataspecs.ConversionDataSpec
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.UnsupportedDataSpec
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.blueprint.conversions.BasicMathsConversion
import com.appcreator.blueprint.conversions.DateAdditionConversion
import com.appcreator.blueprint.conversions.DateFormatConversion
import com.appcreator.blueprint.conversions.JavascriptConversion
import com.appcreator.blueprint.conversions.ListCountConversion
import com.appcreator.blueprint.conversions.NowConversion
import com.appcreator.blueprint.conversions.TodayConversion
import com.appcreator.blueprint.conversions.UUIDConversion
import com.appcreator.blueprint.conversions.UnsupportedConversion
import com.appcreator.blueprint.conversions.UppercaseConversion
import com.appcreator.blueprint.firebase.auth.FirebaseAuthDeleteUserAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoggedInCondition
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoginAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLogoutAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthResetPasswordAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSetDisplayNameAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSignUpAction
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreDeleteDataAction
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreLoaderSpec
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreSetDataAction
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingGetTokenConversion
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingSubscribeToTopicAction
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingUnsubscribeToTopicAction
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigCondition
import com.appcreator.blueprint.firebase.remoteconfig.FirebaseRemoteConfigConversion
import com.appcreator.blueprint.firebase.storage.FirebaseStorageUploadImageAction
import com.appcreator.blueprint.loaderspec.JsonLoaderSpec
import com.appcreator.blueprint.loaderspec.UnsupportedLoaderSpec
import com.appcreator.blueprint.revenuecat.RevenuecatHasEntitlementCondition
import com.appcreator.blueprint.revenuecat.RevenuecatManageSubscriptionsAction
import com.appcreator.blueprint.revenuecat.RevenuecatPaywallComponent
import kotlinx.serialization.modules.PolymorphicModuleBuilder
import kotlinx.serialization.modules.SerializersModuleBuilder
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass

// TODO code generate this from the annotation as well
fun blueprintSerializer(
    components: PolymorphicModuleBuilder<Component>.() -> Unit = {
        subclass(CustomComponent::class)
    }
): SerializersModuleBuilder.() -> Unit = {
    polymorphic(Component::class) {
        defaultDeserializer { UnsupportedComponent.serializer() }
        subclass(ABComponent::class)
        subclass(ButtonComponent::class)
        subclass(ImageComponent::class)
        subclass(DataRepeaterComponent::class)
        subclass(StringSplitterComponent::class)
        subclass(ContainerComponent::class)
        subclass(LoadingComponent::class)
        subclass(TextComponent::class)
        subclass(NavigationComponent::class)
        subclass(NavigationItemComponent::class)
        subclass(MaterialIconComponent::class)
        subclass(InputComponent::class)
        subclass(CheckboxComponent::class)
        subclass(ConditionalComponent::class)
        subclass(NavigationDrawerComponent::class)
        subclass(CountdownComponent::class)
        subclass(PagedLoaderComponent::class)
        subclass(SheetComponent::class)
        subclass(DialogComponent::class)
        subclass(DatePickerModalComponent::class)
        subclass(LocalStateComponent::class)
        subclass(DataConversionComponent::class)
        subclass(DatePartsComponent::class)
        subclass(TabbedPagerComponent::class)
        subclass(TabbedPageComponent::class)
        subclass(VideoComponent::class)
        subclass(WebViewComponent::class)
        subclass(TimePickerComponent::class)

        subclass(ContentfulRichTextComponent::class)
        subclass(RevenuecatPaywallComponent::class)

        components()
    }

    polymorphic(Condition::class) {
        defaultDeserializer { UnsupportedCondition.serializer() }
        subclass(EqualsCondition::class)
        subclass(AndCondition::class)
        subclass(OrCondition::class)
        subclass(NotCondition::class)
        subclass(JavascriptCondition::class)
        subclass(DateCondition::class)
        subclass(ContainsItemCondition::class)
        subclass(ContainsCondition::class)
        subclass(NumberCompareCondition::class)
        subclass(CheckPermissionCondition::class)
        subclass(IsMobileDeviceCondition::class)

        subclass(FirebaseAuthLoggedInCondition::class)
        subclass(FirebaseRemoteConfigCondition::class)

        subclass(RevenuecatHasEntitlementCondition::class)
    }

    polymorphic(Action::class) {
        defaultDeserializer { UnsupportedAction.serializer() }
        subclass(OpenScreenAction::class)
        subclass(CloseScreenAction::class)
        subclass(OpenExternalAction::class)
        subclass(AlertAction::class)
        subclass(ConditionAction::class)
        subclass(SequenceAction::class)
        subclass(SetValueAction::class)
        subclass(SetListAction::class)
        subclass(AddListItemAction::class)
        subclass(RemoveListItemAction::class)
        subclass(TriggerAction::class)
        subclass(ApiRequestAction::class)
        subclass(DataConversionAction::class)
        subclass(PermissionRequestAction::class)
        subclass(SetNavigationDrawerAction::class)
        subclass(SetTitleAction::class)
        subclass(SnackbarAlertAction::class)
        subclass(DelayAction::class)
        subclass(CopyToClipboardAction::class)

        subclass(FirebaseAuthDeleteUserAction::class)
        subclass(FirebaseAuthLoginAction::class)
        subclass(FirebaseAuthLogoutAction::class)
        subclass(FirebaseAuthResetPasswordAction::class)
        subclass(FirebaseAuthSignUpAction::class)
        subclass(FirebaseAuthSetDisplayNameAction::class)

        subclass(FirebaseFirestoreSetDataAction::class)
        subclass(FirebaseFirestoreDeleteDataAction::class)

        subclass(FirebaseStorageUploadImageAction::class)

        subclass(FirebaseMessagingSubscribeToTopicAction::class)
        subclass(FirebaseMessagingUnsubscribeToTopicAction::class)

        subclass(RevenuecatManageSubscriptionsAction::class)
    }

    polymorphic(LoaderSpec::class) {
        defaultDeserializer { UnsupportedLoaderSpec.serializer() }
        subclass(JsonLoaderSpec::class)
        subclass(FirebaseFirestoreLoaderSpec::class)
        subclass(ContentfulLoaderSpec::class)
    }

    polymorphic(Conversion::class) {
        defaultDeserializer { UnsupportedConversion.serializer() }
        subclass(ListCountConversion::class)
        subclass(DateFormatConversion::class)
        subclass(DateAdditionConversion::class)
        subclass(JavascriptConversion::class)
        subclass(UppercaseConversion::class)
        subclass(NowConversion::class)
        subclass(TodayConversion::class)
        subclass(BasicMathsConversion::class)
        subclass(UUIDConversion::class)
        subclass(FirebaseRemoteConfigConversion::class)
        subclass(FirebaseMessagingGetTokenConversion::class)
    }

    polymorphic(DataSpec::class) {
        defaultDeserializer { UnsupportedDataSpec.serializer() }
        subclass(UnsupportedDataSpec::class)
        subclass(ListDataSpec::class)
        subclass(ValueDataSpec::class)
        subclass(ConversionDataSpec::class)
    }
}
