package com.appcreator.blueprint.core.properties

import com.appcreator.blueprint.core.Action
import kotlinx.serialization.Serializable

@Serializable
data class JsCallback(
    val name: String,
    val properties: List<String>,
    val action: Action?
)
