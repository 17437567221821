package com.appcreator.blueprint.actions.permission

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.ActionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class PermissionRequestActionBuilder(
  action: PermissionRequestAction?,
  newId: Boolean = false,
) : ActionBuilder {
  override val spec: ActionSpec = ActionSpec(
          name = "PermissionRequestAction",
          title = "Request Permission",
          group = "Other",
          isExtraPlugin = false,
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
      )

  public val permission: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "",
          title = "Type",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((action?.permission?:
          PermissionRequestAction.Permission.Notifications).name),
          
          options = PermissionRequestAction.Permission.entries.map { it.name },
      )

  override val inputSpecs: List<InputSpec> = listOf(permission)

  override fun build(): Action = PermissionRequestAction(permission.value?.let {
      PermissionRequestAction.Permission.valueOf(it) }?:
      PermissionRequestAction.Permission.Notifications)
}
