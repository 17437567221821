package com.appcreator.blueprint.conversions

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ConversionBuilder
import com.appcreator.blueprint.spec.ConversionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class NowConversionBuilder(
  conversion: NowConversion?,
  newId: Boolean = false,
) : ConversionBuilder {
  override val spec: ConversionSpec = ConversionSpec(
          group = "",
          name = "NowConversion",
          title = "Time now",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
          isExtraPlugin = false,
      )

  override val inputSpecs: List<InputSpec> = listOf()

  override fun build(): Conversion = NowConversion()
}
