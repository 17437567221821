package com.appcreator.creatorapp.editor.centerdata.firestore

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.Icon
import androidx.compose.material3.ScrollableTabRow
import androidx.compose.material3.Tab
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.key
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreLoaderSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.creatorapp.api.MutableDataSource
import com.appcreator.styles.ThemeMargins


@Composable
fun RowScope.FirestoreRequestDetails(
    item: MutableDataSource,
    save: () -> Unit,
    remove: () -> Unit
) {

    Column(modifier = Modifier.weight(1f)
        .padding(horizontal = ThemeMargins.SmallMargin)
    ) {

        CreatorTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Request name",
            value = item.title,
            onValueChange = {
                item.title = it
                save()
            },
            trailingIcon = {
                TextButton(onClick = remove) {
                    Icon(Icons.Default.Delete, contentDescription = null)
                    Spacer(Modifier.width(ThemeMargins.SmallMargin))
                    Text("Delete")
                }
            }
        )

        var selectedSection by remember { mutableStateOf(FirestoreRequestDetailsSections.Query) }
        val loaderSpec = item.loaderSpec as FirebaseFirestoreLoaderSpec

        val filtered =  FirestoreRequestDetailsSections.entries

        ScrollableTabRow(
            selectedTabIndex = filtered.indexOf(selectedSection).coerceAtLeast(0),
            containerColor = Color.Unspecified

        ) {
            filtered
                .forEach { section ->
                    Tab(
                        selected = selectedSection == section,
                        enabled = section.visible(loaderSpec),
                        onClick = {
                            selectedSection = section
                        }
                    ) {
                        Text(
                            text = section.title,
                            modifier = Modifier.padding(ThemeMargins.MedMargins)
                        )
                    }
                }
        }

        key(item.id) {
            selectedSection.content(item, loaderSpec, save)
        }

    }

}