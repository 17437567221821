package com.appcreator.creatorapp.editor.centerdata.rest

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Download
import androidx.compose.material3.Icon
import androidx.compose.material3.ScrollableTabRow
import androidx.compose.material3.Tab
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.VerticalDivider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.key
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.appcreator.blueprint.GlobalData
import com.appcreator.blueprint.core.DataShape
import com.appcreator.blueprint.core.LoaderSpec
import com.appcreator.blueprint.core.UrlSpec
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.dataspecs.ListDataSpecBuilder
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.loaderspec.JsonLoaderSpec
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.Dropdown
import com.appcreator.creatorapp.api.MutableDataSource
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.creatorapp.local.LocalDataShapeClient
import com.appcreator.local.LocalSnackbar
import com.appcreator.local.SnackbarInfo
import com.appcreator.styles.ThemeMargins
import kotlinx.coroutines.launch
import kotlin.random.Random

enum class RestDataSection(
    val title: String,
    var visible: (JsonLoaderSpec) -> Boolean = { true },
    val content: @Composable (MutableDataSource, JsonLoaderSpec, save: () -> Unit) -> Unit
) {
    Parameters("Parameters",
        visible = {
            it.parameters.isNotEmpty()
        },
        content = { data, spec, save ->
            val config = spec.parameters.map {
                it to (spec.parameterSettings?.get(it)?: LoaderSpec.defaultParameterSetting())
            }
            DataParametersConfig(LocalBlueprintManager.current.globalData, config) {
                data.loaderSpec = spec.copy(parameterSettings = it.toMap())
                save()
            }
    }),
    Headers("Headers", content = { data, spec, save ->
        KeyValuePairList(spec.urlSpec.headers.toList()) {
            data.loaderSpec = spec.copy(urlSpec = spec.urlSpec.copy(headers = it.toMap()))
            save()
        }
    }),
    QueryParams("Query Params", content = { data, spec, save ->
        KeyValuePairList(spec.urlSpec.queries.toList()) {
            data.loaderSpec = spec.copy(urlSpec = spec.urlSpec.copy(queries = it.toMap()))
            save()
        }
    }),
    Body("Body",
        visible = {
            val verb = it.urlSpec.verb
            verb == UrlSpec.Verb.Post || verb == UrlSpec.Verb.Put
        },
        content = { data, spec, save ->
            RestBody(spec) {
                data.loaderSpec = it
                save()
            }
    }),
    Response("Response", content = { data, spec, save ->
        if (spec.dataShape?.options?.isNotEmpty() == true) {
           Column(Modifier.verticalScroll(rememberScrollState())) {
                DataShapeList(
                    modifier = Modifier
                        .fillMaxHeight(0.6f),
                    dataSpec = ListDataSpec(
                        key = "",
                        items = spec.dataShape?.options ?: emptyList()
                    ).toBuilder() as ListDataSpecBuilder,
                    update = {
                        (it as? ListDataSpec)?.let {
                            data.loaderSpec = spec.copy(dataShape = DataShape(it.items))
                            save()
                        }
                    },
                    listOption = mutableListOf()
                )
            }
        }
    })
}

@Composable
fun RestData() {

    val blueprintManager = LocalBlueprintManager.current
    var selectedItem by remember { mutableStateOf(blueprintManager.dataSources.filter { it.loaderSpec is JsonLoaderSpec }.firstOrNull()) }

    Row(Modifier.fillMaxSize()) {

        CallsSideBar(blueprintManager, selectedItem) {
            selectedItem = it
        }

        VerticalDivider()

        selectedItem?.let {
            RestDataItem(item = it, save = {
                blueprintManager.notifyUpdates(true)
            }) {
                blueprintManager.dataSources.remove(it)
                selectedItem = null
            }
        }
    }
}


@Composable
private fun RowScope.RestDataItem(
    item: MutableDataSource,
    save: () -> Unit,
    remove: () -> Unit
) {
    val client = LocalDataShapeClient.current

    Column(modifier = Modifier.weight(1f)
        .padding(horizontal = ThemeMargins.SmallMargin)
    ) {

        CreatorTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Request name",
            value = item.title,
            onValueChange = {
                item.title = it
                save()
            },
            trailingIcon = {
                TextButton(onClick = remove) {
                    Icon(Icons.Default.Delete, contentDescription = null)
                    Spacer(Modifier.width(ThemeMargins.SmallMargin))
                    Text("Delete")
                }
            }
        )

        var selectedSection by remember { mutableStateOf(RestDataSection.Response) }

        val loaderSpec = item.loaderSpec as JsonLoaderSpec
        val scope = rememberCoroutineScope()

        Row(verticalAlignment = Alignment.CenterVertically) {

            CreatorTextInput(
                modifier = Modifier.weight(1f),
                label = "URL",
                value = loaderSpec.urlSpec.url,
                onValueChange = {
                    val newSpec = loaderSpec.copy(
                        urlSpec = loaderSpec.urlSpec.copy(url = it)
                    )
                    item.loaderSpec = newSpec
                    save()
                },
                leadingIcon = {
                    Dropdown(
                        anchor = {
                            TextButton(onClick = it) {
                                Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                                Text(loaderSpec.urlSpec.verb.name)
                            }
                        },
                        items = UrlSpec.Verb.entries,
                        onSelection = { verb ->
                            val newSpec =
                                loaderSpec.copy(urlSpec = loaderSpec.urlSpec.copy(verb = verb))
                            item.loaderSpec = newSpec
                            save()

                            if (!selectedSection.visible(newSpec)) {
                                selectedSection = RestDataSection.Response
                            }
                        }
                    )
                },
                trailingIcon = {
                    val blueprintManager = LocalBlueprintManager.current
                    val snackbarChannel = LocalSnackbar.current
                    TextButton(onClick = {
                        scope.launch {

                            try {
                                val globalValues = blueprintManager.globalData
                                val defaults = loaderSpec.parameters.associateWith { key ->
                                    val global = globalValues.find { it.key == key }
                                        ?.value(GlobalData.defaultEnv)
                                    val setting = loaderSpec.parameterSettings?.get(key)
                                    (global ?: setting?.default ?: "")
                                }

                                item.loaderSpec = loaderSpec.copy(
                                    dataShape = DataShape(
                                        client.loadDataShape(
                                            loaderSpec.urlSpec,
                                            defaults
                                        )
                                    )
                                )
                                save()
                                selectedSection = RestDataSection.Response
                            } catch (ex: Exception) {
                                snackbarChannel.send(SnackbarInfo.Builder.fromNetworkException(ex))
                                ex.printStackTrace()
                            }
                        }
                    }) {
                        Icon(Icons.Default.Download, contentDescription = null)
                        Spacer(Modifier.width(ThemeMargins.SmallMargin))
                        Text("Load")
                    }
                }
            )
        }

        val filtered =  RestDataSection.entries

        ScrollableTabRow(
            selectedTabIndex = filtered.indexOf(selectedSection).coerceIn(minimumValue = 0, maximumValue = filtered.lastIndex),
            containerColor = Color.Unspecified

        ) {
            filtered
                .forEach { section ->
                    val enabled = section.visible(loaderSpec)
                    Tab(
                        selected = selectedSection == section,
                        enabled = enabled,
                        onClick = {
                            selectedSection = section
                        }
                    ) {
                        Text(
                            text = section.title,
                            color = if(enabled) Color.Unspecified else Color.Gray.copy(alpha = 0.6f),
                            modifier = Modifier.padding(ThemeMargins.MedMargins)
                        )
                    }
                }
        }

        key(item.id) {
            selectedSection.content(item, loaderSpec, save)
        }
    }
}


private val charPool : List<Char> = ('a'..'z') + ('A'..'Z') + ('0'..'9')
fun randomStringByKotlinRandom() = (1..6)
    .map { Random.nextInt(0, charPool.size).let { charPool[it] } }
    .joinToString("")
