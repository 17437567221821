package com.appcreator.blueprint.firebase.remoteconfig

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Conversion
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ConversionBuilder
import com.appcreator.blueprint.spec.ConversionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import com.appcreator.blueprint.spec.inputs.StringInputSpec
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class FirebaseRemoteConfigConversionBuilder(
  conversion: FirebaseRemoteConfigConversion?,
  newId: Boolean = false,
) : ConversionBuilder {
  override val spec: ConversionSpec = ConversionSpec(
          group = "Firebase Remote Config",
          name = "FirebaseRemoteConfigConversion",
          title = "Firebase Remote Config",
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
          isExtraPlugin = true,
      )

  public val keyName: StringInputSpec = StringInputSpec(
          properties = InputProperties(
          section = "",
          title = "Key Name",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(conversion?.keyName),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(keyName)

  override fun build(): Conversion = FirebaseRemoteConfigConversion(keyName.value)
}
