package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material.icons.filled.DashboardCustomize
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.DragHandle
import androidx.compose.material.icons.filled.MoveDown
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.componentOptions
import com.appcreator.blueprint.spec.inputs.ComponentListInputSpec
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.creatorapp.editor.panelcomponentpicker.ComponentList
import com.appcreator.creatorapp.editor.panelcomponentpicker.filteredOptions
import com.appcreator.creatorapp.editor.utils.displayLabel
import com.appcreator.styles.ThemeMargins
import sh.calvin.reorderable.ReorderableItem
import sh.calvin.reorderable.rememberReorderableLazyListState

@Composable
fun ComponentListInput(spec: ComponentListInputSpec, save: () -> Unit) {

    val blueprintManager = LocalBlueprintManager.current

    Box {
        Column(Modifier
            .padding(vertical = 8.dp)
            .border(1.dp, color = Color.Black, shape = RoundedCornerShape(3.dp))
            .fillMaxWidth()
        ) {

            val lazyListState = rememberLazyListState()
            val reorderableLazyListState = rememberReorderableLazyListState(lazyListState) { from, to ->
                spec.value.add(to.index, spec.value.removeAt(from.index))
                save()
            }

            LazyColumn(
                modifier = Modifier.height(50.dp * spec.value.size),
                state = lazyListState
            ) {
                items(spec.value, key = { it._nodeId }) { item ->

                    ReorderableItem(reorderableLazyListState, key = item._nodeId) {
                        Row(Modifier.fillMaxWidth().height(50.dp),
                            horizontalArrangement = Arrangement.spacedBy(ThemeMargins.SmallMargin),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            IconButton(modifier = Modifier.draggableHandle(), onClick = {}) {
                                Icon(Icons.Default.DragHandle, contentDescription = null)
                            }
                            TextButton(onClick = {
                                blueprintManager.selectedComponent.value = item
                            }) {
                                Icon(Icons.Default.MoveDown, contentDescription = null)
                                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                                Text(item.displayLabel())
                            }
                            Spacer(Modifier.weight(1f))
                            TextButton(onClick = {
                                spec.value.remove(item)
                                save()
                            }) {
                                Icon(Icons.Default.Delete, contentDescription = null)
                                Spacer(Modifier.width(ThemeMargins.SmallMargin))
                                Text("Delete")
                            }
                        }
                    }
                }
            }

            if (spec.allowedComponents.size == 1) {
                Row(modifier = Modifier
                    .clickable {
                        val comp = spec.allowedComponents.first()
                        val options = componentOptions()
                        options.keys.find { it.name == comp }?.let {
                            spec.value.add(options[it]!!.block() )
                            save()
                        }
                    }
                    .padding(
                        horizontal = ThemeMargins.SmallMargin,
                        vertical = ThemeMargins.MedMargins
                    )
                ) {
                    Icon(Icons.Default.DashboardCustomize, contentDescription = null)
                    Spacer(Modifier.width(12.dp))
                    Text("Add component")
                    Spacer(Modifier.weight(1f))
                    Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                }
            } else {

                ModalScaffold(
                    header = {
                        Text("Select component")
                    },
                    body = { close ->
                        ComponentList(
                            options = filteredOptions(spec.allowedComponents)
                        ) {
                            spec.value.add(it.block())
                            save()
                            close()
                        }
                    },
                    anchor = {
                        Row(modifier = Modifier
                            .clickable {
                                it()
                            }
                            .padding(
                                horizontal = ThemeMargins.SmallMargin,
                                vertical = ThemeMargins.MedMargins
                            )
                        ) {
                            Icon(Icons.Default.DashboardCustomize, contentDescription = null)
                            Spacer(Modifier.width(12.dp))
                            Text("Add component")
                            Spacer(Modifier.weight(1f))
                            Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                        }
                    }
                )
            }

        }

        Text(
            modifier = Modifier
                .padding(horizontal = 8.dp)
                .background(Color.White)
                .padding(horizontal = 8.dp),
            style = MaterialTheme.typography.bodySmall,
            text = spec.properties.title
        )
    }
}