package com.appcreator.components.layouts

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextFieldColors
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.onFocusEvent
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp

@Composable
fun <T> TypedCreatorTextInput(
    modifier: Modifier = Modifier,
    readOnly: Boolean = false,
    singleLine: Boolean = false,
    label: String,
    value: T?,
    colors: TextFieldColors = OutlinedTextFieldDefaults.colors(),
    onValueChange: (String) -> Unit,
    leadingIcon: @Composable (() -> Unit)? = null,
    trailingIcon: @Composable (() -> Unit)? = null,
)  {
    var display by remember { mutableStateOf(value?.toString() ?: "") }
    CreatorTextInput(
        modifier = modifier,
        readOnly = readOnly,
        singleLine = singleLine,
        label = label,
        value = display.ifEmpty { " " },
        colors = colors,
        onValueChange = {
            display = it
        },
        leadingIcon = leadingIcon,
        trailingIcon = trailingIcon,
    )
    LaunchedEffect(display) {
        onValueChange(display.trimStart(' ').trimEnd(' '))
    }
    LaunchedEffect(value) {
        display = value?.toString() ?: ""
    }
}

@Composable
fun CreatorTextInput(
    modifier: Modifier = Modifier,
    readOnly: Boolean = false,
    singleLine: Boolean = false,
    label: String,
    value: String,
    supportingText: String? = null,
    placeholder: String? = null,
    colors: TextFieldColors = OutlinedTextFieldDefaults.colors(),
    onValueChange: (String) -> Unit,
    leadingIcon: @Composable (() -> Unit)? = null,
    trailingIcon: @Composable (() -> Unit)? = null,
)  {
    OutlinedTextField(
        value = value,
        onValueChange = onValueChange,
        label = {
            Text(label)
        },
        placeholder = placeholder?.let { { Text(it) } },
        modifier = modifier,
        readOnly = readOnly,
        singleLine = singleLine,
        leadingIcon = leadingIcon,
        trailingIcon = trailingIcon,
        supportingText = if(!supportingText.isNullOrBlank()) { { Text(supportingText) } } else null,
        colors = colors
    )
}

@Composable
fun ClickableInput(
    modifier: Modifier = Modifier.fillMaxWidth(),
    onClick: () -> Unit,
    title: String,
    value: String,
    trailing: @Composable (() -> Unit)? = null,
    leadingIcon: @Composable (() -> Unit)? = null,
) {
    CreatorTextInput(
        modifier = modifier
            .onFocusEvent {
                if (it.hasFocus) {
                    onClick()
                }
            },
        readOnly = true,
        label = title,
        value = value,
        onValueChange = {},
        leadingIcon = leadingIcon,
        trailingIcon = {
            trailing?.invoke()
        }
    )
}

@Composable
fun DropDownInputGroup(
    modifier: Modifier = Modifier.fillMaxWidth(),
    title: String,
    dropDownTitle: String,
    supportingText: String? = null,
    leadingIcon: @Composable (() -> Unit)? = null,
    dropDownContent: @Composable (() -> Unit) -> Unit,
) {

    var show by remember { mutableStateOf<(() -> Unit)?>(null) }
    CreatorTextInput(
        modifier = modifier
            .onFocusEvent {
                if (it.hasFocus) {
                    show?.invoke()
                }
            },
        readOnly = true,
        label = title,
        value = dropDownTitle,
        supportingText = supportingText,
        onValueChange = {},
        leadingIcon = leadingIcon,
        trailingIcon = {
            Dropdown(
                anchor = {
                    show = it
                    IconButton(onClick = it) {
                        Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                    }
                },
                content = dropDownContent
            )
        }
    )
}

@Composable
fun ModalInputGroup(
    title: String,
    modalTitle: String,
    supportingText: String?,
    leadingIcon: @Composable (() -> Unit)? = null,
    modal: @Composable (@Composable (() -> Unit) -> Unit) -> Unit,
    trailingIcon: @Composable ((() -> Unit) -> Unit)? = null
) {
    modal { show ->
        Column {
            Box {
                Row(
                    Modifier
                        .padding(vertical = 8.dp)
                        .border(1.dp, color = Color.Black, shape = RoundedCornerShape(3.dp))
                        .clickable { show() }
                        .padding(start = 8.dp)
                        .heightIn(min = 50.dp)
                        .fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.spacedBy(10.dp)
                ) {

                    leadingIcon?.invoke()
                    Text(modalTitle)

                    Spacer(Modifier.weight(1f))
                    trailingIcon?.invoke(show) ?: IconButton(onClick = show) {
                        Icon(Icons.Default.ArrowDropDown, contentDescription = null)
                    }
                }

                Text(
                    modifier = Modifier
                        .padding(horizontal = 8.dp)
                        .background(MaterialTheme.colorScheme.surface)
                        .padding(horizontal = 8.dp),
                    style = MaterialTheme.typography.bodySmall,
                    text = title
                )
            }
            if(!supportingText.isNullOrBlank()) {
                Text(
                    modifier = Modifier.padding(horizontal = 12.dp),
                    text = supportingText,
                    style = MaterialTheme.typography.bodySmall
                )
            }
        }
    }
}

@Composable
fun ModalInputGroup(
    size: ModalSize = ModalSize.Large(),
    title: String,
    modalTitle: String,
    supportingText: String?,
    leadingIcon: @Composable (() -> Unit)? = null,
    header: @Composable () -> Unit,
    body: @Composable (() -> Unit) -> Unit,
    footer: @Composable ((() -> Unit) -> Unit)? = null,
    trailingIcon: @Composable ((() -> Unit) -> Unit)? = null
) {
    ModalInputGroup(
        title = title,
        modalTitle = modalTitle,
        leadingIcon = leadingIcon,
        supportingText = supportingText,
        modal = {
            ModalScaffold(
                size = size,
                anchor = it,
                header = header,
                body = body,
                footer = footer
            )
        },
        trailingIcon = trailingIcon
    )
}
