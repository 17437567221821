package com.appcreator.compose.components.basic

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.autofill.AutofillNode
import androidx.compose.ui.autofill.AutofillType
import androidx.compose.ui.composed
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.boundsInWindow
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.platform.LocalAutofill
import androidx.compose.ui.platform.LocalAutofillTree
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.components.forms.InputComponent
import com.appcreator.blueprint.components.basic.MaterialIconComponent
import com.appcreator.blueprint.core.properties.MaterialIcon
import com.appcreator.compose.LocalEnvStore
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.di.Container
import com.appcreator.compose.di.performer
import com.appcreator.compose.extensions.SizingView
import com.appcreator.compose.extensions.composeColor
import com.appcreator.compose.extensions.value
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.channels.Channel.Factory.CONFLATED
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.flow.consumeAsFlow
import kotlinx.coroutines.flow.debounce
import kotlinx.coroutines.launch

@Composable
fun InputComposable(modifier: Modifier, component: InputComponent) {
    val envStore = LocalEnvStore.current
    val inputEnvStore = LocalInputEnvStore.current
    val focusManager = LocalFocusManager.current
    val theme = LocalTheme.current
    val value = component.data?.value?.let { envStore.get(it) as? String } ?: ""

    val onValueChange: (String) -> Unit = { new ->
        component.data?.value?.let {
            inputEnvStore.set(it, new)
        }
    }

    val onChangeAction = component.onChangeAction?.let { Container.performer(it)?.deferred() }
    LaunchedEffect(value) {
        delay(400) // This acts as a debounce
        onChangeAction?.perform(envStore)
    }

    val options = KeyboardOptions(
        imeAction = ImeAction.Done,
        capitalization = when(component.contentType) {
            InputComponent.ContentType.Username,
            InputComponent.ContentType.Password,
            InputComponent.ContentType.NewPassword,
            InputComponent.ContentType.Number,
            InputComponent.ContentType.Email,
            InputComponent.ContentType.Phone -> KeyboardCapitalization.None
            else -> KeyboardCapitalization.Sentences
        },
        keyboardType = when(component.contentType) {
            InputComponent.ContentType.Username -> KeyboardType.Text
            InputComponent.ContentType.Password -> KeyboardType.Password
            InputComponent.ContentType.NewPassword -> KeyboardType.Password
            InputComponent.ContentType.Phone,
            InputComponent.ContentType.Number -> KeyboardType.Number
            InputComponent.ContentType.Email -> KeyboardType.Email
            else -> KeyboardType.Text
        },
        autoCorrectEnabled = when(component.contentType) {
            InputComponent.ContentType.Username,
            InputComponent.ContentType.Password,
            InputComponent.ContentType.NewPassword,
            InputComponent.ContentType.Number -> false
            else -> true
        },
    )
    val actions = KeyboardActions(onDone = {
        focusManager.moveFocus(FocusDirection.Down)
    })

    val password = when(component.contentType) {
        InputComponent.ContentType.Password,
        InputComponent.ContentType.NewPassword -> true
        else -> false
    }

    val singleLine = when(component.contentType) {
        InputComponent.ContentType.Text -> true // TODO allow multi line inputs
        else -> true
    }

    var showPassword by remember { mutableStateOf(false) }
    val visualTransformation = if(password && !showPassword) PasswordVisualTransformation() else VisualTransformation.None


    val trailingIcon: (@Composable () -> Unit)? = if (password && component.passwordVisibilityToggle == true) {
        {
            IconButton(
                onClick = {
                    showPassword = !showPassword
                }
            ) {
                val code = if (showPassword) "\ue8f5" else "\uE8F4"
                MaterialIconComposable(Modifier, MaterialIconComponent(
                    _nodeId = null,
                    _nodeLabel = null,
                    _nodeRelativeId = null,
                    icon = MaterialIcon("", code),
                    size = 18,
                    color = null
                ))
            }
        }
    } else null

    SizingView(component.width, null) { mod, _, _ ->

        val autofill = when(component.contentType) {
            InputComponent.ContentType.Username -> AutofillType.Username
            InputComponent.ContentType.Password -> AutofillType.Password
            InputComponent.ContentType.NewPassword -> AutofillType.NewPassword
            InputComponent.ContentType.Email -> AutofillType.EmailAddress
            InputComponent.ContentType.Phone -> AutofillType.PhoneNumber
            else -> null
        }

        val autofillMod = autofill?.let {
            mod.autoFill(it, update = onValueChange)
        }?: mod

        when(component.layoutType) {
            InputComponent.LayoutType.Bordered -> OutlinedTextField(
                colors = OutlinedTextFieldDefaults.colors(
                    focusedBorderColor = theme?.color(component.borderColor)?.composeColor() ?: MaterialTheme.colorScheme.primary
                ),
                modifier = autofillMod.then(modifier),
                value = value,
                onValueChange = onValueChange,
                keyboardOptions = options,
                keyboardActions = actions,
                singleLine = singleLine,
                visualTransformation = visualTransformation,
                label = component.label?.value()?.let {
                    {
                        Text(text = it)
                    }
                },
                placeholder = component.placeholder?.value()?.let {
                    {
                        Text(text = it)
                    }
                },
                trailingIcon = trailingIcon
            )
            InputComponent.LayoutType.Underlined ->
                TextField(
                    modifier = autofillMod.then(modifier),
                    colors = TextFieldDefaults.colors(
                        focusedContainerColor = Color.Transparent,
                        unfocusedContainerColor = Color.Transparent
                    ),
                    value = value,
                    onValueChange = onValueChange,
                    keyboardOptions = options,
                    keyboardActions = actions,
                    singleLine = singleLine,
                    visualTransformation = visualTransformation,
                    label = component.label?.value()?.let {
                        {
                            Text(text = it)
                        }
                    },
                    placeholder = component.placeholder?.value()?.let {
                        {
                            Text(text = it)
                        }
                    }
                )
            else -> BasicTextField(
                modifier = autofillMod.then(modifier).heightIn(min = 44.dp),
                value = value,
                onValueChange = onValueChange,
                keyboardOptions = options,
                keyboardActions = actions,
                singleLine = singleLine,
                visualTransformation = visualTransformation,
                decorationBox = {
                    Box(contentAlignment = Alignment.CenterStart) {
                        it()
                        Row(
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            if (value.isBlank()) {
                                component.placeholder?.value()?.let {
                                    Text(
                                        modifier = Modifier.alpha(0.6f),
                                        text = it
                                    )
                                }
                            }
                            Spacer(Modifier.weight(1f))
                            trailingIcon?.invoke()
                        }
                    }
                }
            )
        }
    }
}

fun Modifier.autoFill(
    autofillType: AutofillType,
    update: (String) -> Unit,
): Modifier = composed {

    val autofillTree = LocalAutofillTree.current
    val autoFill = LocalAutofill.current
    var autoFillNode: AutofillNode? = null

    onFocusChanged { focus ->
        autoFillNode?.let {
            if(focus.isFocused)
                autoFill?.requestAutofillForNode(it)
            else autoFill?.cancelAutofillForNode(it)
        }
    }.onGloballyPositioned {
        autoFillNode = AutofillNode(listOf(autofillType), it.boundsInWindow()) { autofill ->
            update(autofill)
        }.apply {
            autofillTree.plusAssign(this)
        }
    }
}