package com.appcreator.dto

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class ReleasesRequest(
    val platform: String,
    val version: String,
    val dryRun: Boolean?,
    val host: String?,
    val prerender: Boolean?
)

@Serializable
data class ReleasesResponse(
    val releases: List<ReleaseResponse>
)

object PlatformKeys {
    const val Android = "Android"
    const val iOS = "iOS"
    const val Web = "web"
}

@Serializable
data class ReleaseResponse(
    val releaseId: String,
    val version: String,
    val buildNumber: Long,
    val canDownload: Boolean,
    val updatedAt: Instant,
    val platform: String,
    val status: String,
    val hasLogs: Boolean,
    val hasWebsiteZip: Boolean,
    val host: String?,
    val prerender: Boolean?
)

@Serializable
data class DownloadRequest(
    val downloadType: String
)

@Serializable
data class DownloadResponse(
    val url: String
)