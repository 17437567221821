package com.appcreator.blueprint.components.basic

import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.core.ComponentClass
import com.appcreator.blueprint.core.GenericDefault
import com.appcreator.blueprint.core.InputProperty
import com.appcreator.blueprint.core.properties.LocalizableString
import com.appcreator.blueprint.core.properties.SizeValue

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Video")
@ComponentClass(
    group = "Basic",
    title = "Video",
    availableFrom = "0.0.1",
    accessible = false
)
// Currently not working
data class VideoComponent(

    override val _nodeId: String?,
    override val _nodeLabel: String?,
    override val _nodeRelativeId: String?,

    @InputProperty(
        title = "URL",
        availableFrom = "0.0.1"
    )
    val url: LocalizableString?,

    @InputProperty(
        section = "Layout",
        title = "Aspect Ratio",
        availableFrom = "0.0.1"
    )
    @GenericDefault("1.78f")
    val aspectRatio: Float?,

): Component