package com.appcreator.creatorapp.editor.panelcomponent.views

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.DataObject
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.core.DataSpec
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.ListDataSpec
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.Dropdown
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.editor.local.DataOption
import com.appcreator.creatorapp.editor.local.LocalDataOptions
import com.appcreator.creatorapp.editor.local.LocalNodeExplorer
import com.appcreator.creatorapp.editor.local.LocalSelectedComponent
import com.appcreator.styles.ThemeMargins

@Composable
fun DataSourceTextInput(
    label: String,
    value: String,
    supportingText: String? = null,
    allowDataItems: Boolean = true,
    update: (String) -> Unit
) {
    val options = LocalDataOptions.current
    CreatorTextInput(
        modifier = Modifier.fillMaxWidth(),
        label = label,
        value = value,
        supportingText = supportingText,
        onValueChange = update,
        trailingIcon = {
            if (allowDataItems) {
                DataDropdown(options, filter = {
                    it !is ListDataSpec
                }) {
                    if (value.isBlank()) update("{${it.value}}")
                    else update("$value {${it.value}}")
                }
            }
        }
    )
}


@Composable
fun rememberDataOptionsFromShapes(
    inclusive: Boolean = false,
    plus: List<DataOption>? = null
): List<DataOption> {

    val selected by LocalSelectedComponent.current
    val nodeExplorer = LocalNodeExplorer.current
    return remember(selected, plus) {
        val dataItems = nodeExplorer.allDataItems(selected, inclusive)
        plus?.let {
            dataItems + it
        } ?: dataItems
    }
}

@Composable
fun rememberDataOptionsFromAction(
    actionBuilder: ActionBuilder,
): List<DataOption> {

    val selected by LocalSelectedComponent.current
    val nodeExplorer = LocalNodeExplorer.current
    return remember(actionBuilder) {
        nodeExplorer.actionsDataItems(selected, actionBuilder)
    }
}

@Composable
inline fun DataDropdown(
    options: List<DataOption>,
    crossinline filter: (DataSpec) -> Boolean,
    crossinline onSelect: (DataPath) -> Unit
) {
    if (options.any { it.specs.isNotEmpty() }) {
        Dropdown(
            anchor = {
                IconButton(onClick = it) {
                    Icon(Icons.Default.DataObject, contentDescription = null)
                }
            }
        ) { close ->
            DataOptionsList(options, filter, onSelect, close)
        }
    }
}

@Composable
inline fun DataOptionsList(
    options: List<DataOption>,
    crossinline filter: (DataSpec) -> Boolean,
    crossinline onSelect: (DataPath) -> Unit,
    crossinline close: () -> Unit
) {
    if (options.isEmpty()) {
        DropdownItem(title = "No data available") {
            close()
        }
    } else {

        options
            .filter { it.specs.any(filter) }
            .forEach {

                Box(
                    modifier = Modifier.padding(ThemeMargins.SmallMargin),
                    contentAlignment = Alignment.CenterStart
                ) {
                    Text(
                        text = it.title,
                        style = MaterialTheme.typography.labelLarge
                    )
                }

                HorizontalDivider()
                it.specs
                    .filter(filter)
                    .forEach {
                        DropdownItem(title = it.key) {
                            onSelect(DataPath(it.key, DataPath.Source.Other))
                            close()
                        }
                    }
            }
    }

}