@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.appcreator.shared_compose.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainFont0 {
  public val material_outlined: FontResource by 
      lazy { init_material_outlined() }
}

@InternalResourceApi
internal fun _collectCommonMainFont0Resources(map: MutableMap<String, FontResource>) {
  map.put("material_outlined", CommonMainFont0.material_outlined)
}

internal val Res.font.material_outlined: FontResource
  get() = CommonMainFont0.material_outlined

private fun init_material_outlined(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:material_outlined",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.appcreator.shared_compose.generated.resources/font/material_outlined.ttf", -1, -1),
    )
)
