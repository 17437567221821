package com.appcreator.creatorapp.editor.centerdata.firestore.sections

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Info
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.PlainTooltip
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.material3.TooltipBox
import androidx.compose.material3.TooltipDefaults
import androidx.compose.material3.rememberTooltipState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreLoaderSpec
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreLoaderSpec.Order
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.api.MutableDataSource
import com.appcreator.styles.ThemeMargins


@Composable
fun FirestoreQuery(
    data: MutableDataSource,
    spec: FirebaseFirestoreLoaderSpec,
    save: () -> Unit
) {
    Column(Modifier.widthIn(max = 600.dp)) {

        Row {
            CreatorTextInput(
                modifier = Modifier.weight(1f),
                label = when(spec.queryScope) {
                    FirebaseFirestoreLoaderSpec.QueryScope.CollectionGroup -> "Collection group"
                    else -> "Path"
                },
                value = spec.dataPath ?: "",
                onValueChange = {
                    data.loaderSpec = spec.copy(dataPath = it)
                    save()
                },
                singleLine = true,
                trailingIcon = {
                    TooltipBox(
                        positionProvider = TooltipDefaults.rememberPlainTooltipPositionProvider(),
                        state = rememberTooltipState(isPersistent = true),
                        tooltip = {
                            PlainTooltip {
                                Column(Modifier.padding(ThemeMargins.SmallMargin)) {
                                    val text = when(spec.queryScope) {
                                        FirebaseFirestoreLoaderSpec.QueryScope.CollectionGroup -> "Collection group scope allows querying across all collections with the same Collection ID"
                                        else -> "Collection path allows querying in a specific collection. To query a subcollection, follow the format: /collectionId/documentId/subcollectionId"
                                    }
                                    Text(text)
                                }
                            }
                        },
                        content = {
                            Icon(Icons.Default.Info, contentDescription = null)
                        }
                    )
                }
            )
            Spacer(Modifier.width(ThemeMargins.SmallMargin))
            DropDownInputGroup(
                modifier = Modifier.width(200.dp),
                title = "Scope",
                dropDownTitle = spec.queryScope?.name ?: "",
                dropDownContent = { close ->
                    FirebaseFirestoreLoaderSpec.QueryScope.entries.forEach {
                        DropdownItem(
                            title = it.name
                        ) {
                            data.loaderSpec = spec.copy(queryScope = it)
                            save()
                            close()
                        }
                    }
                }
            )
        }

        if (spec.dataPath?.isNotEmpty() == true) {

            if (spec.documentFetch) {
                Text(
                    style = MaterialTheme.typography.bodySmall,
                    text = "This path will fetch a single document"
                )
            } else {

                Text(
                    style = MaterialTheme.typography.bodySmall,
                    text = "This path will run a collection query"
                )
                Spacer(Modifier.height(ThemeMargins.LargeMargins))

                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Limit",
                    value = spec.limit?.toString() ?: "",
                    onValueChange = {
                        data.loaderSpec = spec.copy(limit = it.toIntOrNull())
                        save()
                    }
                )

                val where =
                    remember { mutableStateListOf(*(spec.where ?: emptyList()).toTypedArray()) }
                where.forEachIndexed { index, item ->
                    WhereRow(
                        where = item,
                        update = {
                            where[index] = it
                            data.loaderSpec = spec.copy(where = where)
                            save()
                        },
                        remove = {
                            where.remove(item)
                            data.loaderSpec = spec.copy(where = where)
                            save()
                        }
                    )
                }

                TextButton(onClick = {
                    where.add(
                        FirebaseFirestoreLoaderSpec.Where.Value(
                            field = "",
                            value = "",
                            compareType = FirebaseFirestoreLoaderSpec.WhereType.Equal
                        )
                    )
                }) {
                    Icon(Icons.Default.Add, contentDescription = null)
                    Spacer(Modifier.width(ThemeMargins.SmallMargin))
                    Text("Add to query")
                }

                val order = remember { mutableStateListOf(*(spec.orderBy ?: emptyList()).toTypedArray()) }
                order.forEachIndexed { index, item ->
                    OrderRow(
                        order = item,
                        update = {
                            order[index] = it
                            data.loaderSpec = spec.copy(orderBy = order)
                            save()
                        },
                        remove = {
                            order.remove(item)
                            data.loaderSpec = spec.copy(orderBy = order)
                            save()
                        }
                    )
                }
                TextButton(onClick = {
                    order.add(
                        Order(
                            field = "",
                            direction = FirebaseFirestoreLoaderSpec.OrderBy.Ascending
                        )
                    )
                }) {
                    Icon(Icons.Default.Add, contentDescription = null)
                    Spacer(Modifier.width(ThemeMargins.SmallMargin))
                    Text("Add to order")
                }
            }
        }
    }
}

@Composable
private fun WhereRow(where: FirebaseFirestoreLoaderSpec.Where, update: (FirebaseFirestoreLoaderSpec.Where) -> Unit, remove: () -> Unit) {

    when (where) {
        is FirebaseFirestoreLoaderSpec.Where.Group -> {
            // TODO
        }
        is FirebaseFirestoreLoaderSpec.Where.In -> {
            // TODO
        }
        is FirebaseFirestoreLoaderSpec.Where.Value -> WhereValueRow(where, update, remove)
    }

}

@Composable
private fun WhereValueRow(where: FirebaseFirestoreLoaderSpec.Where.Value, update: (FirebaseFirestoreLoaderSpec.Where) -> Unit, remove: () -> Unit) {

    Row(horizontalArrangement = Arrangement.spacedBy(ThemeMargins.SmallMargin)) {
        CreatorTextInput(
            modifier = Modifier.weight(1f),
            label = "Field",
            value = where.field,
            onValueChange = {
                update(where.copy(field = it))
            }
        )

        DropDownInputGroup(
            modifier = Modifier.width(100.dp),
            title = "",
            dropDownTitle = where.compareType.display,
            dropDownContent = { close ->
                FirebaseFirestoreLoaderSpec.WhereType.entries.forEach {
                    DropdownItem(
                        title = "${it.name.split("(?=\\p{Upper})".toRegex()).joinToString(separator = " ")} (${it.display})"
                    ) {
                        update(where.copy(compareType = it))
                        close()
                    }
                }
            }
        )

        CreatorTextInput(
            modifier = Modifier.weight(1f),
            label = "Value",
            value = where.value,
            onValueChange = {
                update(where.copy(value = it))
            },
            trailingIcon = {
                IconButton(
                    onClick = remove
                ) {
                    Icon(Icons.Default.Delete, contentDescription = null)
                }
            }
        )
    }
}

@Composable
private fun OrderRow(order: Order, update: (Order) -> Unit, remove: () -> Unit) {

    Row(horizontalArrangement = Arrangement.spacedBy(ThemeMargins.SmallMargin), verticalAlignment = Alignment.CenterVertically) {
        CreatorTextInput(
            modifier = Modifier.weight(1f),
            label = "Field",
            value = order.field,
            onValueChange = {
                update(order.copy(field = it))
            }
        )

        DropDownInputGroup(
            modifier = Modifier.width(300.dp),
            title = "",
            dropDownTitle = order.direction.name,
            dropDownContent = { close ->
                FirebaseFirestoreLoaderSpec.OrderBy.entries.forEach {
                    DropdownItem(
                        title = it.name
                    ) {
                        update(order.copy(direction = it))
                        close()
                    }
                }
            }
        )

        IconButton(
            onClick = remove
        ) {
            Icon(Icons.Default.Delete, contentDescription = null)
        }
    }
}
