package com.appcreator.blueprint.actions

import com.appcreator.blueprint.actions.AlertAction
import com.appcreator.blueprint.actions.AlertActionBuilder
import com.appcreator.blueprint.actions.ConditionAction
import com.appcreator.blueprint.actions.ConditionActionBuilder
import com.appcreator.blueprint.actions.CopyToClipboardAction
import com.appcreator.blueprint.actions.CopyToClipboardActionBuilder
import com.appcreator.blueprint.actions.DelayAction
import com.appcreator.blueprint.actions.DelayActionBuilder
import com.appcreator.blueprint.actions.SequenceAction
import com.appcreator.blueprint.actions.SequenceActionBuilder
import com.appcreator.blueprint.actions.TriggerAction
import com.appcreator.blueprint.actions.TriggerActionBuilder
import com.appcreator.blueprint.actions.`data`.AddListItemAction
import com.appcreator.blueprint.actions.`data`.AddListItemActionBuilder
import com.appcreator.blueprint.actions.`data`.ApiRequestAction
import com.appcreator.blueprint.actions.`data`.ApiRequestActionBuilder
import com.appcreator.blueprint.actions.`data`.DataConversionAction
import com.appcreator.blueprint.actions.`data`.DataConversionActionBuilder
import com.appcreator.blueprint.actions.`data`.RemoveListItemAction
import com.appcreator.blueprint.actions.`data`.RemoveListItemActionBuilder
import com.appcreator.blueprint.actions.`data`.SetListAction
import com.appcreator.blueprint.actions.`data`.SetListActionBuilder
import com.appcreator.blueprint.actions.`data`.SetTitleAction
import com.appcreator.blueprint.actions.`data`.SetTitleActionBuilder
import com.appcreator.blueprint.actions.`data`.SetValueAction
import com.appcreator.blueprint.actions.`data`.SetValueActionBuilder
import com.appcreator.blueprint.actions.alerts.SnackbarAlertAction
import com.appcreator.blueprint.actions.alerts.SnackbarAlertActionBuilder
import com.appcreator.blueprint.actions.navigation.CloseScreenAction
import com.appcreator.blueprint.actions.navigation.CloseScreenActionBuilder
import com.appcreator.blueprint.actions.navigation.OpenExternalAction
import com.appcreator.blueprint.actions.navigation.OpenExternalActionBuilder
import com.appcreator.blueprint.actions.navigation.OpenScreenAction
import com.appcreator.blueprint.actions.navigation.OpenScreenActionBuilder
import com.appcreator.blueprint.actions.navigation.SetNavigationDrawerAction
import com.appcreator.blueprint.actions.navigation.SetNavigationDrawerActionBuilder
import com.appcreator.blueprint.actions.permission.PermissionRequestAction
import com.appcreator.blueprint.actions.permission.PermissionRequestActionBuilder
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.firebase.auth.FirebaseAuthDeleteUserAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthDeleteUserActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoginAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLoginActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLogoutAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLogoutActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthResetPasswordAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthResetPasswordActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSetDisplayNameAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSetDisplayNameActionBuilder
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSignUpAction
import com.appcreator.blueprint.firebase.auth.FirebaseAuthSignUpActionBuilder
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreDeleteDataAction
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreDeleteDataActionBuilder
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreSetDataAction
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreSetDataActionBuilder
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingSubscribeToTopicAction
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingSubscribeToTopicActionBuilder
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingUnsubscribeToTopicAction
import com.appcreator.blueprint.firebase.messaging.FirebaseMessagingUnsubscribeToTopicActionBuilder
import com.appcreator.blueprint.firebase.storage.FirebaseStorageUploadImageAction
import com.appcreator.blueprint.firebase.storage.FirebaseStorageUploadImageActionBuilder
import com.appcreator.blueprint.revenuecat.RevenuecatManageSubscriptionsAction
import com.appcreator.blueprint.revenuecat.RevenuecatManageSubscriptionsActionBuilder
import com.appcreator.blueprint.spec.ActionBuilder
import kotlin.Boolean

public fun Action.toBuilder(newId: Boolean = false): ActionBuilder {
  val builder = when(this) {
      is AlertAction -> AlertActionBuilder(this, newId)
      is ConditionAction -> ConditionActionBuilder(this, newId)
      is CopyToClipboardAction -> CopyToClipboardActionBuilder(this, newId)
      is DelayAction -> DelayActionBuilder(this, newId)
      is SequenceAction -> SequenceActionBuilder(this, newId)
      is TriggerAction -> TriggerActionBuilder(this, newId)
      is SnackbarAlertAction -> SnackbarAlertActionBuilder(this, newId)
      is AddListItemAction -> AddListItemActionBuilder(this, newId)
      is ApiRequestAction -> ApiRequestActionBuilder(this, newId)
      is DataConversionAction -> DataConversionActionBuilder(this, newId)
      is RemoveListItemAction -> RemoveListItemActionBuilder(this, newId)
      is SetListAction -> SetListActionBuilder(this, newId)
      is SetTitleAction -> SetTitleActionBuilder(this, newId)
      is SetValueAction -> SetValueActionBuilder(this, newId)
      is CloseScreenAction -> CloseScreenActionBuilder(this, newId)
      is OpenExternalAction -> OpenExternalActionBuilder(this, newId)
      is OpenScreenAction -> OpenScreenActionBuilder(this, newId)
      is SetNavigationDrawerAction -> SetNavigationDrawerActionBuilder(this, newId)
      is PermissionRequestAction -> PermissionRequestActionBuilder(this, newId)
      is FirebaseAuthDeleteUserAction -> FirebaseAuthDeleteUserActionBuilder(this, newId)
      is FirebaseAuthLoginAction -> FirebaseAuthLoginActionBuilder(this, newId)
      is FirebaseAuthLogoutAction -> FirebaseAuthLogoutActionBuilder(this, newId)
      is FirebaseAuthResetPasswordAction -> FirebaseAuthResetPasswordActionBuilder(this, newId)
      is FirebaseAuthSetDisplayNameAction -> FirebaseAuthSetDisplayNameActionBuilder(this, newId)
      is FirebaseAuthSignUpAction -> FirebaseAuthSignUpActionBuilder(this, newId)
      is FirebaseFirestoreDeleteDataAction -> FirebaseFirestoreDeleteDataActionBuilder(this, newId)
      is FirebaseFirestoreSetDataAction -> FirebaseFirestoreSetDataActionBuilder(this, newId)
      is FirebaseMessagingSubscribeToTopicAction ->
      FirebaseMessagingSubscribeToTopicActionBuilder(this, newId)
      is FirebaseMessagingUnsubscribeToTopicAction ->
      FirebaseMessagingUnsubscribeToTopicActionBuilder(this, newId)
      is FirebaseStorageUploadImageAction -> FirebaseStorageUploadImageActionBuilder(this, newId)
      is RevenuecatManageSubscriptionsAction -> RevenuecatManageSubscriptionsActionBuilder(this,
      newId)
      else -> throw IllegalStateException("Trying to convert $this which has no mapping")
  }
  return builder
}
