package com.appcreator.creatorapp.editor.builderaction

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.AccountTree
import androidx.compose.material.icons.filled.Book
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.dataspecs.ValueDataSpec
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.inputs.ActionInputSpec
import com.appcreator.blueprint.spec.inputs.ConditionInputSpec
import com.appcreator.blueprint.spec.inputs.DataSpecType
import com.appcreator.components.layouts.ModalInputGroup
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.components.layouts.ModalSize
import com.appcreator.creatorapp.editor.DocsButton
import com.appcreator.creatorapp.editor.buildercondition.ConditionScreen
import com.appcreator.creatorapp.editor.inputs.InputItem
import com.appcreator.creatorapp.editor.local.DataOption
import com.appcreator.creatorapp.editor.local.LocalDataOptions
import com.appcreator.creatorapp.editor.panelcomponent.views.rememberDataOptionsFromAction
import com.appcreator.creatorapp.editor.panelcomponent.views.rememberDataOptionsFromShapes
import com.appcreator.styles.ThemeMargins

@Composable
fun ActionBuilder(
    actionBuilder: ActionBuilder,
    extraDataTitle: String,
    extraData: List<String>,
    save: () -> Unit,
    remove: () -> Unit
) {

    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Text(
            modifier = Modifier.weight(0.6f),
            text = actionBuilder.spec.title
        )

        TextButton(onClick = remove) {
            Text("Remove")
            Spacer(Modifier.width(ThemeMargins.SmallMargin))
            Icon(Icons.Default.Delete, contentDescription = null)
        }
    }

    val actionItems = rememberDataOptionsFromAction(actionBuilder)
    val extraDataItems = remember {
        DataOption(
            title = extraDataTitle,
            settable = false,
            specs = extraData.map {
                ValueDataSpec(
                    it,
                    ValueDataSpec.Type.String,
                    "",
                    true,
                    DataSpecType.Value
                )
            })
    }
    val dataItems = rememberDataOptionsFromShapes(
        true,
        plus = actionItems.plus(if (extraData.isNotEmpty()) listOf(extraDataItems) else emptyList())
    )
    CompositionLocalProvider(LocalDataOptions provides dataItems) {
        actionBuilder.inputSpecs
            .filterNot { it is ActionInputSpec }
            .filter { it.properties.visible() }
            .forEach {
                // Little dirty this
                if (it is ConditionInputSpec) {
                    CustomConditionInput(it, save)
                } else {
                    InputItem(it, save)
                }
                Box(Modifier.height(ThemeMargins.SmallMargin))
            }
    }

    Spacer(Modifier.height(ThemeMargins.LargeMargins))
    if (actionBuilder.spec.hasDocs) {
        DocsButton("actions", actionBuilder.spec.title)
    }
}

@Composable
fun CustomConditionInput(spec: ConditionInputSpec, save: () -> Unit) {
    ModalInputGroup(
        title = spec.properties.title,
        modalTitle = "Condition Creator",
        supportingText = spec.properties.description,
        leadingIcon = {
            Icon(Icons.Default.AccountTree, contentDescription = null)
        },
        modal = { anchor ->
            ModalScaffold(
                size = ModalSize.Large(60.dp),
                anchor = {
                    anchor(it)
                    LaunchedEffect(Unit) {
                        it()
                    }
                },
                header = {
                    Text("Condition Creator")
                },
                body = {
                    ConditionScreen(spec, save)
                }
            )
        }
    )
}
