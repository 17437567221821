package com.appcreator.creatorapp.editor.centerdata.contentful

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.contentful.ContentfulLoaderSpec
import com.appcreator.blueprint.core.LoaderSpec.Companion.defaultParameterSetting
import com.appcreator.blueprint.firebase.firestore.FirebaseFirestoreLoaderSpec
import com.appcreator.creatorapp.api.MutableDataSource
import com.appcreator.creatorapp.editor.centerdata.firestore.sections.FirestoreQuery
import com.appcreator.creatorapp.editor.centerdata.firestore.sections.FirestoreRequest
import com.appcreator.creatorapp.editor.centerdata.rest.DataParametersConfig
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager

enum class ContentfulRequestDetailsSections(
    val title: String,
    var visible: (ContentfulLoaderSpec) -> Boolean = { true },
    val content: @Composable (MutableDataSource, ContentfulLoaderSpec, save: () -> Unit) -> Unit
) {
    Parameters("Parameters",
        visible = { it.parameters.isNotEmpty() },
        content = { data, spec, save ->
            val config = spec.parameters.map {
                it to (spec.parameterSettings?.get(it)?: defaultParameterSetting())
            }
            DataParametersConfig(
                LocalBlueprintManager.current.globalData,
                config
            ) {
                data.loaderSpec = spec.copy(parameterSettings = it.toMap())
                save()
            }
        }),
    Query("Query",
        content = { data, spec, save ->
            ContentfulQuery(data, spec, save)
        }),
    Response("Response", content = { data, spec, save ->
        ContentfulRequest(data, spec, save)
    })
}