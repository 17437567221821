package com.appcreator.app

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Info
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import app.softwork.routingcompose.BrowserRouter
import app.softwork.routingcompose.Router
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.components.layouts.ModalScaffold
import com.appcreator.components.layouts.ModalSize
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.Navigation
import com.appcreator.creatorapp.RootScreen
import com.appcreator.creatorapp.editor.ProjectEditorScreen
import com.appcreator.creatorapp.local.LocalApiClient
import com.appcreator.creatorapp.organisation.OrganisationScreen
import com.appcreator.creatorapp.project.ProjectScreen
import com.appcreator.creatorapp.project.configurations.LoadingButton
import com.appcreator.styles.ThemeMargins
import kotlinx.browser.window

@Composable
fun EarlyAccessBanner() {
    Row(modifier = Modifier
        .fillMaxWidth()
        .background(MaterialTheme.colorScheme.tertiary)
        .padding(horizontal = ThemeMargins.LargeMargins),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.spacedBy(ThemeMargins.LargeMargins)
    ) {
        CompositionLocalProvider(LocalContentColor provides MaterialTheme.colorScheme.onTertiary) {
            Text(
                text = "Early access"
            )
            FeebackModal {
                Row(
                    Modifier.clickable { it() }.padding(4.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Icon(
                        Icons.Default.Info,
                        modifier = Modifier.size(16.dp),
                        contentDescription = null
                    )
                    Spacer(Modifier.width(4.dp))
                    Text(
                        text = "Report issue, give feedback or request a feature",
                        style = MaterialTheme.typography.labelSmall
                    )
                }
            }
        }
    }
}

@Composable
private fun FeebackModal(anchor: @Composable (() -> Unit) -> Unit) {


    ModalScaffold(
        size = ModalSize.Small,
        header = {
            Text("Feedback")
        },
        body = { close ->

            val feedbackOptions = listOf("Report Issue", "Give Feedback", "Request Feature")
            var feedbackType by remember { mutableStateOf(feedbackOptions.first()) }
            var feedback by remember { mutableStateOf("") }
            var submitted by remember { mutableStateOf(false) }

            if (submitted) {
                Box {
                    Column {
                        Text(text = "Thank you for taking the time to provide us with feedback, we may be in touch to offer assistance or updates if required.")
                        Spacer(Modifier.weight(1f))
                        Button(onClick = close) {
                            Text("Close")
                        }
                    }
                }

            } else {
                Column(verticalArrangement = Arrangement.spacedBy(ThemeMargins.MedMargins)) {
                    DropDownInputGroup(
                        modifier = Modifier.fillMaxWidth(),
                        title = "Type of feedback",
                        dropDownTitle = feedbackType,
                        dropDownContent = { close ->
                            feedbackOptions.forEach {
                                DropdownItem(
                                    title = it
                                ) {
                                    feedbackType = it
                                    close()
                                }
                            }
                        }
                    )

                    CreatorTextInput(
                        modifier = Modifier.fillMaxWidth().weight(1f),
                        label = "Message",
                        value = feedback,
                        onValueChange = {
                            feedback = it
                        }
                    )

                    val apiClient = LocalApiClient.current
                    LoadingButton(
                        modifier = Modifier,
                        enabled = feedback.isNotEmpty(),
                        label = "Submit",
                        action = {
                            apiClient.sendFeedback(feedbackType, feedback, window.location.href)
                            submitted = true
                        }
                    )
                }
            }
        },
        anchor = anchor
    )
}
