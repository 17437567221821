package com.appcreator.blueprint.actions.navigation

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.ActionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.BlueprintLinkInputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class OpenScreenActionBuilder(
  action: OpenScreenAction?,
  newId: Boolean = false,
) : ActionBuilder {
  override val spec: ActionSpec = ActionSpec(
          name = "OpenScreenAction",
          title = "Open Screen",
          group = "Navigation",
          isExtraPlugin = false,
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
      )

  public val openType: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "",
          title = "Type",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((action?.openType?: OpenScreenAction.OpenType.Standard).name),
          
          options = OpenScreenAction.OpenType.entries.map { it.name },
      )

  public val link: BlueprintLinkInputSpec = BlueprintLinkInputSpec(
          properties = InputProperties(
          section = "",
          title = "Link",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf(action?.link),
          
          
      )

  override val inputSpecs: List<InputSpec> = listOf(openType, link)

  override fun build(): Action = OpenScreenAction(openType.value?.let {
      OpenScreenAction.OpenType.valueOf(it) }?: OpenScreenAction.OpenType.Standard, link.value)
}
