package com.appcreator.blueprint.core

import kotlinx.serialization.Serializable

@Serializable
data class LoaderConfig(
    val loaderSpec: LoaderSpecReference,
    val parameters: Map<String, String>,
    val supportsPaging: Boolean?
)
