package com.appcreator.compose.components.navigation

import androidx.compose.foundation.layout.Box
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowBack
import androidx.compose.material.icons.filled.Menu
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.MediumTopAppBar
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBar
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.material3.TopAppBarScrollBehavior
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import com.appcreator.blueprint.components.Screen
import com.appcreator.blueprint.core.Component
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.LocalNavigationDrawer
import com.appcreator.compose.LocalScreenTitle
import com.appcreator.compose.LocalTheme
import com.appcreator.compose.Navigator
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.compose.extensions.composeColor
import com.appcreator.compose.extensions.value

@Composable
fun NavigationBar(
    navigator: Navigator,
    screen: Screen,
    scrollBehavior: TopAppBarScrollBehavior?
) {

    val contentColor = when (screen.navigationContent) {
        Screen.NavigationContent.Light -> Color(red = 230, green = 225, blue = 229)
        Screen.NavigationContent.Dark -> Color(red = 28, green = 27, blue = 31)
        else -> LocalContentColor.current
    }
    val color = LocalTheme.current?.color(screen.navigationColor)
    val appBarColors = color?.let {
        TopAppBarDefaults.topAppBarColors(
            containerColor = color.composeColor(),
            scrolledContainerColor = color.composeColor(),
            navigationIconContentColor = contentColor,
            titleContentColor = contentColor,
            actionIconContentColor = contentColor
        )
    } ?: TopAppBarDefaults.topAppBarColors(
        containerColor = MaterialTheme.colorScheme.surfaceContainer,
        scrolledContainerColor = MaterialTheme.colorScheme.surfaceContainer,
        navigationIconContentColor = contentColor,
        titleContentColor = contentColor,
        actionIconContentColor = contentColor
    )


    when (screen.navigationStyle) {
        Screen.NavigationStyle.Large ->

                screen.navigationCustomTitle?.let {
                    Box {
                        MediumTopAppBar(
                            colors = appBarColors,
                            title = {
                                val title by LocalScreenTitle.current
                                if(title.isNotEmpty()) {
                                    Text(title)
                                }
                            },
                            scrollBehavior = scrollBehavior
                        )
                        TopAppBar(
                            colors = appBarColors,
                            title = {
                                screen.navigationCustomTitle?.let {
                                    ComponentWithSize(it)
                                }
                            },
                            navigationIcon = {
                                NavigationIcon(navigator)
                            },
                            actions = {
                                screen.navigationItems?.let {
                                    ComponentWithSize(it)
                                }
                            }
                        )
                    }
                }?: MediumTopAppBar(
                    colors = appBarColors,
                    title = {
                       val title by LocalScreenTitle.current
                        if(title.isNotEmpty()) {
                            Text(title)
                        }
                    },
                    navigationIcon = {
                        NavigationIcon(navigator)
                    },
                    actions = {
                        screen.navigationItems?.let {
                            ComponentWithSize(it)
                        }
                    },
                    scrollBehavior = scrollBehavior
                )
        Screen.NavigationStyle.Inline ->
            TopAppBar(
                colors = appBarColors,
                title = {
                    screen.navigationCustomTitle?.let {
                        ComponentWithSize(it)
                    } ?: run {
                        val title by LocalScreenTitle.current
                        if(title.isNotEmpty()) {
                            Text(title)
                        }
                    }
                },
                navigationIcon = {
                    NavigationIcon(navigator)
                },
                actions = {
                    screen.navigationItems?.let {
                        ComponentWithSize(it)
                    }
                }
            )
        else -> {}
    }
}

@Composable
private fun ComponentWithSize(component: Component) {
//    BoxWithConstraints {
//        CompositionLocalProvider(LocalAbsoluteSize provides AbsoluteSize(this)) {
            ComponentComposable(Modifier, component)
//        }
//    }
}

@Composable
private fun NavigationIcon(navigator: Navigator) {
    val canGoBack = remember { navigator.canGoBack() }
    if (canGoBack) {
        IconButton(
            onClick = {
                navigator.back()
            }
        ) { Icon(imageVector = Icons.Default.ArrowBack, "Back") }
    } else if (LocalNavigationDrawer.current) {
        val envStore = LocalInputEnvStore.current
        IconButton(
            onClick = {
                envStore.set("drawer-state", "Open")
            }
        ) { Icon(imageVector = Icons.Default.Menu, "Menu") }
    }
}
