package com.appcreator.blueprint.spec

import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.spec.inputs.ComponentInputSpec
import com.appcreator.blueprint.spec.inputs.ComponentListInputSpec
import com.appcreator.blueprint.spec.inputs.StringInputSpec

interface ComponentBuilder {
    fun build(): Component
    val spec: ComponentSpec
    val _nodeLabel: StringInputSpec
    val _nodeId: StringInputSpec
    val _nodeRelativeId: StringInputSpec
    val inputSpecs: List<InputSpec>
}

fun ComponentBuilder.mergedInputSpec(): List<InputSpec> =
    inputSpecs.mapNotNull {
        if(it.properties.displayInline && it.properties.visible()) {
            (it as? ComponentInputSpec)?.value?.inputSpecs
        } else {
            listOf(it)
        }
    }.flatten()

fun ComponentBuilder.clearIds() {
    _nodeId.value = null
    inputSpecs.forEach {
        when (it) {
            is ComponentInputSpec -> it.value?.clearIds()
            is ComponentListInputSpec -> it.value.forEach { it.clearIds() }

        }
    }
}