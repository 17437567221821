package com.appcreator.dto

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class FileResponse(
    val id: String,
    val title: String,
    val createdAt: Instant,
    val url: String,
    val fileExtension: String
)
