package com.appcreator.creatorapp.editor.panelcomponentpicker

import androidx.compose.animation.AnimatedContent
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredHeightIn
import androidx.compose.foundation.layout.requiredSize
import androidx.compose.foundation.layout.requiredWidth
import androidx.compose.foundation.layout.sizeIn
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.foundation.lazy.staggeredgrid.LazyVerticalStaggeredGrid
import androidx.compose.foundation.lazy.staggeredgrid.StaggeredGridCells
import androidx.compose.foundation.lazy.staggeredgrid.StaggeredGridItemSpan
import androidx.compose.foundation.lazy.staggeredgrid.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Search
import androidx.compose.material3.Card
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.scale
import androidx.compose.ui.unit.dp
import com.appcreator.app.utils.onDrag
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.core.Component
import com.appcreator.blueprint.spec.ComponentBuilder
import com.appcreator.components.layouts.CreatorTextInput
import com.appcreator.compose.AbsoluteSize
import com.appcreator.compose.LocalAbsoluteSize
import com.appcreator.compose.LocalVerticalScrollingParent
import com.appcreator.compose.components.ComponentComposable
import com.appcreator.creatorapp.LoadingComposable
import com.appcreator.creatorapp.editor.DragCompleteInfo
import com.appcreator.creatorapp.editor.DragHolder
import com.appcreator.creatorapp.editor.DragItem
import com.appcreator.creatorapp.editor.LocalDrag
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.dto.ComponentTemplate
import com.appcreator.styles.ThemeMargins

@Composable
fun ComponentTemplatePanel(
    add: (ComponentBuilder, DragCompleteInfo) -> Unit
) {
    val blueprintManager = LocalBlueprintManager.current
    val dragHolder = LocalDrag.current

    LoadingComposable(loader = {
        it.getComponentTemplates(blueprintManager.orgId, blueprintManager.projectId)
    }) { response ->

        var search by remember { mutableStateOf("") }

        val sortedOptions = response.templates
            .filter {
                search.isEmpty() ||
                        it.info.name.contains(search, ignoreCase = true) ||
                        it.info.group.contains(search, ignoreCase = true) ||
                        it.info.description.contains(search, ignoreCase = true)
            }
            .groupBy { it.info.group }

        Column(modifier = Modifier.fillMaxSize()) {

            Box(Modifier.padding(horizontal = ThemeMargins.SmallMargin)) {
                CreatorTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Search",
                    value = search,
                    onValueChange = {
                        search = it
                    },
                    leadingIcon = {
                        Icon(Icons.Default.Search, contentDescription = null)
                    }
                )
            }

            if(sortedOptions.isEmpty()) {
                Text(
                    modifier = Modifier.padding(horizontal = ThemeMargins.SmallMargin),
                    text = "No results try another search",
                )
            }

            LazyVerticalStaggeredGrid(
                columns = StaggeredGridCells.Fixed(2),
                contentPadding = PaddingValues(vertical = ThemeMargins.MedMargins)
            ) {
                sortedOptions.forEach {
                    item(span = StaggeredGridItemSpan.FullLine) {
                        Text(
                            modifier = Modifier.padding(horizontal = ThemeMargins.SmallMargin),
                            text = it.key,
                            style = MaterialTheme.typography.headlineMedium
                        )
                    }

                    items(it.value) { template ->
                        Template(dragHolder, template, add)
                    }
                }
            }
        }
    }
}


@Composable
private fun Template(
    dragHolder: DragHolder,
    template: ComponentTemplate,
    add: (ComponentBuilder, DragCompleteInfo) -> Unit
) {
    Box(
        Modifier
            .padding(ThemeMargins.SmallMargin)
            .clip(RoundedCornerShape(8.dp))
            .border(
                1.dp,
                color = MaterialTheme.colorScheme.primary,
                shape = RoundedCornerShape(8.dp)
            )
    ) {

        BoxWithConstraints(modifier = Modifier
            .heightIn(max = 400.dp)
            .fillMaxWidth()
            .onDrag {
                if (it) {
                    dragHolder.startDrag(
                        DragItem(template.component.toBuilder()) {
                            Card {
                                BoxWithConstraints(
                                    Modifier.sizeIn(
                                        maxWidth = 340.dp,
                                        maxHeight = 400.dp
                                    )
                                ) {
                                    CompositionLocalProvider(
                                        LocalAbsoluteSize provides AbsoluteSize(
                                            this
                                        ),
                                        LocalVerticalScrollingParent provides true
                                    ) {
                                        ComponentComposable(
                                            Modifier,
                                            template.component
                                        )
                                    }
                                }
                            }
                        }
                    )
                } else {
                    dragHolder.completeDrag()?.let { info ->
                        add(
                            template.component.toBuilder(true),
                            info
                        )
                    }
                }
            }
        ) {

            BoxWithConstraints(
                Modifier
                    .requiredWidth(maxWidth * 2)
                    .requiredHeightIn(max = 800.dp)
                    .scale(0.5f)
            ) {
                CompositionLocalProvider(
                    LocalAbsoluteSize provides AbsoluteSize(this),
                    LocalVerticalScrollingParent provides true
                ) {
                    ComponentComposable(
                        Modifier,
                        template.component
                    )
                }
            }
        }
    }
}
