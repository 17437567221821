package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.appcreator.blueprint.core.ScreenPath
import com.appcreator.blueprint.core.findParameters
import com.appcreator.blueprint.spec.inputs.ScreenPathInputSpec
import com.appcreator.creatorapp.editor.panelcomponent.views.DataSourceTextInput
import com.appcreator.styles.ThemeMargins

@Composable
fun ScreenPathInput(spec: ScreenPathInputSpec, save: () -> Unit) {


    val content: @Composable () -> Unit = {
        DataSourceTextInput(
            label = spec.properties.title,
            value = spec.value?.value ?: "",
            allowDataItems = spec.properties.allowDataFields,
            supportingText = spec.properties.description,
            update = {
                val defaultParams = it.findParameters().associateWith { key ->
                    spec.value?.defaultParameters?.get(key) ?: ""
                }
                spec.value = ScreenPath(value = it, defaultParams)
                save()
            }
        )

        if(spec.value?.defaultParameters?.isNotEmpty() == true) {
            Column(Modifier.padding(start = ThemeMargins.LargeMargins)) {
                Spacer(Modifier.height(ThemeMargins.MedMargins))
                Text("Editor defaults")
                spec.value?.defaultParameters
                    ?.forEach { parameter ->
                        DataSourceTextInput(
                            label = parameter.key,
                            value = parameter.value,
                            allowDataItems = false,
                            update = { input ->
                                spec.value?.let {
                                    spec.value =
                                        it.copy(defaultParameters = it.defaultParameters?.plus(parameter.key to input))
                                    save()
                                }
                            }
                        )
                        Box(Modifier.height(ThemeMargins.SmallMargin))
                    }
            }
        }
    }

    if(spec.value?.defaultParameters?.isNotEmpty() == true) {

        Column(
            Modifier
                .border(
                    1.dp,
                    color = MaterialTheme.colorScheme.onBackground.copy(alpha = 0.4f),
                    shape = RoundedCornerShape(8.dp)
                )
                .padding(ThemeMargins.SmallMargin)
        ) {
            content()
        }
    } else {
        content()
    }

}