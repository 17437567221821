package com.appcreator.creatorapp.editor.inputs

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.appcreator.blueprint.components.componentOptions
import com.appcreator.blueprint.conversions.conversionOptions
import com.appcreator.blueprint.spec.ComponentBuilderProvider
import com.appcreator.blueprint.spec.ComponentSpec
import com.appcreator.blueprint.spec.ConversionBuilderProvider
import com.appcreator.blueprint.spec.ConversionSpec
import com.appcreator.blueprint.spec.inputs.ConversionInputSpec
import com.appcreator.components.layouts.DropDownInputGroup
import com.appcreator.components.layouts.DropdownItem
import com.appcreator.creatorapp.editor.centerdata.rest.functions.FunctionBuilder
import com.appcreator.creatorapp.editor.local.LocalBlueprintManager
import com.appcreator.styles.ThemeMargins

@Composable
fun DataConversionInput(spec: ConversionInputSpec, save: () -> Unit) {

    DropDownInputGroup(title = spec.properties.title, dropDownTitle = spec.value?.spec?.title?: "Select") {
        filteredOptions()
            .forEach {
            DropdownItem(it.key.title) {
                spec.value = it.value.block()
                save()
            }
        }
    }

    Column(Modifier.padding(start = ThemeMargins.LargeMargins)) {
        spec.value?.let {
            Box(Modifier.height(ThemeMargins.SmallMargin))
            FunctionBuilder(it, save)
        }
    }

}

@Composable
fun filteredOptions(): Map<ConversionSpec, ConversionBuilderProvider> {
    val blueprintManager = LocalBlueprintManager.current
    val accessibleOptions = conversionOptions()
    return accessibleOptions.filterKeys {
        blueprintManager.enabledExtras.contains(it.group) || !it.isExtraPlugin
    }
}