package com.appcreator.creatorapp.project.newproject

import androidx.compose.runtime.Composable
import com.appcreator.BuildConfig
import com.appcreator.blueprint.contentful.ContentfulLoaderSpec
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.client.contentful.ContentfulLoader
import com.appcreator.client.contentful.RichTextInfo
import com.appcreator.creatorapp.RawLoadingComposable
import com.appcreator.creatorapp.local.LocalContentfulClient

data class SlideContentful(
    val richTextInfo: RichTextInfo
)

@Composable
fun ContentfulSlideLoader(
    slug: String,
    content: @Composable (SlideContentful) -> Unit
) {
    val client = LocalContentfulClient.current
    RawLoadingComposable(
        loader = {
            val loader = ContentfulLoader(
                client = client,
                space = BuildConfig.CONTENTFUL_SPACE,
                environment = BuildConfig.CONTENTFUL_ENV,
                accessToken = BuildConfig.CONTENTFUL_TOKEN,
                ContentfulLoaderSpec(
                    id = "",
                    parameterSettings = null,
                    modelId = null,
                    modelName = "setupSlide",
                    limit = 1,
                    orderBy = null,
                    where = listOf(ContentfulLoaderSpec.Where("slug", slug)),
                    dataValues = listOf(ContentfulLoaderSpec.ContentfulData("content", "", ContentfulLoaderSpec.ContentfulDataType.RichText))
                )
            )
            val (_, data) = loader.load(EnvStore.create("GB"), emptyMap(), null)
            SlideContentful(data["content"] as RichTextInfo)
        },
        content = content
    )
}