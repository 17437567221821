package com.appcreator.dto

import kotlinx.serialization.Serializable

@Serializable
data class ErrorResponse(
    val error: ErrorMessage
)

@Serializable
data class ErrorMessage(
    val code: Int,
    val message: String?
)