package com.appcreator.blueprint.actions.navigation

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.actions.toBuilder
import com.appcreator.blueprint.components.basic.ContainerComponentBuilder
import com.appcreator.blueprint.components.toBuilder
import com.appcreator.blueprint.conditions.toBuilder
import com.appcreator.blueprint.conversions.toBuilder
import com.appcreator.blueprint.core.Action
import com.appcreator.blueprint.core.properties.DataPath
import com.appcreator.blueprint.dataspecs.toBuilder
import com.appcreator.blueprint.spec.ActionBuilder
import com.appcreator.blueprint.spec.ActionSpec
import com.appcreator.blueprint.spec.InputSpec
import com.appcreator.blueprint.spec.inputs.EnumInputSpec
import com.appcreator.blueprint.spec.inputs.InputProperties
import kotlin.Boolean
import kotlin.collections.List
import kotlin.uuid.Uuid

public class SetNavigationDrawerActionBuilder(
  action: SetNavigationDrawerAction?,
  newId: Boolean = false,
) : ActionBuilder {
  override val spec: ActionSpec = ActionSpec(
          name = "SetNavigationDrawerAction",
          title = "Configure Navigation Drawer",
          group = "Navigation",
          isExtraPlugin = false,
          description = "",
          availableFrom = "0.0.1",
          accessible = true,
          hasDocs = true,
      )

  public val drawerAction: EnumInputSpec = EnumInputSpec(
          properties = InputProperties(
          section = "",
          title = "Open/Close Navigation Drawer",
          description = "",
          availableFrom = "0.0.1",
          displayInline = false,
          allowDataFields = true,
          dataOptions = "Value",
          settableDataOnly = false,
          includeSiblingData = false,
          visible = { !false },
          dataOptionsPathFilter = { emptyList() },
          alwaysContainer = false,
          extraDataAvailable = "".split(",")
      ) ,
          value = mutableStateOf((action?.drawerAction?:
          SetNavigationDrawerAction.Action.Closed).name),
          
          options = SetNavigationDrawerAction.Action.entries.map { it.name },
      )

  override val inputSpecs: List<InputSpec> = listOf(drawerAction)

  override fun build(): Action = SetNavigationDrawerAction(drawerAction.value?.let {
      SetNavigationDrawerAction.Action.valueOf(it) }?: SetNavigationDrawerAction.Action.Closed)
}
